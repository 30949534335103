import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../Utils/Config';
import imag from "../../../../assets/images/add-podcast.svg";
import imag1 from "../../../../assets/images/manage-podcast.svg";
import del from "../../../../assets/images/del.png";
import ed from "../../../../assets/images/edit.png";
import search from "../../../../assets/images/search.png";

const Podcast = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setMailingAddressActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [editSpeakerVisible, setEditSpeakerVisible] = useState(false);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const [podcasts, setPodcasts] = useState([]);
    const [selectedPodcast, setSelectedPodcast] = useState(null);
    const [speakers, setSpeakers] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        starting_time: '',
        ending_time: '',
        youtube_url: '',
        host: [], // Array for hosts
        guest: [], // Array for guests
        banner: null,
        image:null
    });
    useEffect(() => {
        const fetchspeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/speakers/`);
                setSpeakers(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching  spekers:', error);
            }
        };

        fetchspeakers();
    }, []);
    useEffect(() => {
        console.log("Form Data Host IDs:", formData.host);
        console.log("Speakers List:", speakers.map(speaker => ({
            id: speaker.id,
            isChecked: formData.host.includes(speaker.id.toString())
        })));
    }, [formData.host, speakers]);


    const handleCheckboxChange = (e, type) => {
        const { value, checked } = e.target;
        const updatedList = checked
            ? [...formData[type], value]
            : formData[type].filter(id => id !== value);

        setFormData(prevFormData => ({
            ...prevFormData,
            [type]: updatedList
        }));
    };


    const handleDropdownChange = (e, type) => {
        setFormData(prevData => ({ ...prevData, [type]: e.target.value }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        console.log("request data", formData);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('date', formData.date);
        formDataToSend.append('starting_time', formData.starting_time);
        formDataToSend.append('ending_time', formData.ending_time);
        formDataToSend.append('youtube_url', formData.youtube_url);

        formData.host.forEach((host, index) => {
            formDataToSend.append(`host[${index}]`, host);
        });
        formData.guest.forEach((guest, index) => {
            formDataToSend.append(`guest[${index}]`, guest);
        });

        if (formData.banner) {
            formDataToSend.append('banner', formData.banner);
        }
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }
        try {
            await axios.post(`${BASE_URL}/admins/podcast/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert("Podcast Added Successfully");
            fetchPodcast();
            setFormData({
                name: '',
                date: '',
                starting_time: '',
                ending_time: '',
                youtube_url: '',
                host: [],  
                guest: [],  
                banner: null,
                image:null
            });
        } catch (err) {
            setError(err.response ? err.response.data : 'An error occurred');
        }
    };
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const handleChange = (e) => {
        const { files } = e.target;

        if (files && files[0]) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                banner: files[0],
                // Set the selected banner file
            }));
        }
    };


    const handleChange1 = (e) => {
        const { files } = e.target;

        if (files && files[0]) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: files[0],
               
            }));
        }
    };



    const handleAddButtonClick = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            host_contents: [...prevFormData.host_contents, { host_name: '', host_image: null }]
        }));
    };

    const handleRemoveContent = (index, type) => {
        const updatedContents = type === 'host'
            ? formData.host_contents.filter((_, i) => i !== index)
            : formData.guest_contents.filter((_, i) => i !== index);
        setFormData(prevFormData => ({
            ...prevFormData,
            [type === 'host' ? 'host_contents' : 'guest_contents']: updatedContents
        }));
    };

    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);
        if (!personalInfoActive) setMailingAddressActive(false);
    };

    const toggleMailingAddress = () => {
        setMailingAddressActive(!mailingAddressActive);
        if (!mailingAddressActive) setPersonalInfoActive(false);
    };

    const fetchPodcast = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/podcast_listall/`);
            console.log('Fetched Podcast:', response.data);
            setPodcasts(response.data);
        } catch (error) {
            console.error('Error fetching Podcast:', error);

        }
    };

    useEffect(() => {
        fetchPodcast();
    }, []);

    const handleDelete = async (podcastId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this podcast?');
        if (confirmDelete) {
            try {
                await axios.delete(`${BASE_URL}/admins/podcast/${podcastId}/delete/`);
                setPodcasts(prevPodcasts => prevPodcasts.filter(podcast => podcast.id !== podcastId));
                console.log('Podcast deleted successfully');
                alert('Podcast deleted successfully!');
                fetchPodcast();
            } catch (error) {
                console.error('Error deleting podcast:', error);
                alert('Failed to delete podcast. Please try again.');
            }
        }
    };

    const handleEdit = (podcast) => {
        console.log("Editing Podcast:", podcast); // Debugging line

        setSelectedPodcast(podcast);
        setEditFormVisible(true);
        setSelectedImage(null);

        setFormData(prevFormData => ({
            ...prevFormData,
            name: podcast.name || '',
            date: podcast.date || '',
            starting_time: podcast.starting_time || '',
            ending_time: podcast.ending_time || '',
            youtube_url: podcast.youtube_url || '',
            host: podcast.hosts ? podcast.hosts.map(host => host.id.toString()) : [], // Ensure IDs are strings
            guest: podcast.guests ? podcast.guests.map(guest => guest.id.toString()) : [],
            banner: null,
            image:null,
        }));
    };


    useEffect(() => {
        console.log("Selected Podcast:", selectedPodcast); // Debugging line
        console.log("Form Data Host IDs:", formData.host); // Debugging line

        if (selectedPodcast) {
            setFormData(prevFormData => ({
                ...prevFormData,
                name: selectedPodcast.name || '',
                date: selectedPodcast.date || '',
                starting_time: selectedPodcast.starting_time || '',
                ending_time: selectedPodcast.ending_time || '',
                youtube_url: selectedPodcast.youtube_url || '',
                host: selectedPodcast.hosts ? selectedPodcast.hosts.map(host => host.id.toString()) : [],
                guest: selectedPodcast.guests ? selectedPodcast.guests.map(guest => guest.id.toString()) : [],
                banner: null,
                image:null
            }));
        }
    }, [selectedPodcast]);





    const handleFormSubmit = async (e, podcastId) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        console.log("formdata update edit", formData);

        formDataToSend.append('name', formData.name);
        formDataToSend.append('date', formData.date);
        formDataToSend.append('starting_time', formData.starting_time);
        formDataToSend.append('ending_time', formData.ending_time);
        formDataToSend.append('youtube_url', formData.youtube_url);

        formData.host.forEach((host, index) => {
            formDataToSend.append(`host[${index}]`, host);
        });
        formData.guest.forEach((guest, index) => {
            formDataToSend.append(`guest[${index}]`, guest);
        });

        if (formData.banner) {
            formDataToSend.append('banner', formData.banner);
        }
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        try {
            const response = await axios.put(`${BASE_URL}/admins/podcast/${podcastId}/update/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const updatedPodcast = response.data;
            setPodcasts(prevPodcasts => prevPodcasts.map(podcast => podcast.id === updatedPodcast.id ? { ...podcast, ...updatedPodcast } : podcast));
            alert('Podcast updated successfully!');
            setEditFormVisible(false);
            setSelectedPodcast(null);
            fetchPodcast();
            setFormData({
                name: '',
                date: '',
                starting_time: '',
                ending_time: '',
                youtube_url: '',
                host: [], // Reset to empty array
                guest: [], // Reset to empty array
                banner: null,
                image:null
            });
        } catch (error) {
            console.error('Error updating podcast:', error);
            alert('Failed to update podcast. Please try again.');
        }
    };
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };
    const [hostSearchQuery, setHostSearchQuery] = useState('');
    const [guestSearchQuery, setGuestSearchQuery] = useState('');
    const filteredHosts = speakers.filter(speaker =>
        speaker.name.toLowerCase().includes(hostSearchQuery.toLowerCase())
    );
    const filteredGuests = speakers.filter(speaker =>
        speaker.name.toLowerCase().includes(guestSearchQuery.toLowerCase())
    );
    const [searchHost, setSearchHost] = useState('');
    const [searchGuest, setSearchGuest] = useState('');

    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Podcast</p>
                <div className='flex pt-6 gap-[12rem] p-6'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Podcast</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={toggleMailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Podcast</p>
                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className="w-[50%]">
                        <form className="bg-white" onSubmit={handleSubmit}>
                            {/* Podcast Name */}
                            <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Podcast Name</p>
                            <div className="pt-2">
                                <input
                                    type="text"
                                    id="name"
                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter Podcast Name"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                />
                            </div>
                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Image</p>
                            <div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-2 w-full bg-[#F4F4F4]"
                                    onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
                                />
                            </div>
                            {/* Banner Upload */}
                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Banner</p>
                            <div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-2 w-full bg-[#F4F4F4]"
                                    onChange={(e) => setFormData({ ...formData, banner: e.target.files[0] })}
                                />
                            </div>

                            {/* Host Section with Search */}
                            <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Host</p>
                            <div className="pt-2">
                                <input
                                    type="text"
                                    className="appearance-none border rounded-[4px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Search for host"
                                    value={hostSearchQuery}
                                    onChange={(e) => setHostSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className="relative appearance-none border rounded-[4px] w-full py-4 px-3 max-h-[150px] overflow-y-auto">
                                {filteredHosts.map(speaker => (
                                    <div key={speaker.id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`host-${speaker.id}`}
                                            value={speaker.id}
                                            checked={formData.host.includes(speaker.id.toString())}
                                            onChange={(e) => handleCheckboxChange(e, 'host')}
                                        />
                                        <label htmlFor={`host-${speaker.id}`} className="text-gray-700">{speaker.name}</label>
                                    </div>
                                ))}
                            </div>

                            {/* Guest Section with Search */}
                            <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Guest</p>
                            <div className="pt-2">
                                <input
                                    type="text"
                                    className="appearance-none border rounded-[4px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Search for guest speaker"
                                    value={guestSearchQuery}
                                    onChange={(e) => setGuestSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className="relative appearance-none border rounded-[4px] w-full py-4 px-3 max-h-[150px] overflow-y-auto">
                                {filteredGuests.map(speaker => (
                                    <div key={speaker.id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`guest-${speaker.id}`}
                                            value={speaker.id}
                                            checked={formData.guest.includes(speaker.id.toString())}
                                            onChange={(e) => handleCheckboxChange(e, 'guest')}
                                        />
                                        <label htmlFor={`guest-${speaker.id}`} className="text-gray-700">{speaker.name}</label>
                                    </div>
                                ))}
                            </div>

                            {/* Date and Time Inputs */}
                            <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Date</p>
                            <div className="pt-2">
                                <input
                                    type="date"
                                    id="date"
                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={formData.date}
                                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                />
                            </div>

                            <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Starting Time</p>
                            <div className='pt-2'>
                                <input
                                    type="time"
                                    id="starting_time"
                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={formData.starting_time}
                                    onChange={(e) => setFormData({ ...formData, starting_time: e.target.value })}
                                />
                            </div>

                            <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Ending Time</p>
                            <div className='pt-2'>
                                <input
                                    type="time"
                                    id="ending_time"
                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={formData.ending_time}
                                    onChange={(e) => setFormData({ ...formData, ending_time: e.target.value })}
                                />
                            </div>

                            <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>YouTube URL</p>
                            <div className='pt-2'>
                                <input
                                    type="text"
                                    id="youtube_url"
                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter YouTube URL"
                                    value={formData.youtube_url}
                                    onChange={(e) => setFormData({ ...formData, youtube_url: e.target.value })}
                                />
                            </div>

                            {/* Submit Button */}
                            <div className='pt-4'>
                                <button
                                    type="submit"
                                    className='bg-[#00549A] submit-btn-certificate text-white rounded p-3 font-semibold'
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editFormVisible && (
                            <div>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        placeholder="Search podcast..."
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[50%] relative"
                                    />

                                    <img src={search} alt="Search" className="absolute left-[40rem] top-6 transform -translate-y-1/2" />
                                </div>
                                <div className='bg-gray-100 py-4 w-full flex p-6 gap-[21.5rem]'>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-2 text-[18px] not-italic font-semibold leading-[normal]'>Podcast Name</p>
                                    <p className='text-[color:var(--Gray,#58585A)]  text-[18px] not-italic font-semibold leading-[normal]'>Podcast Date</p>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold leading-[normal]'>Actions</p>
                                </div>

                                {podcasts.filter(podcast => podcast.name.toLowerCase().includes(searchQuery.toLowerCase())).map((podcast, index) => (
                                    <div key={index} className='w-full '>
                                        <div className='flex pt-4 pb-4 pl-8 '>
                                            <div className='flex gap-4'>
                                                <p className='text-[color:var(--Gray,#58585A)] w-[445px] text-[16px] not-italic font-normal leading-[normal]'>{podcast.name}</p>
                                                <p className='text-[color:var(--Gray,#58585A)] w-[450px] text-[16px] not-italic font-normal leading-[normal]'>{podcast.date}</p>
                                            </div>
                                            <div className='flex gap-6'>
                                                <div>
                                                    <img src={ed} alt="" className='' onClick={() => handleEdit(podcast)} />
                                                </div>
                                                <div>
                                                    <img src={del} alt="" className='' onClick={() => handleDelete(podcast.id)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='' />
                                    </div>
                                ))}
                            </div>
                        )}
                        {editFormVisible && selectedPodcast && (
                            <div className='w-[50%]'>
                                <form onSubmit={(e) => handleFormSubmit(e, selectedPodcast.id)}>
                                    <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Podcast Name</p>
                                    <div className="pt-2">
                                        <input
                                            type="text"
                                            id="name"
                                            className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter Podcast Name"
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        />
                                    </div>
                                    {selectedPodcast && (
                                        <div>
                                            <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Banner</p>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="image"
                                                onChange={handleChange1 }
                                                className="block w-full border border-gray-300 rounded-md px-3 py-2 mt-1 bg-white"
                                            />
                                            {formData.image ? (
                                                formData.image instanceof File ? (
                                                    <img src={URL.createObjectURL(formData.image)} alt="image" className="mt-2 w-[30%] h-auto rounded-md" />
                                                ) : (
                                                    <img src={formData.image} alt="Banner" className="mt-2 w-[30%] h-auto rounded-md" />
                                                )
                                            ) : (
                                                selectedPodcast.image ? (
                                                    <img src={selectedPodcast.image} alt="Banner" className="mt-2 w-[30%] h-auto rounded-md" />
                                                ) : (
                                                    <p>No image available</p>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {/* Banner Section */}
                                    {selectedPodcast && (
                                        <div>
                                            <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Banner</p>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="banner"
                                                onChange={handleChange}
                                                className="block w-full border border-gray-300 rounded-md px-3 py-2 mt-1 bg-white"
                                            />
                                            {formData.banner ? (
                                                formData.banner instanceof File ? (
                                                    <img src={URL.createObjectURL(formData.banner)} alt="banner" className="mt-2 w-[30%] h-auto rounded-md" />
                                                ) : (
                                                    <img src={formData.banner} alt="Banner" className="mt-2 w-[30%] h-auto rounded-md" />
                                                )
                                            ) : (
                                                selectedPodcast.banner ? (
                                                    <img src={selectedPodcast.banner} alt="Banner" className="mt-2 w-[30%] h-auto rounded-md" />
                                                ) : (
                                                    <p>No image available</p>
                                                )
                                            )}
                                        </div>
                                    )}

                                    {/* Host Section */}
                                    <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Host</p>
                                    <input
                                        type="text"
                                        placeholder="Search Host"
                                        onChange={(e) => setSearchHost(e.target.value)}
                                        className="appearance-none border rounded-[4px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                                    />
                                    <div className="relative appearance-none border rounded-[4px] w-full py-4 px-3 max-h-[150px] overflow-y-auto">
                                        {speakers
                                            .filter(speaker => speaker.name.toLowerCase().includes(searchHost.toLowerCase()))
                                            .map(speaker => (
                                                <div key={speaker.id} className="flex items-center mb-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`host-${speaker.id}`}
                                                        value={speaker.id}
                                                        checked={formData.host.includes(speaker.id.toString())}
                                                        onChange={(e) => handleCheckboxChange(e, 'host')}
                                                    />
                                                    <label htmlFor={`host-${speaker.id}`} className="text-gray-700">{speaker.name}</label>
                                                </div>
                                            ))}
                                    </div>

                                    {/* Guest Section */}
                                    <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Guest</p>
                                    <input
                                        type="text"
                                        placeholder="Search Guest"
                                        onChange={(e) => setSearchGuest(e.target.value)}
                                        className="appearance-none border rounded-[4px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  "
                                    />
                                    <div className="relative border rounded-[4px] w-full py-4 px-3 max-h-[150px] overflow-y-auto">
                                        {speakers
                                            .filter(speaker => speaker.name.toLowerCase().includes(searchGuest.toLowerCase()))
                                            .map(speaker => (
                                                <div key={speaker.id} className="flex items-center mb-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`guest-${speaker.id}`}
                                                        value={speaker.id}
                                                        checked={formData.guest.includes(speaker.id.toString())}
                                                        onChange={(e) => handleCheckboxChange(e, 'guest')}
                                                    />
                                                    <label htmlFor={`guest-${speaker.id}`} className="text-gray-700">{speaker.name}</label>
                                                </div>
                                            ))}
                                    </div>

                                    {/* Date and Time Fields */}
                                    <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Date</p>
                                    <div className="pt-2">
                                        <input
                                            type="date"
                                            id="date"
                                            className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={formData.date}
                                            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                        />
                                    </div>

                                    <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Starting Time</p>
                                    <div className='pt-2'>
                                        <input
                                            type="time"
                                            id="starting_time"
                                            className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={formData.starting_time}
                                            onChange={(e) => setFormData({ ...formData, starting_time: e.target.value })}
                                        />
                                    </div>

                                    <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Ending Time</p>
                                    <div className='pt-2'>
                                        <input
                                            type="time"
                                            id="ending_time"
                                            className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={formData.ending_time}
                                            onChange={(e) => setFormData({ ...formData, ending_time: e.target.value })}
                                        />
                                    </div>

                                    <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>YouTube URL</p>
                                    <div className='pt-2'>
                                        <input
                                            type="text"
                                            id="youtube_url"
                                            className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter YouTube URL"
                                            value={formData.youtube_url}
                                            onChange={(e) => setFormData({ ...formData, youtube_url: e.target.value })}
                                        />
                                    </div>

                                    <div className='flex'>
                                        <div className='pt-8 w-[500px]'>
                                            <button className='bg-[#00549A] rounded-[10px] w-full py-4'>
                                                <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                            </button>
                                        </div>
                                        <div className='pt-8 w-[25%] ml-6'>
                                            <button className='bg-gray-500 rounded-[10px] w-full py-4' onClick={handleExitEditForm}>
                                                <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Cancel</p>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}


                    </>
                )}
            </div>
        </div>
    );
};

export default Podcast;
