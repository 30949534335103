import React, { useState, useEffect, useRef } from "react";
 
 
import { motion, useAnimation, useInView } from 'framer-motion';
 
import Page1 from "../../../assets/images/OurMission.jpg";
 
 
  

const AboutCard3 = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    return (
        <div class="w-full h-full bg-[#f4f4f4]">
        <div class="container  ">
            <div class="pt-6 mpt-4 md-bg-white">
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <motion.div
                            className="bg-white text-start rounded-lg p-10 p-10-mob mp-5 vision-mission-padding "
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 0.6 }}
                            ref={ref}
                        >
               
                        <p class="text-[#222] text-4xl   font-semibold leading-normal">Our Mission</p>
                        <p class="text-[#58585A] pt-4 md:pt-8 about-text leading-[1.5] missiontext-padding">
                            To enhance high-quality patient-centered  care by supporting clinical pharmacists to practice at the top of their expertise thereby uplifting the clinical pharmacy profession to best serve our patients and our society.
                        </p>
                  
                    </motion.div>
                    <motion.div
                            className=" "
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 8}}
                            transition={{
                                duration: 0.6,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                    <div class="flex items-center justify-center md-p-30">
                        <img src={Page1} alt="Left Arrow" class="w-full md:w-auto rounded-lg" />
                    </div>
                    </motion.div>
                </div>
            </div>
        </div>
    </div>
    
    );
};

export default AboutCard3;
