import react from "react";
import Home from "../../../Components/Home/index";
import Committees from  "../../../Components/User/About/Committees"; 
import Footer from  "../../../Components/Footer/Footer";


const  CommitteePage = () => {

    return (
        <div>
    <Home/>
   <Committees/>
  <Footer/> 
    </div>
    )
}
export default CommitteePage;