import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import cap from "../../../assets/images/lineee.svg";
import blog from "../../../assets/images/Blogs.jpg";
import pdf from "../../../assets/images/pdf-02.png";
import { motion, useAnimation, useInView } from 'framer-motion';

const Newsletter = () => {
    const [newsletters, setNewsletters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9; // Number of newsletters per page
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchNewsletters = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/newsletterslist/`);
                const sortedNewsletters = response.data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
                setNewsletters(sortedNewsletters);
                console.log("Sorted Newsletters:", sortedNewsletters);
            } catch (error) {
                console.error('Error fetching newsletters:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsletters();
    }, []);

    const formatPeriod = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startMonth = start.toLocaleString('default', { month: 'long' });
        const endMonth = end.toLocaleString('default', { month: 'long' });
        const year = end.getFullYear();

        if (startMonth === endMonth) {
            return `${startMonth} ${year}`;
        }
        return `${startMonth}-${endMonth} ${year}`;
    };

    const handleDownload = async (pdfUrl, fileName) => {
        try {
            const response = await axios.get(pdfUrl, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    // Calculate the current newsletters to display
    const indexOfLastNewsletter = currentPage * itemsPerPage;
    const indexOfFirstNewsletter = indexOfLastNewsletter - itemsPerPage;
    const currentNewsletters = newsletters.slice(indexOfFirstNewsletter, indexOfLastNewsletter);

    // Pagination Logic
    const totalPages = Math.ceil(newsletters.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px] pb-8">
                        <div className="grid grid-cols-1 md:grid-cols-2 bg-[#00549A] rounded-t-[30px] relative overflow-hidden brb-30 ptb-30">
                            <div>
                                <p className="text-white text-[32px] not-italic font-semibold leading-[normal] p-8">Newsletter</p>
                                <motion.div
                                    className="blogimg"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 2, ease: "easeInOut" }}
                                    ref={ref}
                                >
                                    <img src={blog} alt="Blogs" className="w-full pt-12 rounded-tr-[30px]" />
                                </motion.div>
                            </div>
                            
                            <div className="start position-relative z-10 pl-30">
                                <p className="text-[#FFc233] text-[23px] pt-28 not-italic font-medium leading-[normal]">Recent Newsletter</p>

                                {newsletters.length > 0 && (
                                    <p className="text-white text-[42px]  pt-4 not-italic font-normal leading-[51px]">
                                        {newsletters[0].title}
                                    </p>
                                )}
                            </div>
                            <div className="bg-line">
                                <img src={cap} alt="Line Decoration" className="w-full pt-12 rounded-tr-[30px]" />
                            </div>
                        </div>

                        <motion.div
                            className="grid grid-cols-3  md:grid-cols-2 lg:grid-cols-3 pr-10 pl-10 pt-100 gap-12"
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 1.5 }}
                            ref={ref}
                        >
                            {currentNewsletters.map((newsletter) => (
                                <div className="bg-[#f4f4f4] hover:bg-[#f4f4f4] p-8 rounded-[10px]" key={newsletter.id}>
                                    <img src={newsletter.thumbnail} alt={newsletter.title} className="rounded-[8px] w-[99%]" />
                                    <p className="text-[#222] pt-4 text-[21px] not-italic font-medium leading-[28px]">
                                        {newsletter.title}
                                    </p>
                                    <div className="justify-between gap-[12rem]">
                                        <p className="text-[#222] text-[16px] pt-4 not-italic font-normal leading-[28px]">{newsletter.edition}</p>
                                    </div>
                                    <p className="text-[color:var(--Gray,#58585A)] pt-4 w-[300px] text-[16px] not-italic font-normal leading-[normal]">
                                        {formatPeriod(newsletter.start_date, newsletter.end_date)}
                                    </p>

                                    <div className='pt-8 w-[65%]'>
                                        <button
                                            onClick={() => handleDownload(newsletter.pdf, `${newsletter.title}.pdf`)}
                                            className='flex justify-between bg-[#1AB78D] rounded-[10px] w-full py-2 p-4'
                                        >
                                            <p className='text-white text-center text-[16px] not-italic font-semibold leading-[normal]'>Download Newsletter</p>
                                            <img src={pdf} alt="PDF Icon" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </motion.div>


                        <div className="flex justify-center items-center pt-6">
                            <button onClick={handlePrevPage} disabled={currentPage === 1} className="bg-gray-300 p-2 rounded ">
                                &lt;
                            </button>
                            <p className="mx-4">Page {currentPage} of {totalPages}</p>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages} className="bg-gray-300 p-2 rounded">
                                &gt;
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
