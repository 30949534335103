import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import { Link } from 'react-router-dom';
import cap from "../../../assets/images/lineee.svg";
import blog from "../../../assets/images/Blogs.jpg";
import arrow from "../../../assets/images/arrow-right-01-round.png";
import arrow3 from "../../../assets/images/arrow3.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import "./Blogs.css";

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [generalBlogs, setGeneralBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Fetch general and regular blogs
                const [blogsResponse, generalBlogsResponse] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/blogslist/`),
                    axios.get(`${BASE_URL}/admins/blogsgeneral/`)
                ]);


                setBlogs(blogsResponse.data);
                setGeneralBlogs(generalBlogsResponse.data);


                const combinedBlogs = [...blogsResponse.data, ...generalBlogsResponse.data];
                const sortedBlogs = combinedBlogs.sort((a, b) => new Date(b.date) - new Date(a.date));

                setLatestBlogs(sortedBlogs.slice(0, 3));


                setBlogs(sortedBlogs);

            } catch (error) {

                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);
    const getRegularBlogs = () => {
        const latestSlugs = latestBlogs.map(blog => blog.slug);
        return blogs.filter(blog => !latestSlugs.includes(blog.slug));
    };


    const allBlogs = [...blogs, ...generalBlogs];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px]">

                        <div className="grid grid-cols-1 md:grid-cols-2 bg-[#00549A] rounded-t-[30px] relative overflow-hidden brb-30 ptb-30">
                            <div>
                                <p className="text-white text-[32px] not-italic font-semibold leading-[normal] pl-50">Blogs</p>

                                <motion.div
                                    className="blogimg "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <img src={blog} alt="Blogs" className="w-full pt-12 rounded-tr-[30px]" />
                                </motion.div>
                            </div>
                            <div className="start position-relative z-10 pl-30">
                                <p className="text-[#FFc233] text-[23px] pt-28 not-italic font-medium leading-[normal]">Recent Blog</p>

                                {latestBlogs.length > 0 && (
                                    <p className="text-white text-[42px] pt-4 not-italic font-normal leading-[51px]">
                                        {latestBlogs[0].title}
                                    </p>
                                )}
                            </div>


                            <div className="bg-line">
                                <img src={cap} alt="Line Decoration" className="w-full pt-12 rounded-tr-[30px]" />
                            </div>
                        </div>



                        <motion.div
                            className="grid grid-cols-3 b-p-l-12 blo-pt-60 gap-12 mb-gap-12"
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 1 }}
                            ref={ref}
                        >
                            {latestBlogs.map((blog) => (
                                <Link
                                    to={generalBlogs.some(b => b.slug === blog.slug) ? `/general-blogs-detail/${blog.slug}` : `/blogs-detail/${blog.slug}`}
                                    key={blog.slug}
                                >
                                    <div className="bg-white hover:bg-[#f4f4f4] b-all-12 rounded-[10px]">
                                        <img src={BASE_URL + blog.blog_banner} alt={blog.title} className="rounded-[30px] w-[99%]" />
                                        <p className="text-[#222] bl-pt-8 text-[21px] not-italic font-medium leading-[28px]">
                                            {blog.title}
                                            <p className='text-[#222] pt-2 text-[19px] not-italic font-medium leading-[28px]'>{blog.forum_title}</p>
                                        </p>
                                        <div className="flex justify-between pt-12  ">
                                            <p className="text-[#222] text-[16px] not-italic font-normal leading-[28px]">{formatDate(blog.date)}</p>
                                            <img src={arrow3} alt="Arrow" className="pl-8" />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </motion.div>



                        <div className="pt-12 pl-12 pr-12">

                            {getRegularBlogs().map(blog => (
                                <div key={blog.slug} className="pt-8">
                                    <Link to={generalBlogs.some(b => b.slug === blog.slug) ? `/general-blogs-detail/${blog.slug}` : `/blogs-detail/${blog.slug}`}>
                                        <div className="flex justify-between">
                                            <div>
                                                <p className="text-[#222] text-[29px] not-italic font-normal leading-[normal]">{blog.title}</p>
                                                <p className="blog-smalltitle">{blog.forum_title}</p>
                                            </div>
                                            <p className="text-[#222] text-[17px] not-italic font-normal leading-[normal] mt-200">{formatDate(blog.date)}</p>
                                        </div>
                                        <hr className="mt-6" />
                                    </Link>
                                </div>
                            ))}

                        </div>




                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blogs;
