import React, { useState, useEffect ,useRef} from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import { BASE_URL } from '../../Utils/Config';
import gr from "../../assets/images/graph4.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import './Card.css';
const Card3 = () => {
  const [totalEvents, setTotalEvents] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [speakerCount, setSpeakerCount] = useState(0);
  const [newsletterCount, setNewsletterCount] = useState(0);
  const [podcastCount, setPodcastCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [chartData, setChartData] = useState({ labels: [], series: [] });
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('days');
   // Default to 'days'

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch general data
        const [eventResponse, memberResponse, speakerResponse, newsletterResponse, podcastResponse, userResponse] = await Promise.all([
          axios.get(`${BASE_URL}/admins/total-events/`),
          axios.get(`${BASE_URL}/admins/member-count/`),
          axios.get(`${BASE_URL}/admins/speaker-count/`),
          axios.get(`${BASE_URL}/admins/newsletter-count/`),
          axios.get(`${BASE_URL}/admins/podcast-count/`),
          axios.get(`${BASE_URL}/accounts/user-count/`)
        ]);

        setTotalEvents(eventResponse.data.total_events);
        setMemberCount(memberResponse.data.member_count);
        setSpeakerCount(speakerResponse.data.speaker_count);
        setNewsletterCount(newsletterResponse.data.newsletter_count);
        setPodcastCount(podcastResponse.data.podcast_count);
        setUserCount(userResponse.data.user_count);

        // Fetch user count data based on the activeTab
        const countResponse = await axios.get(
          activeTab === 'months'
            ? `${BASE_URL}/accounts/monthly-user-count/`
            : `${BASE_URL}/accounts/last-10-days-user-count/`
        );

        const { months = [], counts = [] } = countResponse.data || {};

        // Format the dates
        const formattedLabels = activeTab === 'months'
          ? months.map(dateString => {
            const date = new Date(dateString);
            const options = { month: 'short', day: '2-digit' };
            return date.toLocaleDateString('en-US', options).replace(',', '');
          })
          : counts.map((_, i) => {
            const date = new Date();
            date.setDate(date.getDate() - (10 - i)); // Adjust the date for last 10 days
            return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
          });

        setChartData({
          labels: formattedLabels,
          series: [
            {
              name: 'User Counts',
              type: 'bar',
              data: counts
            },
            {
              name: 'Growth',
              type: 'line',
              data: counts.map((_, i) => counts.slice(0, i + 1).reduce((a, b) => a + b, 0)) // Cumulative sum for growth
            }
          ]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]); // Add activeTab to dependency array

  const chartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: { enabled: false },
      toolbar: {
        show: true,
        tools: {
          download: false, // Disables the download button
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    stroke: { width: [0, 2] },
    plotOptions: {
      bar: { columnWidth: '55%', endingShape: 'rounded' }
    },
    xaxis: {
      categories: chartData.labels,
      labels: {
        style: { fontSize: '10px', colors: ['#000'] }
      }
    },
    yaxis: [
      {
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            const customTicks = [0, 10, 20, 40, 60, 80, 100];
            return customTicks.includes(value) ? value : '';
          }
        }
      },
      {
        opposite: true,
        min: 0
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: { formatter: (val) => `Count: ${val}` }
    }
  };
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
      if (isInView) {
          controls.start({ opacity: 1, y: 0 });
      } else {
          controls.start({ opacity: 0, y: 50 });
      }
  }, [isInView, controls]);
  return (
    <div className="w-full h-full pt-6 bg-[#f4f4f4]">
      <div className="container">
        <motion.div
          className="  "
          initial={{ opacity: 0 }}
          animate={{ opacity: 4 }}
          transition={{
            duration: 0.6,
            ease: "easeInOut",
          }}
          ref={ref}
        >
          <div className="flex counter-block gap-6">
            <div className='bg-white rounded-[30px] w-[52%] mob-full-width relative count-bg'>
              <div className='p-counter'>
                <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 grid-rows-2 pt-4 gap-10'>
                  <div className='count-item'>
                    <div className='counter-div'>
                      <p className='text-[#58585A] fcpi-counter '>Total Events</p>
                      <p className='text-[#00549A] fcpi-counternum font-semibold'>{totalEvents}</p>
                    </div>
                  </div>
                  <div className='count-item p-counter-item'>
                    <div className='counter-div'>
                    <p className='text-[#58585A] fcpi-counter '>FCPI Members</p>
                    <p className='text-[#00549A] fcpi-counternum font-semibold'>{memberCount}</p>
                  </div>
                  </div>

                  <div className='p-counter-item'>
                    <p className='text-[#58585A] fcpi-counter '>Podcasts</p>
                    <p className='text-[#00549A] fcpi-counternum font-semibold'>{podcastCount}</p>
                  </div>

                  <div className='count-item'>
                    <div className='counter-div'>
                    <p className='text-[#58585A] fcpi-counter'>Newsletters</p>
                    <p className='text-[#00549A] fcpi-counternum font-semibold'>{newsletterCount}</p>
                    </div>
                  </div>

                  

                  <div className='p-counter-item pt-4'>
                    <p className='text-[#58585A] fcpi-counter '>Speakers Collaborated</p>
                    <p className='text-[#00549A] fcpi-counternum font-semibold'>{speakerCount}</p>
                  </div>
                  
                   </div>
                  
                
              </div>
              <img src={gr} alt="" className='absolute right-0 top-50 transform -translate-y-1/2' />
            </div>

            <div className='bg-white rounded-[30px] w-[48%] mob-full-width mob-mt-6'>
              <div className='p-6'>
                <div className='flex gap-4'>
                  <p className='text-[#58585A] text-[24px]'>Website Users</p>
                  <p className='text-[#1AB78D] text-[24px] font-bold'>{userCount}</p>
                </div>
                <div className='pt-2 flex justify-between'>
                  <div>
                    <p className='text-[#686868] text-[13px]'>User Count</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <button
                    className={`px-2 py-1 rounded ${activeTab === 'months' ? 'bg-custom-blue text-white' : 'bg-gray-200 text-gray-800'}`}
                    onClick={() => setActiveTab('months')}
                  >
                    Last 10 Months
                  </button> */}
                    <button
                      className={`px-2 py-1 rounded ${activeTab === 'days' ? 'bg-custom-blue text-white' : 'bg-gray-200 text-gray-800'}`}
                      onClick={() => setActiveTab('days')}
                    >
                      Last 10 Days
                    </button>
                  </div>
                </div>
                <div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <ApexCharts
                      options={chartOptions}
                      series={chartData.series}
                      type="line"
                      height={246}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>


          
          </motion.div>
      </div>
    </div>
  );
};

export default Card3;
