import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/add-events.svg";
import imag1 from "../../../assets/images/manage-event.svg";
import imag2 from "../../../assets/images/add-attachment.svg";
import imag3 from "../../../assets/images/manage-attachment.svg";

import del from "../../../assets/images/delete-02.png";

import ed from "../../../assets/images/edit.png";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config'; 

import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom"
import search from "../../../assets/images/search.png";
import plus from "../../../assets/images/plus_icon.svg"
import cancel from "../../../assets/images/cancel_icon.svg"
import "./AddEvent.css"




const GeneralEvents = ({ eventId }) => {

    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [attachmentActive, setAttachmentActive] = useState(false);
    const [attachmentEditActive, setAttachmentEditActive] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState('');
    const [filteredSpeakers, setFilteredSpeakers] = useState([]);
    const [selectedSpeakers, setSelectedSpeakers] = useState([]);
    const [eventData, setEventData] = useState()
    const [selectedImage, setSelectedImage] = useState(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {

        const filtered = speakers.filter(speaker =>
            speaker.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredSpeakers(filtered);
    }, [speakers, searchQuery]);

    const [formData, setFormData] = useState({
        days: '',
       
        event_name: '',
        date: '',
        speakers: [],
        banner: null,
        single_speaker: '',
        youtube_link: '',
        points: '',
        starting_time: '',
        ending_time: '',
        topics: '',
        highlights: []
    });

    const fetchEventData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/general_allevents/`);
            const fetchedEventData = response.data;
            console.log("events ", fetchedEventData);
            setEventData(fetchedEventData);
        } catch (error) {
            console.error('Error fetching event data:', error);
            toast.error('Error fetching event data');
        }
    };

    useEffect(() => {
        fetchEventData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;


        if (name === 'date') {

            const formattedDate = formatDate(value);

            setFormData(prevState => ({
                ...prevState,
                [name]: formattedDate
            }));
        } else {

            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    const handleInputChange = (e, parentKey, index, field) => {
        const { name, value } = e.target;

        // Ensure name is not empty
        if (!name) {
            console.error('Input element missing "name" attribute.');
            return;
        }

        if (parentKey) {
            // Handle nested fields
            setFormData(prevState => {
                const updatedParentData = [...prevState[parentKey]];
                updatedParentData[index] = {
                    ...updatedParentData[index],
                    [field]: value
                };

                return {
                    ...prevState,
                    [parentKey]: updatedParentData
                };
            });
        } else {
            // Handle top-level fields
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    const addHighlight1 = (dayIndex) => {
        setFormData(prevState => {
            const updatedSingleEvents = [...prevState.single_events];

            // Ensure the dayIndex exists
            if (!updatedSingleEvents[dayIndex]) {
                updatedSingleEvents[dayIndex] = {};
            }

            // Initialize highlights if not present
            const updatedHighlights = [...(updatedSingleEvents[dayIndex].highlights || [])];
            updatedHighlights.push(""); // Add a new empty highlight

            // Update the single_events with the modified highlights
            updatedSingleEvents[dayIndex] = {
                ...updatedSingleEvents[dayIndex],
                highlights: updatedHighlights
            };

            return {
                ...prevState,
                single_events: updatedSingleEvents
            };
        });
    };





    const handleChange1 = (index, event) => {
        const newHighlights = [...formData.highlights];
        newHighlights[index] = event.target.value;
        setFormData({
            ...formData,
            highlights: newHighlights
        });
    };

 

    const handleMultiEventChange = (e, multiEventIndex, singleEventIndex, field) => {
        const { value } = e.target;

        // Check if single_events is an array
        if (!Array.isArray(formData.single_events)) {
            console.error('formData.single_events is not an array');
            return;
        }

        const updatedSingleEvents = [...formData.single_events];
        const updatedMultiEvents = [...updatedSingleEvents[singleEventIndex].multi_events];

        updatedMultiEvents[multiEventIndex] = {
            ...updatedMultiEvents[multiEventIndex],
            [field]: value
        };

        updatedSingleEvents[singleEventIndex] = {
            ...updatedSingleEvents[singleEventIndex],
            multi_events: updatedMultiEvents
        };

        setFormData({
            ...formData,
            single_events: updatedSingleEvents
        });
    };



    const formatDate = (dateString) => {

        if (dateString && dateString !== 'NaN-NaN-NaN') {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        } else {

            return '';
        }
    };

    const [editFormVisible, setEditFormVisible] = useState(false);
    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };



    const handleImageChange = (e) => {
        const bannerFile = e.target.files[0];
        setSelectedImage(bannerFile);
        setFormData(prevFormData => ({
            ...prevFormData,
            banner: bannerFile
        }));
    };

    const [scheduleHighlights, setScheduleHighlights] = useState([]);
    useEffect(() => {
        setScheduleHighlights(Array.from({ length: formData.days }, () => [""]));  
    }, [formData.days]);
    const handleHighlightChange = (dayIndex, index, event) => {
        const { name, value } = event.target;

        setScheduleHighlights(prevHighlights => {
            const newHighlights = [...prevHighlights];
            if (!newHighlights[dayIndex]) {
                newHighlights[dayIndex] = [];
            }
            newHighlights[dayIndex][index] = value;

            return newHighlights;
        });
    };
    const addHighlight = (dayIndex) => {
        setScheduleHighlights(prevHighlights => {
            const newHighlights = [...prevHighlights];
            if (!newHighlights[dayIndex]) {
                newHighlights[dayIndex] = [];
            }
            newHighlights[dayIndex].push("");
            return newHighlights;
        });
    };

    const removeHighlight = (dayIndex, index) => {
        setScheduleHighlights(prevHighlights => {
            const newHighlights = [...prevHighlights];
            if (newHighlights[dayIndex]) {
                newHighlights[dayIndex].splice(index, 1);
            }
            return newHighlights;
        });
    };

    const [dropdownStates, setDropdownStates] = useState([]);

     
    const removeHighlight1 = (dayIndex, index) => {
        setFormData(prevState => {
            const updatedSingleEvents = [...prevState.single_events];

            // Ensure the dayIndex and index exist
            if (updatedSingleEvents[dayIndex]) {
                const updatedHighlights = [...(updatedSingleEvents[dayIndex].highlights || [])];

                // Remove the highlight at the specified index
                if (index >= 0 && index < updatedHighlights.length) {
                    updatedHighlights.splice(index, 1);
                }

                // Update the single_events with the modified highlights
                updatedSingleEvents[dayIndex] = {
                    ...updatedSingleEvents[dayIndex],
                    highlights: updatedHighlights
                };
            }

            return {
                ...prevState,
                single_events: updatedSingleEvents
            };
        });
    };



    useEffect(() => {
        console.log("formData.days:", formData.days);
        setScheduleFormData(Array.from({ length: formData.days }, () => [{}]));
    }, [formData.days]);


    const handleHighlightChange1 = (dayIndex, index, event) => {
        if (!event || !event.target) {
            console.error('Invalid event object');
            return;
        }

        const { value } = event.target;

        setFormData(prevState => {
            const updatedSingleEvents = [...prevState.single_events];

            if (!updatedSingleEvents[dayIndex]) {
                updatedSingleEvents[dayIndex] = {};
            }

            const updatedHighlights = [...(updatedSingleEvents[dayIndex].highlights || [])];
            updatedHighlights[index] = value;

            // Update the single_events with the modified highlights
            updatedSingleEvents[dayIndex] = {
                ...updatedSingleEvents[dayIndex],
                highlights: updatedHighlights
            };

            return {
                ...prevState,
                single_events: updatedSingleEvents
            };
        });
    };


    const [scheduleFormData, setScheduleFormData] = useState([]);

    const handleScheduleChange = (e, dayIndex, scheduleIndex) => {
        const { name, value } = e.target;

        setScheduleFormData(prevScheduleData => {
            const updatedScheduleData = [...prevScheduleData];
            if (!updatedScheduleData[dayIndex]) {
                updatedScheduleData[dayIndex] = [];
            }
            if (!updatedScheduleData[dayIndex][scheduleIndex]) {
                updatedScheduleData[dayIndex][scheduleIndex] = {};
            }
            updatedScheduleData[dayIndex][scheduleIndex][name] = value;
            return updatedScheduleData;
        });
    };




    const [showAdditionalFields, setShowAdditionalFields] = useState(false);


    const handleAddButtonClick = (dayIndex) => {
        setScheduleFormData(prevScheduleData => {
            const updatedScheduleData = [...prevScheduleData];
            if (!updatedScheduleData[dayIndex]) {
                updatedScheduleData[dayIndex] = [];
            }
            updatedScheduleData[dayIndex].push({
                starting_time: '',
                ending_time: '',
                topics: '',
                single_speaker: ''
            });
            return updatedScheduleData;
        });
    };
    const handleRemovedButtonClick = (dayIndex, scheduleIndex) => {
        setScheduleFormData(prevScheduleData => {
            const updatedScheduleData = [...prevScheduleData];
            if (updatedScheduleData[dayIndex] && updatedScheduleData[dayIndex].length > 1) {
                updatedScheduleData[dayIndex].splice(scheduleIndex, 1);
            }
            return updatedScheduleData;
        });
    };



    const [speakerList, setSpeakerList] = useState([]);

    const handleSpeakerSearch = (searchQuery) => {
        const filtered = speakers.filter(speaker =>
            speaker.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredSpeakers(filtered);

        setSpeakerList(filtered.length > 0);
    };
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Ensure eventData is an array before calling .filter
    const filteredEvents = Array.isArray(eventData)
        ? eventData
            .filter(event =>
                event.event_name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA; 
            })
        : [];



   

    const handleCheckboxChange = (e, speakerId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedSpeakers(prevSelected => [...prevSelected, speakerId]);
        } else {
            setSelectedSpeakers(prevSelected => prevSelected.filter(id => id !== speakerId));
        }
    };
    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            speakers: selectedSpeakers
        }));
    }, [selectedSpeakers]);

    const handleCheckboxChange1 = (e, speakerId) => {
        if (e.target.checked) {
            setSelectedSpeakers(prev => [...prev, speakerId]);
        } else {
            setSelectedSpeakers(prev => prev.filter(id => id !== speakerId));
        }
    };

    // Verify the selectedSpeakers array
    console.log(selectedSpeakers);

    const handleAddSpeaker = () => {
        if (selectedSpeaker) {
            setFormData(prevState => ({
                ...prevState,
                speakers: [...prevState.speakers, selectedSpeaker]
            }));
            setSelectedSpeaker('');
        }
    };
    const handleRemoveSpeaker = (speakerId) => {
        setSelectedSpeakers(prevSpeakers => prevSpeakers.filter(id => id !== speakerId));
    };

    const handleRemovedButtonClick1 = (index, dayIndex) => {
        setFormData(prevFormData => {
            const updatedSingleEvents = [...prevFormData.single_events];
            const updatedMultiEvents = updatedSingleEvents[dayIndex].multi_events.filter((_, i) => i !== index);
            updatedSingleEvents[dayIndex] = { ...updatedSingleEvents[dayIndex], multi_events: updatedMultiEvents };
            return { ...prevFormData, single_events: updatedSingleEvents };
        });
    };


    const handleSpeakerChangeForSection = (e, dayIndex, scheduleIndex) => {
        const value = e.target.value;
        setScheduleFormData(prevScheduleData => {
            const updatedScheduleData = [...prevScheduleData];
            if (!updatedScheduleData[dayIndex]) {
                updatedScheduleData[dayIndex] = [];
            }
            if (!updatedScheduleData[dayIndex][scheduleIndex]) {
                updatedScheduleData[dayIndex][scheduleIndex] = {};
            }
            updatedScheduleData[dayIndex][scheduleIndex].single_speaker = value;
            return updatedScheduleData;
        });
    };



    useEffect(() => {
        const fetchspeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/speakers/`);
                setSpeakers(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching  spekers:', error);
            }
        };

        fetchspeakers();
    }, []);
    const initialFormData = {
        days: '',
     
        event_name: '',
        date: '',
        speakers: [],
        banner: null,
        single_speaker: '',
        youtube_link: '',
        points: '',
        starting_time: '',
        ending_time: '',
        topics: '',
        highlights: []
    };

    // Initial state for other states
    const initialSelectedSpeakers = [];
    const initialScheduleFormData = [];
    const initialScheduleHighlights = [];
    const initialDropdownStates = [];
    const resetForm = () => {
        setFormData(initialFormData);
        setSelectedSpeakers(initialSelectedSpeakers);
        setScheduleFormData(initialScheduleFormData);
        setScheduleHighlights(initialScheduleHighlights);
        setDropdownStates(initialDropdownStates);
        setSelectedSpeaker('');
        setFilteredSpeakers([]);
        setSpeakerList([]);
    };

    const handleMultiSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const currentDate = new Date();
            const selectedDate = new Date(formData.date);
            const selectedTime = new Date(`01-01-2000 ${formData.time}`);
    
            const selectedDateTime = new Date(selectedDate);
            selectedDateTime.setHours(selectedTime.getHours(), selectedTime.getMinutes());
    
            const singleEventsData = [];
    
            const formatDate = (dateString) => {
                if (dateString && dateString !== 'NaN-NaN-NaN') {
                    const date = new Date(dateString);
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
                } else {
                    return '';
                }
            };
    
            scheduleFormData.forEach((daySchedule, index) => {
                const singleEventData = {
                    highlights: (scheduleHighlights[index] || []).map(highlight => {
                        return (typeof highlight === 'string' ? highlight.trim() : '');
                    }).filter(highlight => highlight.trim() !== ''),
                    youtube_link: '',
                    points: 0,
                    multi_events: []
                };
    
                daySchedule.forEach((schedule, subIndex) => {
                    console.log(`Schedule for Day ${index + 1}, Event ${subIndex + 1}:`, schedule);
    
                    if (schedule.hasOwnProperty('youtube_link') && schedule.youtube_link.trim() !== '') {
                        singleEventData.youtube_link = schedule.youtube_link.trim();
                    }
    
                    const points = schedule.points !== undefined && schedule.points !== '' ? parseFloat(schedule.points) : 0;
                    singleEventData.points += points;
    
                    const eventData = {
                        starting_time: formatTime(schedule.starting_time),
                        ending_time: formatTime(schedule.ending_time),
                        topics: schedule.topics,
                        single_speaker: schedule.single_speaker
                    };
    
                    singleEventData.multi_events.push(eventData);
                });
    
                singleEventsData.push(singleEventData);
            });
    
            const requestData = {
                event_name: formData.event_name,
                date: formatDate(formData.date),
                days: formData.days,
                speakers: selectedSpeakers.map(speaker => speaker.toString()),
                single_events: singleEventsData
            };
    
            if (formData.banner) {
                requestData.banner = formData.banner;
            }
    
            console.log('Request Data:', requestData);
    
            const formDataToSend = new FormData();
            Object.keys(requestData).forEach(key => {
                if (Array.isArray(requestData[key])) {
                    requestData[key].forEach(item => {
                        if (key === 'single_events') {
                            formDataToSend.append(`${key}[]`, JSON.stringify(item));
                        } else {
                            formDataToSend.append(key, item);
                        }
                    });
                } else {
                    formDataToSend.append(key, requestData[key]);
                }
            });
    
            const response = await axios.post(`${BASE_URL}/admins/general_eventcreate/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            console.log(response.data);
            alert('Event added successfully!');
            window.location.reload();
            resetForm();
        } catch (error) {
            console.error('Error adding event:', error);
            alert('Failed to add event. Please try again.');
        }
    };
    



    const formatTime = (time) => {
        if (!time) return '';
        const parts = time.split(':');
        let hours = parseInt(parts[0]);
        const minutes = parts[1];
        const suffix = parts[2];

        if (suffix) {
            if (suffix.toLowerCase() === 'pm' && hours < 12) {
                hours += 12;
            } else if (suffix.toLowerCase() === 'am' && hours === 12) {
                hours = 0;
            }
        }

        return `${hours}:${minutes}:00`;
    };




    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
            setAttachmentActive(false)
            setAttachmentEditActive(false)
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
            setAttachmentActive(false)
            setAttachmentEditActive(false)
        }
    };

 
    const toggleAttachmentAddress = () => {
        setAttachmentActive(!attachmentActive);

        if (!attachmentActive) {
            setPersonalInfoActive(false);
            setmailingAddressActive(false);
            setAttachmentEditActive(false)
        }
    };

    const toggleEditAttachment = () => {
        setAttachmentEditActive(!attachmentEditActive);

        if (!attachmentEditActive) {
            setPersonalInfoActive(false);
            setmailingAddressActive(false);
            setAttachmentActive(false)
        }
    };


    const handleDeleteEvent = async (eventId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this event?');

        if (confirmDelete) {
            try {
                await axios.delete(`${BASE_URL}/admins/general_events/${eventId}/delete/`);
                alert('Event deleted successfully!');

                // Refresh the list of events
                fetchEventData();
            } catch (error) {
                console.error('Error deleting event:', error);
                alert('Failed to delete event. Please try again.');
            }
        }
    };

    const [selectedImage1, setSelectedImage1] = useState(null);
    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                banner: file
            });
        }
    };


    const [imageUrl, setImageUrl] = useState(formData.banner || '');


    useEffect(() => {
        if (selectedImage1) {
            setImageUrl(selectedImage1);

            // Cleanup function to revoke object URL
            return () => URL.revokeObjectURL(selectedImage1);
        } else {
            setImageUrl(formData.banner || '');
        }
    }, [selectedImage1, formData.banner]);


    const handleEditButtonClick = (eventId) => {
        setEditEventId(eventId);
        const eventToEdit = eventData.find(event => event.id === eventId);

        if (eventToEdit) {
            console.log('Editing event:', eventToEdit);

            // Initialize formDataToUpdate
            let formDataToUpdate = {
                days: eventToEdit.days || '',
                forum: eventToEdit.forum || '',
                event_name: eventToEdit.event_name || '',
                date: eventToEdit.date || '',
                speakers: eventToEdit.speakers ? eventToEdit.speakers.map(speaker => speaker.id) : [],
                banner: eventToEdit.banner || null,


                single_events: []
            };

            console.log('Initial formDataToUpdate:', formDataToUpdate);

            // Handle multiple single events if present
            if (eventToEdit.single_events && eventToEdit.single_events.length > 0) {
                const singleEvents = eventToEdit.single_events;
                console.log('Single Events:', singleEvents);

                formDataToUpdate.single_events = singleEvents.map(singleEvent => ({

                    youtube_link: singleEvent.youtube_link || '',
                    points: singleEvent.points || '',
                    highlights: singleEvent.highlights ? singleEvent.highlights.split(',').map(h => h.trim()) : [],
                    multi_events: singleEvent.multi_events || [],

                }));

                console.log('Updated formDataToUpdate with single events:', formDataToUpdate);
            }

            setFormData(formDataToUpdate);

            // Schedule form data initialization
            let updatedScheduleFormData = Array.from({ length: formDataToUpdate.days }, (_, dayIndex) => {
                console.log(`Processing schedule for day ${dayIndex + 1}`);
                return (eventToEdit.schedule && eventToEdit.schedule[dayIndex]) ?
                    eventToEdit.schedule[dayIndex].map(item => ({

                        starting_time: item.starting_time || '',
                        ending_time: item.ending_time || '',
                        topics: item.topics || '',
                        single_speaker: item.single_speaker || ''
                    })) :
                    [{ starting_time: '', ending_time: '', topics: '', single_speaker: '' }];
            });

            console.log('Updated Schedule Form Data:', updatedScheduleFormData);
            setScheduleFormData(updatedScheduleFormData);

            let updatedScheduleHighlights = Array.from({ length: formDataToUpdate.days }, (_, dayIndex) => {
                console.log(`Processing highlights for day ${dayIndex + 1}`);
                return (eventToEdit.highlights && eventToEdit.highlights[dayIndex]) ?
                    eventToEdit.highlights[dayIndex].split(',').map(h => h.trim()) :
                    [];
            });

            console.log('Updated Schedule Highlights:', updatedScheduleHighlights);
            setScheduleHighlights(updatedScheduleHighlights);

            // Set selected speakers
            if (eventToEdit.speakers) {
                setSelectedSpeakers(eventToEdit.speakers.map(speaker => speaker.id));
            } else {
                setSelectedSpeakers([]);
            }

            setEditFormVisible(true);
            setDisplaySectionVisible(false);
        } else {
            console.error(`Event with ID ${eventId} not found.`);
        }
    };





    const handleAddButtonClick1 = (dayIndex) => {
        setFormData(prevFormData => {
            const singleEvents = Array.isArray(prevFormData.single_events) ? prevFormData.single_events : [];
            if (!Array.isArray(singleEvents[dayIndex].multi_events)) {
                singleEvents[dayIndex].multi_events = [];
            }
            const updatedMultiEvents = [...singleEvents[dayIndex].multi_events, {
                starting_time: '',
                ending_time: '',
                topics: '',
                single_speaker: ''
            }];
            const updatedSingleEvents = singleEvents.map((events, index) =>
                index === dayIndex ? { ...events, multi_events: updatedMultiEvents } : events
            );
            return { ...prevFormData, single_events: updatedSingleEvents };
        });
    }







    const [editEventId, setEditEventId] = useState(null);

    const [refreshFlag, setRefreshFlag] = useState(false);
    const [events, setEvents] = useState([]);

    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Create a FormData object to send data
        const formDataToSend = new FormData();
    
        // Debugging: Check the initial formData
        console.log("formData", formData);
    
        // Append non-empty fields to FormData
        if (formData.event_name) formDataToSend.append('event_name', formData.event_name);
        if (formData.date) formDataToSend.append('date', formData.date);
        if (formData.days) formDataToSend.append('days', formData.days);
        if (formData.forum) formDataToSend.append('forum', formData.forum);
    
        // Append speakers
        formData.speakers.forEach((speakerId) => {
            if (speakerId) formDataToSend.append('speakers', speakerId);
        });
    
        // Append banner if it's a File object
        if (formData.banner instanceof File) {
            formDataToSend.append('banner', formData.banner);
        }
    
        // Handle optional fields
        if (formData.youtube_link && formData.youtube_link !== 'undefined') {
            formDataToSend.append('youtube_link', formData.youtube_link);
        }
        if (formData.points && formData.points !== 'undefined') {
            formDataToSend.append('points', formData.points);
        }
    
        // Convert JSON arrays to strings and append them
        const appendJsonField = (key, value) => {
            if (value && value.length > 0) {
                formDataToSend.append(key, JSON.stringify(value));
            }
        };
    
        appendJsonField('highlights', formData.highlights);
        appendJsonField('multi_events', formData.multi_events);
        appendJsonField('single_events', formData.single_events);
    
        // Debugging: Check FormData contents
        console.log("FormData to send:", Array.from(formDataToSend.entries()));
    
        try {
            let response;
            const url = !editEventId
                ? `${BASE_URL}/admins/general_events/`
                : `${BASE_URL}/admins/general_events/${editEventId}/update/`;
    
            const method = !editEventId ? 'post' : 'put';
    
            response = await axios({
                method,
                url,
                data: formDataToSend,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
    
            console.log(`${!editEventId ? 'Event added' : 'Event updated'}:`, response.data);
            alert(`${!editEventId ? 'Event added' : 'Event updated'} successfully!`);
    
            fetchEventData();
    
            setEventData(prevEventData => {
                if (!editEventId) {
                    return [...prevEventData, response.data];
                } else {
                    return prevEventData.map(event => event.id === response.data.id ? response.data : event);
                }
            });
    
            // Reset the form after submission
            setFormData({
                event_name: '',
                date: '',
                days: '',
                forum: '',
                speakers: [],
                banner: null,
                youtube_link: '',
                points: '',
                highlights: [],
                multi_events: [],
                single_events: []
            });
    
            setEditFormVisible(false);
            setDisplaySectionVisible(true);
        } catch (error) {
            console.error('Error updating event:', error);
            alert('Failed to update event. Please try again.');
        }
    };
    
    

    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [singleEvents, setSingleEvents] = useState([]);
    const [selectedSingleEvent, setSelectedSingleEvent] = useState('');
    const [file, setFile] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [attachments, setAttachments] = useState([]);

    const handleSingleEventChange = (e) => {
        setSelectedSingleEvent(e.target.value);
    };

    const handleAttachmentSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile || !selectedSingleEvent) {
            setError('Please select a single event and a file.');
            return;
        }

        setLoading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('single_event', selectedSingleEvent);

        try {
            const response = await axios.post(`${BASE_URL}/admins/general_upload/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Upload response:', response);
            alert("File uploaded successfully");
            setSelectedFile(null);
            setSelectedSingleEvent('');
            window.location.reload();
        } catch (err) {
            console.error('Error uploading file:', err.response ? err.response.data : err.message);
            toast.error("Error uploading file");
        } finally {
            setLoading(false);
        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log('Selected file:', file);
        setSelectedFile(file);
    };

    useEffect(() => {
        const fetchSingleEventData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_singleevents_all/`);
                setSingleEvents(response.data);
            } catch (error) {
                console.error('Error fetching single events:', error);
                toast.error("Error fetching single events");
            }
        };

        fetchSingleEventData();
    }, []);
    const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);



    
    useEffect(() => {
        const fetchAttachmentDetails = async () => {
            if (!selectedAttachmentId) return;

            try {
                const response = await axios.get(`${BASE_URL}/admins/attachments/${selectedAttachmentId}/`);
                const attachmentData = response.data;

                console.log(' particular attachment:', attachmentData);

                setSelectedSingleEvent(attachmentData.single_event);  
                setCurrentFile(attachmentData.file);  
            } catch (error) {
                console.error('Error fetching attachment details:', error);
                toast.error("Error fetching attachment details");
            }
        };

        fetchAttachmentDetails();
    }, [selectedAttachmentId]);

    const fetchAttachments = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/general_attachments-all/`);
            setAttachments(response.data);
            console.log("attatchmentdata", response.data)
        } catch (error) {
            console.error('Error fetching attachments:', error);
            toast.error("Error fetching attachments");
            setError('Failed to load attachments');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAttachments();
    }, []);

    useEffect(() => {
        console.log('Attachments:', attachments);
    }, [attachments]);

    const [searchInput, setSearchInput] = useState('');
    const [searchfilteredEvents, setFilteredEvents] = useState([]);

    const handleSearchChange1 = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        setFilteredEvents(
            singleEvents.filter(event =>
                event.event_name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleEventSelect = (event) => {
        setSearchInput(event.event_name);
        handleSingleEventChange({ target: { value: event.id } });
        setFilteredEvents([]);
    };

    const handleDeleteAttachment = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this attachment?");
        if (!confirmed) {
            return;
        }

        try {
            const response = await axios.delete(`${BASE_URL}/admins/general_attachments/${id}/delete/`);
            console.log('Response:', response);
            if (response.status === 204) {
                const updatedAttachments = attachments.filter(attachment => attachment.id !== id);
                setAttachments(updatedAttachments);
                alert('Attachment deleted successfully');
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (err) {
            console.error('Error deleting attachment:', err.response ? err.response.data : err.message);
            alert('Error deleting attachment');
        }
    };

 





    const [editFormData, setEditFormData] = useState({ event_name: '', file: null });

    const [editFile, setEditFile] = useState(null);

    const handleEditFileChange = (e) => {
        const file = e.target.files[0];
        setEditFile(file);
        setEditFormData(prevFormData => ({
            ...prevFormData,
            file: file
        }));
    };

    const [editAttachment, setEditAttachment] = useState(null);
    const [selectedAttachment, setSelectedAttachment] = useState(null);

     


    const [editattaFormVisible, setEditattaFormVisible] = useState(false);

    const handleEditClick = (attachment) => {
        setSelectedAttachment(attachment);
        setEditattaFormVisible(true);
        setDisplayattSectionVisible(false);

        setEditFormData({
            event_name: attachment.event_name || '',
            file: attachment.file
        });

        setEditFile(null);
    };

    const handleExitsAttEditForm = () => {
        setEditattaFormVisible(false);
        setDisplayattSectionVisible(true);
    };


    const [displayattSectionVisible, setDisplayattSectionVisible] = useState(true);

    
    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
    
        // Prepare FormData
        const formDataToSend = new FormData();
        formDataToSend.append('event_name', editFormData.event_name);
        if (editFile) formDataToSend.append('file', editFile);
    
        try {
            if (selectedAttachment) {
                // Update existing attachment
                const response = await axios.put(`${BASE_URL}/admins/general_attachments/${selectedAttachment.id}/update/`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                if (response.status === 200) {
                    const updatedAttachment = response.data;
    
                  
                    setAttachments(prevAttachments => 
                        prevAttachments.map(att => att.id === updatedAttachment.id ? { ...att, ...updatedAttachment } : att)
                    );
    
                  
                    alert('Attachment updated successfully!');
    
                    
                    setEditFormData({ event_name: '', file: null });
                    setEditFile(null);
                    setEditattaFormVisible(false);
                    setDisplayattSectionVisible(true);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again.');
        }
    };
    
    const [currentPage, setCurrentPage] = useState(1);
    const eventsPerPage = 15;
    // Calculate the index of the first and last event to display
    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);
    const startingIndex = (currentPage - 1) * eventsPerPage + 1;

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredEvents.length / eventsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Events</p>
                <div className='flex pt-12 gap-[2rem] p-8'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Events</p>

                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag2} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Events</p>

                            </div>




                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${attachmentActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={toggleAttachmentAddress}>
                            <div className='flex gap-3'>
                                {attachmentActive && <img src={imag3} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${attachmentActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Attachment</p>

                            </div>




                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${attachmentEditActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={toggleEditAttachment}>
                            <div className='flex gap-3'>
                                {attachmentEditActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${attachmentEditActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Edit Attachment</p>

                            </div>




                        </button>
                    </div>
                </div>

                {personalInfoActive && (

                    <div className=' '>
                        <form onSubmit={handleMultiSubmit}>

                            <div className='flex gap-8'>
                                <div className='w-[71%]'>
                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Number of Days</p>
                                    <div className="pt-2">

                                        <input
                                            type="text"
                                            name="days"
                                            value={formData.days}
                                            onChange={handleChange}

                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="2"
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className='  flex pt-8 gap-8'>

                               
                                <div className='w-full'>
                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Event Name</p>
                                    <div className="pt-2">
                                        <input
                                            type="text"
                                            name="event_name"
                                            value={formData.event_name}
                                            onChange={handleChange}
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Event Name"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p className='text-[color:var(--Black,#222)]   text-[18px] not-italic font-medium leading-[24px]'>Event Date</p>
                                    <div className="pt-2">
                                        <input
                                            type="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={handleChange}
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Date of Birth"
                                        />
                                    </div>
                                </div>
                                <div className='w-full'>
                                </div>
                            </div>
                            <div className='flex gap-8'>
                                <div className='w-[35%] pt-8'>
                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium  leading-[24px]'>Speakers</p>
                                    <div className="  relative">
                                        <div className="relative pt-2">
                                            <input
                                                type="text"
                                                name="speaker_search"
                                                value={selectedSpeaker}
                                                onChange={(e) => {
                                                    handleSpeakerSearch(e.target.value);
                                                    setSelectedSpeaker(e.target.value);
                                                    handleChange(e);
                                                }}
                                                onClick={() => {
                                                    if (!speakerList) {
                                                        setSpeakerList(true);
                                                    }
                                                }}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="Search for a speaker"
                                            />
                                            <div className="bg-white border border-gray-400 rounded-[6px] w-full mt-1 z-10" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                {filteredSpeakers.map(speaker => (
                                                    <div key={speaker.id} className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200">
                                                        <input
                                                            type="checkbox"
                                                            id={speaker.id}
                                                            value={speaker.id}
                                                            checked={selectedSpeakers.includes(speaker.id)}
                                                            onChange={(e) => handleCheckboxChange(e, speaker.id)}
                                                            className="mr-2"
                                                        />
                                                        <label htmlFor={speaker.id}>{speaker.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[34%] pt-16'>
                                    {selectedSpeakers.map(speakerId => (
                                        <div key={speakerId} className=" flex relative">
                                            <img src={speakers.find(speaker => speaker.id === speakerId)?.photo} alt="" className='absolute left-6 top-4 w-[8%] rounded-[30px]' />
                                            <div
                                                className="border border-gray-400 rounded-[6px]  pl-20  py-4 w-full bg-[#F4F4F4] flex items-center"
                                            >
                                                {speakers.find(speaker => speaker.id === speakerId)?.name}
                                                <img
                                                    src={del}
                                                    alt=""
                                                    className='ml-auto cursor-pointer pr-8 '
                                                    onClick={() => handleRemoveSpeaker(speakerId)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>



                            </div>
                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Image</p>
                            <div className='w-[35%]'>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    required
                                />
                            </div>


                            {[...Array(Number(formData.days))].map((_, dayIndex) => (
                                <div key={dayIndex}>
                                    <div className='pt-8'>
                                        <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Schedule - Day {dayIndex + 1}</p>
                                        <div className=' gap-8 pt-6'>

                                            <div className='pt-12'>
                                                <div className='flex gap-8'>



                                                    <div className="relative w-[61%]">
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>YouTube Link </p>
                                                        <div className="pt-2">
                                                            <input
                                                                type="text"
                                                                name="youtube_link"
                                                                value={scheduleFormData[dayIndex]?.[0]?.youtube_link || ''}
                                                                onChange={(e) => handleScheduleChange(e, dayIndex, 0)}
                                                                className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]"
                                                                placeholder="Youtube Url"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="relative w-[8%]">
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Points </p>
                                                        <div className="pt-2">
                                                            <input
                                                                type="text"
                                                                name='points'
                                                                value={scheduleFormData[dayIndex]?.[0]?.points || ''}
                                                                onChange={(e) => handleScheduleChange(e, dayIndex, 0)}
                                                                className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]"
                                                                placeholder="Points"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-6">
                                                    <div className="relative w-[71%]">
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Highlights </p>

                                                        <div className='  content-center'>
                                                            <div className='w-[100%]'>
                                                                {scheduleHighlights[dayIndex]?.map((highlight, index) => (
                                                                    <div key={index} className=''>
                                                                        <div className=" flex pt-2">
                                                                            <input
                                                                                type="text"
                                                                                name="highlight"
                                                                                value={highlight || ""}
                                                                                onChange={(event) => handleHighlightChange(dayIndex, index, event)}
                                                                                className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]"
                                                                                placeholder="Highlights"
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => removeHighlight(dayIndex, index)}
                                                                                className="ml-2 text-black"
                                                                            >

                                                                                <img src={cancel} alt="" className='w-[30px] pt-2' />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>


                                                            <div className='p-4 bg-[#1AB78D] text-white w-[185px] mt-4 rounded-[10px] text-center'>
                                                                <button type="button" className='flex items-center gap-2' onClick={() => addHighlight(dayIndex)}>
                                                                    <p>Add Highlights</p> <img src={plus} alt="" className='h-btn' />
                                                                </button>
                                                            </div>

                                                        </div>




                                                    </div>




                                                </div>
                                                <div className='pt-10'>
                                                    {scheduleFormData[dayIndex] && scheduleFormData[dayIndex].map((scheduleItem, scheduleIndex) => (
                                                        <div key={scheduleIndex} className="border border-gray-400 rounded-[6px] w-[71%] px-[20px] py-4 mb-4 bg-[#F4F4F4]">
                                                            <div className='flex gap-2'>
                                                                <div className="mb-2 w-[50%]">
                                                                    <label htmlFor={`starting_time_${dayIndex}_${scheduleIndex}`} className="block mb-1">Starting Time</label>
                                                                    <input
                                                                        type="time"
                                                                        id={`starting_time_${dayIndex}_${scheduleIndex}`}
                                                                        name="starting_time"
                                                                        value={scheduleFormData[dayIndex]?.[scheduleIndex]?.starting_time || ''}
                                                                        onChange={(e) => handleScheduleChange(e, dayIndex, scheduleIndex)}
                                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#FFFFFF]"
                                                                    />
                                                                </div>

                                                                <div className="mb-2 w-[50%]">
                                                                    <label htmlFor={`ending_time_${dayIndex}_${scheduleIndex}`} className="block mb-1">Ending Time</label>
                                                                    <input
                                                                        type="time"
                                                                        id={`ending_time_${dayIndex}_${scheduleIndex}`}
                                                                        name="ending_time"
                                                                        value={scheduleFormData[dayIndex]?.[scheduleIndex]?.ending_time || ''}
                                                                        onChange={(e) => handleScheduleChange(e, dayIndex, scheduleIndex)}
                                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#FFFFFF]"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mb-2">
                                                                <label htmlFor={`topics_${dayIndex}_${scheduleIndex}`} className="block mb-1">Topics</label>
                                                                <input
                                                                    type="text"
                                                                    name="topics"
                                                                    value={scheduleFormData[dayIndex]?.[scheduleIndex]?.topics || ''}
                                                                    onChange={(e) => handleScheduleChange(e, dayIndex, scheduleIndex)}
                                                                    className="border border-gray-400 rounded-[6px]  px-[20px] py-4 w-full bg-[#FFFFFF]"
                                                                />
                                                            </div>

                                                            <div className="mb-2">
                                                                <label htmlFor={`single_speaker_${dayIndex}_${scheduleIndex}`} className="block mb-1">Speaker</label>
                                                                <select
                                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                    value={scheduleFormData[dayIndex]?.[scheduleIndex]?.single_speaker || ''}
                                                                    onChange={(e) => handleSpeakerChangeForSection(e, dayIndex, scheduleIndex)}
                                                                >
                                                                    <option value="" disabled>Select a speaker</option>
                                                                    {speakers.length > 0 && selectedSpeakers.map(speakerId => {
                                                                        const speaker = speakers.find(s => s.id === speakerId);
                                                                        return (
                                                                            <option key={speaker.id} value={speaker.id}>
                                                                                {speaker.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>

                                                            <button
                                                                type="button"
                                                                onClick={() => handleRemovedButtonClick(dayIndex, scheduleIndex)}
                                                                className="text-black"
                                                            >
                                                                <img src={cancel} alt="Cancel" className='w-[30px] pt-2' />
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>



                                                <div className='p-4 bg-[#1AB78D] text-white w-[240px] mt-4 rounded-[10px] text-center'>
                                                    <button type="button" className='flex items-center gap-2' onClick={() => handleAddButtonClick(dayIndex)}>
                                                        <p>Add Another Time Slot</p> <img src={plus} alt="" className='h-btn' />
                                                    </button>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}


                            <div className='pt-8 w-[235px]'>
                                <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                                </button>

                            </div>

                        </form>
                    </div>
                )
                }

                {mailingAddressActive && (
                    <>
                        <div className="event-details">
                            {/* <input
                    type="text"
                    placeholder="Search by event name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                /> */}

                            {displaySectionVisible && !editFormVisible && (
                                <>
                                    <div className='relative'>
                                        <input
                                            type="text"
                                            placeholder="Search by event name"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[45%] relative"
                                        />

                                        <img src={search} alt="Search" className="absolute left-[36rem] top-6 transform -translate-y-1/2" />
                                    </div>
                                    <table className="event-table ">
                                        <thead>
                                            <tr>
                                                <th className='w-[8%]'>No</th>
                                                <th className='w-[55%]'>Event Name</th>
                                        
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {currentEvents.map((event, index) => (
                                                <tr key={event.id}>
                                                    <td>{startingIndex + index}</td>  
                                                    <td>{event.event_name}</td>
                                                 
                                                    <td>{event.date}</td>
                                                    <td className='flex gap-6'>
                                                        <div>
                                                            <img src={ed} alt="" className='w-[23px] cursor-pointer hover:opacity-80 transition duration-200 ease-in-out' onClick={() => handleEditButtonClick(event.id)} />
                                                        </div>
                                                        <div>
                                                            <img src={del} alt="" className='w-[23px] cursor-pointer hover:opacity-80 transition duration-200 ease-in-out' onClick={() => handleDeleteEvent(event.id)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    <div className="pagination flex justify-center items-center space-x-4 mt-6">
                                        <button
                                            onClick={prevPage}
                                            disabled={currentPage === 1}
                                            className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                        >
                                            <span className="pagination-icon">&#8249;</span> {/* Left arrow */}
                                        </button>
                                        <span className="pagination-info">
                                            Page {currentPage} of {totalPages}
                                        </span>
                                        <button
                                            onClick={nextPage}
                                            disabled={currentPage === totalPages}
                                            className={`pagination-arrow ${currentPage === totalPages ? 'disabled' : ''}`}
                                        >
                                            <span className="pagination-icon">&#8250;</span> {/* Right arrow */}
                                        </button>
                                    </div>
                                </>
                            )}

                            {editFormVisible && editEventId !== null && (
                                <div className="edit-form">
                                    {console.log('Edit Form is Visible', { editFormVisible, editEventId })}
                                    <form onSubmit={handleSubmit}>

                                        <div className='flex gap-8'>
                                            <div className='w-[71%]'>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Number of Days</p>
                                                <div className="pt-2">

                                                    <input
                                                        type="text"
                                                        name="days"
                                                        value={formData.days}

                                                        onChange={(e) => setFormData({ ...formData, days: e.target.value })}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        placeholder="2"
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                        <div className='  flex pt-8 gap-8'>

                        
                                            <div className='w-full'>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Event Name</p>
                                                <div className="pt-2">
                                                    <input
                                                        type="text"
                                                        name="event_name"
                                                        value={formData.event_name}
                                                        onChange={(e) => setFormData({ ...formData, event_name: e.target.value })}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        placeholder="Event Name"
                                                    />

                                                </div>
                                            </div>
                                            <div>
                                                <p className='text-[color:var(--Black,#222)]   text-[18px] not-italic font-medium leading-[24px]'>Event Date</p>
                                                <div className="pt-2">
                                                    <input
                                                        type="date"
                                                        name="date"
                                                        value={formData.date}
                                                        onChange={handleChange}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        placeholder="Date of Birth"
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full'>
                                            </div>
                                        </div>
                                        <div className='flex gap-8'>
                                            <div className='w-[35%] pt-8'>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Speakers</p>
                                                <div className="relative pt-2">
                                                    <input
                                                        type="text"
                                                        name="speaker_search"
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        placeholder="Search for a speaker"
                                                    />

                                                    <div className="bg-white border border-gray-400 rounded-[6px] w-full mt-1 z-10" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                        {filteredSpeakers && filteredSpeakers.map(speaker => (
                                                            <div key={speaker.id} className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200">
                                                                <input
                                                                    type="checkbox"
                                                                    id={speaker.id}
                                                                    value={speaker.id}
                                                                    checked={selectedSpeakers?.includes(speaker.id)}
                                                                    onChange={(e) => handleCheckboxChange1(e, speaker.id)}
                                                                    className="mr-2"
                                                                />
                                                                <label htmlFor={speaker.id}>{speaker.name}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[34%] pt-16'>
                                                {selectedSpeakers && selectedSpeakers.map(speakerId => {
                                                    const speaker = speakers.find(s => s.id === speakerId);
                                                    if (!speaker) return null;

                                                    return (
                                                        <div key={speaker.id} className="pt-2 flex relative">
                                                            <img src={speaker.photo} alt="" className='absolute left-6 top-4 w-[8%] rounded-[30px]' />
                                                            <div className="border border-gray-400 rounded-[6px] pl-20 py-4 w-full bg-[#F4F4F4] flex items-center">
                                                                {speaker.name}
                                                                <img
                                                                    src={del}
                                                                    alt=""
                                                                    className='ml-auto cursor-pointer pr-8'
                                                                    onClick={() =>  handleRemoveSpeaker(speakerId)}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <img
                                            src={selectedImage1 || `${BASE_URL}${imageUrl}`}
                                            alt="Event Banner"
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-[20%] bg-[#F4F4F4]"
                                        />


                                        <div className="pt-1">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageFileChange}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-[35%] bg-[#F4F4F4]"
                                            />
                                        </div>


                                        {[...Array(Number(formData.days))].map((_, dayIndex) => (
                                            <div key={dayIndex}>
                                                <div className='pt-8'>
                                                    <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>
                                                        Schedule - Day {dayIndex + 1}
                                                    </p>
                                                    <div className='gap-8 pt-6'>
                                                        <div className='pt-12'>
                                                            <div className='flex gap-8'>
                                                                <div className="relative w-[60%]">
                                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>
                                                                        YouTube Link
                                                                    </p>
                                                                    <div className="pt-2">
                                                                        <input
                                                                            type="text"
                                                                            name="youtube_link"
                                                                            value={formData.single_events[dayIndex]?.youtube_link || ''}
                                                                            onChange={(e) => handleInputChange(e, 'single_events', dayIndex, 'youtube_link')}
                                                                            className='border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="relative w-[8%]">
                                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>
                                                                        Points
                                                                    </p>
                                                                    <div className="pt-2">
                                                                        <input
                                                                            type="text"
                                                                            name="points"
                                                                            value={formData.single_events[dayIndex]?.points || ''}
                                                                            onChange={(e) => handleInputChange(e, 'single_events', dayIndex, 'points')}
                                                                            className='border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="pt-6">
                                                                <div className="relative w-[71%]">
                                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>
                                                                        Highlights
                                                                    </p>
                                                                    <div className='content-center'>
                                                                        <div className='w-[100%] pt-2'>
                                                                            {formData.single_events[dayIndex]?.highlights.map((highlight, index) => (
                                                                                <div key={index}>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={highlight}
                                                                                        onChange={(event) => handleHighlightChange1(dayIndex, index, event)}
                                                                                        placeholder={`Highlight ${index + 1}`}
                                                                                        className='border border-gray-400 rounded-[6px] px-[26px] py-4 w-[68%] bg-[#F4F4F4]'
                                                                                    />
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => removeHighlight1(dayIndex, index)}
                                                                                        className="ml-2 text-black"
                                                                                    >
                                                                                        <img src={cancel} alt="Remove Highlight" className='w-[30px] pt-2' />
                                                                                    </button>

                                                                                </div>
                                                                            ))}

                                                                            <div className='p-4 bg-[#1AB78D] text-white w-[185px] mt-4 rounded-[10px] text-center'>
                                                                                <button type="button" className='flex items-center gap-2' onClick={() => addHighlight1(dayIndex)}>
                                                                                    <p>Add Highlights</p> <img src={plus} alt="" className='h-btn' />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='pt-10 w-[71%]'>
                                                                {formData.single_events[dayIndex]?.multi_events.map((multiEvent, index) => (
                                                                    <div key={index} className="border border-gray-400 rounded-[6px] px-[20px] py-4 mb-4 bg-[#F4F4F4]">
                                                                        <div className='flex gap-4'>
                                                                            <div className="mb-2 w-[50%]">
                                                                                <label htmlFor={`starting_time_${index}`} className="block mb-1">Starting Time</label>
                                                                                <input
                                                                                    type="time"
                                                                                    id={`starting_time_${index}`}
                                                                                    name="starting_time"
                                                                                    value={multiEvent.starting_time || ''}
                                                                                    onChange={(e) => handleMultiEventChange(e, index, dayIndex, 'starting_time')}
                                                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#FFFFFF]"
                                                                                />
                                                                            </div>
                                                                            <div className="mb-2 w-[50%]">
                                                                                <label htmlFor={`ending_time_${index}`} className="block mb-1">Ending Time</label>
                                                                                <input
                                                                                    type="time"
                                                                                    id={`ending_time_${index}`}
                                                                                    name="ending_time"
                                                                                    value={multiEvent.ending_time || ''}
                                                                                    onChange={(e) => handleMultiEventChange(e, index, dayIndex, 'ending_time')}
                                                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#FFFFFF]"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-2">
                                                                            <label htmlFor={`topics_${index}`} className="block mb-1">Topics</label>
                                                                            <input
                                                                                type="text"
                                                                                id={`topics_${index}`}
                                                                                name="topics"
                                                                                value={multiEvent.topics || ''}
                                                                                onChange={(e) => handleMultiEventChange(e, index, dayIndex, 'topics')}
                                                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#FFFFFF]"
                                                                            />
                                                                        </div>
                                                                        <div className="mb-2">
                                                                            <label htmlFor={`single_speaker_${index}`} className="block mb-1">Speaker</label>
                                                                            <select
                                                                                id={`single_speaker_${index}`}
                                                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                                value={multiEvent.single_speaker || ''}
                                                                                onChange={(e) => handleMultiEventChange(e, index, dayIndex, 'single_speaker')}
                                                                            >
                                                                                <option value="" disabled>Select a speaker</option>
                                                                                {speakers.length > 0 && speakers.map(speaker => (
                                                                                    <option key={speaker.id} value={speaker.id}>
                                                                                        {speaker.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleRemovedButtonClick1(index, dayIndex)}
                                                                            className="text-black"
                                                                        >
                                                                            <img src={cancel} alt="Cancel" className='w-[30px] pt-2' />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                                <div className='p-4 bg-[#1AB78D] text-white w-[240px] mt-4 rounded-[10px] text-center'>
                                                                    <button type="button" className='flex items-center gap-2' onClick={() => handleAddButtonClick1(dayIndex)}>
                                                                        <p>Add Another Time Slot</p> <img src={plus} alt="" className='h-btn' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}




                                        {/* <div className='pt-8 w-[15%] '>
                                            <button type='submit' className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                                <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                                            </button>
                                        </div> */}
                                        <div className='flex'>
                                            <div className='pt-8 w-[500px]'>
                                                <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                                </button>
                                            </div>
                                            <div className='pt-8 w-[25%] ml-6'>
                                                <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitEditForm}>Cancel</p>
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {attachmentActive && (
                    <>

                        <h3 className="text-primary text-18 font-semibold">Upload Attachment</h3>
                        <div className='w-[50%]'>
                            <form onSubmit={handleAttachmentSubmit}>
                                <div className="mb-4 pt-8">
                                    <label
                                        htmlFor="event-search"
                                        className="text-secondary text-[16px] font-medium block mb-2"
                                    >
                                        Event
                                    </label>
                                    <input
                                        id="event-search"
                                        value={searchInput}
                                        onChange={handleSearchChange1}
                                        className="form-input border border-gray rounded-[8px] px-[20px] py-4"
                                        placeholder="Search for an event"
                                    />
                                    {searchfilteredEvents.length > 0 && (
                                        <ul className="border border-gray rounded-[8px] mt-2 max-h-40 overflow-y-auto">
                                            {searchfilteredEvents.map(event => (
                                                <li
                                                    key={event.id}
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                                                    onClick={() => handleEventSelect(event)}
                                                >
                                                    {event.event_name} - Day {event.day}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="file"
                                        className="text-secondary  text-[16px] font-medium block mb-2 w-[200px]"
                                    >
                                        File
                                    </label>
                                    <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        onChange={handleFileChange}
                                        className="form-input border border-gray py-3 rounded-[8px]"
                                    />
                                </div>
                                <div className="mb-4">
                                <div className="mb-4">
                                    <button type="submit" className= "submit-btn-certificate text-white rounded-[8px] p-3 font-semibold">
                                       <p>Upload</p>
                                    </button>
                                </div>
                                </div>
                                
                            </form>
                        </div>
                    </>
                )}


                {attachmentEditActive && (
                    <>
                      {displayattSectionVisible && !editattaFormVisible && (
                            <div>
                                <h3 className="text-primary text-18 font-semibold">Attachments List</h3>
                                <div className='pt-6'>
                                    <div className='relative'>
                                        <input
                                            type="text"
                                            placeholder="Search by event name"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[45%] relative"
                                        />
                                        <img src={search} alt="Search" className="absolute left-[36rem] top-6 transform -translate-y-1/2" />
                                    </div>
                                </div>
                                <table className="w-full mt-8 border border-gray-300 overflow-hidden">
                                    <thead>
                                        <tr className="bg-gray-200 text-gray-700">
                                            {/* Reduce width of "No" column */}
                                            <th className="border border-gray-300 px-4 py-2 text-left w-[8%]">No</th>

                                            {/* Increase width of "Event" column */}
                                            <th className="border border-gray-300 px-4 py-2 text-left w-[50%]">Event</th>

                                            <th className="border border-gray-300 px-4 py-2 text-left w-[25%]">File</th>
                                            <th className="border border-gray-300 px-4 py-2 text-left w-[25%]">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attachments
                                            .filter(attachment =>
                                                attachment.event_name && attachment.event_name.toLowerCase().includes(searchQuery.toLowerCase())
                                            )
                                            .map((attachment, index) => (
                                                <tr key={attachment.id} className="hover:bg-gray-50">

                                                    <td className="border border-gray-300 px-4 py-2 w-[10%] text-left">{index + 1}</td>

                                                    {/* "Event" column with increased width */}
                                                    <td className="border border-gray-300 text-left px-4 py-2 w-[40%]">
                                                        {attachment.event_name
                                                            ? `${attachment.event_name} - Day ${attachment.event_day || 'N/A'}`
                                                            : 'No event'}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2 w-[25%] text-left">
                                                        {attachment.file ? (
                                                            <a href={attachment.file} target="_blank" rel="noopener noreferrer" className='text-blue-500 underline hover:text-blue-700 transition duration-200 ease-in-out'>View File</a>
                                                        ) : 'No file'}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2 w-[25%]">
                                                        <div className='flex gap-4 text-left  '>
                                                            <img src={ed} alt="Edit" className='w-6 cursor-pointer hover:opacity-80 transition duration-200 ease-in-out' onClick={() => handleEditClick(attachment)} />
                                                            <img src={del} alt="Delete" className='w-6 cursor-pointer hover:opacity-80 transition duration-200 ease-in-out' onClick={() => handleDeleteAttachment(attachment.id)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        {attachments.length === 0 && (
                                            <tr>
                                                <td colSpan={4} className="border border-gray-300 px-4 py-2 text-center text-gray-500">No attachments found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>


                            </div>
                        )}


                        {editattaFormVisible && selectedAttachment && (
                            <div className="edit-form-container w-[50%]">

                                <form onSubmit={handleUpdateSubmit}>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="event-name"
                                            className="text-secondary text-[16px] font-medium block mb-2"
                                        >
                                            Event Name
                                        </label>
                                        <input
                                            id="event-name"
                                            value={ editFormData.event_name   }

                                            onChange={(e) => setEditFormData({ ...editFormData, event_name: e.target.value })}
                                            className="form-input border border-gray rounded-[8px] px-[20px] py-4"
                                            placeholder="Event Name"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="edit-file"
                                            className="text-secondary text-[16px] font-medium block mb-2"
                                        >
                                            File
                                        </label>
                                        <input
                                            type="file"
                                            id="edit-file"
                                            onChange={handleEditFileChange}
                                            className="form-input border border-gray py-3 rounded-[8px]"
                                        />

                                        {!editFile && editFormData.file && (
                                            <p className="mt-2 text-gray-600">
                                                {typeof editFormData.file === 'string' ? (
                                                    <>
                                                        <span>{editFormData.file.split('/').pop()}</span> {/* File name */}
                                                        {" "}
                                                        <a href={editFormData.file} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                                            View current file
                                                        </a>
                                                    </>
                                                ) : editFormData.file.name}
                                            </p>
                                        )}

                                        {/* Display selected file */}
                                        {editFile && (
                                            <p className="mt-2 text-gray-600">
                                                Selected file: {editFile.name}
                                            </p>
                                        )}
                                    </div>

                                    <div className='flex'>
                                        <div className='pt-8 w-[70%]'>
                                            <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                                <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                            </button>
                                        </div>


                                        <div className='pt-8 w-[30%] ml-6'>
                                            <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitsAttEditForm}>Cancel</p>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}


                    </>
                )}


            </div>
        </div >
    );
};

export default GeneralEvents;