import react from "react";
import Home from "../../../Components/Home/index";
import Member from "../../../Components/User/About/MemberProfile";  
import Footer from  "../../../Components/Footer/Footer";


const MemberPage = () => {

    return (
        <div>
    <Home/>
    <Member/>
  <Footer/> 
    </div>
    )
}
export default MemberPage;