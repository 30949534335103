import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import parse from 'html-react-parser';
import './Gallery.css';
import { motion, useAnimation, useInView } from 'framer-motion';
import { AiOutlineRight, AiOutlineClose } from 'react-icons/ai';

const Gallery = () => {
    const [galleries, setGalleries] = useState([]);
    const [lightboxImage, setLightboxImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0); // Track the current gallery
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/gallery/`);
                const reversedGalleries = response.data.reverse();
                setGalleries(reversedGalleries);
            } catch (error) {
                console.error('Error fetching galleries:', error);
            }
        };

        fetchGalleries();
    }, []);

    const openLightbox = (imageSrc, galleryIndex, imageIndex) => {
        setLightboxImage(imageSrc);
        setCurrentIndex(imageIndex);
        setCurrentGalleryIndex(galleryIndex);
    };

    const closeLightbox = () => {
        setLightboxImage(null);
        setCurrentIndex(0);
        setCurrentGalleryIndex(0);
    };

    const nextImage = () => {
        const currentGallery = galleries[currentGalleryIndex]?.images || [];
        const nextIndex = (currentIndex + 1) % currentGallery.length;
        setLightboxImage(currentGallery[nextIndex].image);
        setCurrentIndex(nextIndex);
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <motion.div
                        className="bg-white rounded-[30px] p-8"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                        }}
                        ref={ref}
                    >
                        {galleries.map((gallery, galleryIndex) => (
                            <div key={galleryIndex} className="mb-8">
                                <h2 className="text-2xl font-bold">{gallery.title}</h2>
                                <p className="blog-content pt-4">
                                    {typeof gallery.description === 'string' ? parse(gallery.description) : ' '}
                                </p>
                                <div className="grid grid-cols-3 gap-4 mt-4">
                                    {gallery.images.map((image, imgIndex) => (
                                        <div key={imgIndex} className="relative group">
                                            <img
                                                src={image.image}
                                                alt={`Image ${imgIndex}`}
                                                className="w-full h-auto rounded-lg cursor-pointer"
                                                onClick={() => openLightbox(image.image, galleryIndex, imgIndex)}
                                            />
                                          
                                            
                                        </div> 
                                    ))}
                                </div>
                            </div>
                        ))}
                    </motion.div>
                </div>

                {/* Lightbox Modal */}
                {lightboxImage && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={closeLightbox}
                    >
                        <motion.img
                            src={lightboxImage}
                            alt="Lightbox"
                            className="max-w-full max-h-full rounded-lg"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.3 }}
                        />

                        {/* Close Icon */}
                        <AiOutlineClose
                            className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer text-white text-3xl"
                            onClick={closeLightbox}
                        />

                        {/* Next Arrow Icon */}
                        <AiOutlineRight
                            className="absolute top-1/2 right-8 transform -translate-y-1/2 cursor-pointer text-white text-3xl"
                            onClick={(e) => {
                                e.stopPropagation();
                                nextImage();
                            }}
                        />
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default Gallery;
