import React, { useState, useEffect } from 'react';
import pdficon from "../../../../assets/images/pdf-icons.svg";
import { BASE_URL } from '../../../../Utils/Config';
import axios from 'axios';
import { toast } from "react-hot-toast";
import { getUserProfile } from '../../../../helpers/auth';
import "./Download.css";

const Downloads = () => {
    const [user, setUser] = useState(null);
    const [userAttachments, setUserAttachments] = useState([]);  
    const [generalAttachments, setGeneralAttachments] = useState([]);
    const [allAttachments, setAllAttachments] = useState([]); // New state to hold combined and sorted attachments
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');

    // Fetch user profile
    const fetchUserProfile = async () => {
        try {
            if (userId && authToken) {
                const profile = await getUserProfile(userId);
                setUser(profile.user);
                toast.success('User profile fetched successfully');
            } else {
                toast.error('User ID or authentication token is missing');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
            toast.error('Error fetching user profile');
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, [userId, authToken]);

    // Fetch user attachments
    const fetchUserAttachments = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/user-attachments/`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            setUserAttachments(response.data.attachments);
            console.log("Fetched user attachments:", response.data.attachments);  
        } catch (error) {
            console.error('Error fetching user attachments:', error);
        }
    };

    // Fetch general attachments
    const fetchGeneralAttachments = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/general-user-attachments/`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            setGeneralAttachments(response.data.attachments);
            console.log("Fetched general attachments:", response.data.attachments);  
        } catch (error) {
            console.error('Error fetching general attachments:', error);
        }
    };

    useEffect(() => {
        if (authToken) {
            fetchUserAttachments();
            fetchGeneralAttachments();
        }
    }, [authToken]);

    useEffect(() => {
        // Combine and sort attachments when either list changes
        const combineAndSortAttachments = () => {
            const combined = [...userAttachments, ...generalAttachments];
            // Sort by created_at timestamp in descending order
            const sorted = combined.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setAllAttachments(sorted);
        };

        combineAndSortAttachments();
    }, [userAttachments, generalAttachments]);

  
    const handleFileClick = (fileUrl) => {
        console.log('File URL:', fileUrl);   
        if (fileUrl) {
            window.open(fileUrl, '_blank');
        } else {
            toast.error('File URL is missing');
        }
    };

 
    const groupAttachments = (attachments) => {
        return attachments.reduce((groups, attachment) => {
            const { single_event, file, event_name, event_day } = attachment;
            if (!groups[single_event]) {
                groups[single_event] = { files: [], event_name, event_day };
            }
            groups[single_event].files.push(file);
            return groups;
        }, {});
    };

    const groupedAttachments = groupAttachments(allAttachments);

    return (
        <div className='bg-[#f4f4f4] w-[100%] h-[100%] p-6'>
            <div className='bg-white p-6'>
                <div className='download-head'>
                    <div className='download-title'>
                        <h3>Downloads</h3>
                    </div>
                </div>

                <div className='download-main'>
                    {Object.keys(groupedAttachments).length > 0 ? (
                        Object.keys(groupedAttachments).map(singleEventId => (
                            <div className='download-item' key={singleEventId}>
                                <div className='download-name'>
                                    <p className='p-3 w-[100%]'>
                                        {groupedAttachments[singleEventId].event_name} 
                                        - Day {groupedAttachments[singleEventId].event_day} 
                                    </p>
                                </div>
                                <div className='download-files pl-8 flex mob-d-block'>
                                    {groupedAttachments[singleEventId].files.map((fileUrl, index) => (
                                        <img
                                            src={pdficon}
                                            alt="File"
                                            onClick={() => handleFileClick(fileUrl)}
                                            style={{ cursor: 'pointer', marginRight: '10px' }}
                                            key={index}
                                            className=' pdf-icon-dwonload'
                                        />
                                    ))}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className='text-gray-400'>No Downloads Found </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Downloads;
