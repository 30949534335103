import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import imag from "../../../assets/images/add-blog.svg";
import imag1 from "../../../assets/images/manage-blog.svg";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import "./Ablog.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import search from "../../../assets/images/search.png";
const Blogs = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setMailingAddressActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);
    const [editSpeakerVisible, setEditSpeakerVisible] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
 
    const [formData, setFormData] = useState({
        forum: '',
        title: '',
        author: '',
        qualification: '',
        date: '',
        blog_banner: null,
        author_profile: null,
        blog_contents: [
            {
                topic: '',
                description: '',
                image: null
            }
        ]
    });
    const [blogs, setBlogs] = useState([]);




    const [selectedBlogBanner, setSelectedBlogBanner] = useState(null); // State for selected blog banner image





    const handleEdit = (blog) => {
        setSelectedBlog(blog);
        setEditFormVisible(true);
        setSelectedImage(null);
        const blogId = blog.id;

        const selectedForum = forums.find(forum => forum.title === blog.forum_title);

        const forumId = selectedForum ? selectedForum.id : null;
        setFormData({
            ...formData,
            forum: forumId,
            title: blog.title,
            author: blog.author,
            qualification: blog.qualification,
            date: blog.date,
            blog_contents: (blog.blog_contents || []).map(content => ({
                topic: content.topic,
                description: content.description,
                image: content.image
            }))
        });
    };

    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };


    const handleDelete = async (blogId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
        if (confirmDelete) {
            try {
                await axios.delete(`${BASE_URL}/admins/blogs/${blogId}/delete/`);
                setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== blogId));
                console.log('Blog deleted successfully');
                alert('Blog deleted successfully!');
            } catch (error) {
                console.error('Error deleting blog:', error);
                alert('Failed to delete blog. Please try again.');
            }
        }
    };


    const [forums, setForums] = useState([]);

    useEffect(() => {
        const fetchForums = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forums/`);
                setForums(response.data);
            } catch (error) {
                console.error('Error fetching forums:', error);
            }
        };

        fetchForums();
    }, []);



    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/blogslistall/`);
            console.log('Fetched blogs:', response.data);
            setBlogs(response.data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
            alert.error('Error fetching blogs');
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    // const handleImageChange = (e, index) => {
    //     const imageFile = e.target.files[0];
    //     const updatedBlogContents = [...formData.blog_contents];
    //     updatedBlogContents[index].image = imageFile || null;
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         blog_contents: updatedBlogContents
    //     }));
    // };

    const handleBlogBannerChange = (e) => {
        if (e && e.target && e.target.files) {
            const newBanner = e.target.files[0];
            setSelectedBlog({ ...selectedBlog, blog_banner: newBanner });
        }
    };


    const handleAddButtonClick = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            blog_contents: [
                ...prevFormData.blog_contents,
                {
                    topic: '',
                    description: '',
                    image: null
                }
            ]
        }));
    };


    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData };

        if (name.includes('topic')) {
            const plainText = stripHtmlTags(value);
            updatedFormData.blog_contents[index].topic = value;
        } else {

            updatedFormData[name] = value;
        }

        setFormData(updatedFormData);
    };

    // Update content in ReactQuill
    const handleQuillChange = (value, index) => {
        const updatedFormData = { ...formData };
        updatedFormData.blog_contents[index].description = value;
        setFormData(updatedFormData);
    };

    const handleImageChange1 = (e) => {
        const imageFile = e.target.files[0];
        setFormData(prevFormData => ({
            ...prevFormData,
            blog_banner: imageFile
        }));
    };



    const handleAuthorProfileChange = (e) => {
        const profileFile = e.target.files[0];
        if (profileFile) {
            console.log('Selected author_profile file:', profileFile);
            setFormData(prevFormData => ({
                ...prevFormData,
                author_profile: profileFile // Update the author_profile field in the form data
            }));
        }
    };

    useEffect(() => {
        return () => {
            // Cleanup object URLs created by URL.createObjectURL
            URL.revokeObjectURL(formData.blog_banner);
            URL.revokeObjectURL(formData.author_profile);
        };
    }, [formData.blog_banner, formData.author_profile]);

    // const handleAuthorProfileChange = (e) => {
    //     const imageFile = e.target.files[0];
    //     setSelectedImage(imageFile); // Update the selected image state

    //     // Update the author_profile field in the form data
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         author_profile: imageFile || null // Set it to null if no file is selected
    //     }));
    // };
    const handleAddContent = () => {
        setFormData({
            ...formData,
            blog_contents: [
                ...formData.blog_contents,
                { topic: '', description: '', image: null }
            ]
        });
    };

    // Function to handle removing a content entry by index
    const handleRemoveContent = (index) => {
        const updatedContents = [...formData.blog_contents];
        updatedContents.splice(index, 1);
        setFormData({
            ...formData,
            blog_contents: updatedContents
        });
    };


    const handleFieldChange = (index, fieldName, value) => {
        const updatedFormData = { ...formData };
        updatedFormData.blog_contents[index][fieldName] = value;
        setFormData(updatedFormData);
    };


    const handleImageChange = (e, index) => {
        const files = e.target.files;
        if (files.length > 0) {
            const newImage = files[0];
            setFormData(prevFormData => ({
                ...prevFormData,
                blog_contents: prevFormData.blog_contents.map((content, i) =>
                    i === index ? { ...content, image: newImage } : content
                )
            }));
        }
    };
    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("Form data:", formData);


            const formDataToSend = new FormData();


            formDataToSend.append('forum', formData.forum);
            formDataToSend.append('title', formData.title);
            formDataToSend.append('author', formData.author);
            formDataToSend.append('qualification', formData.qualification);
            formDataToSend.append('date', formData.date);
            formDataToSend.append('blog_banner', formData.blog_banner !== null ? formData.blog_banner : '');
            formDataToSend.append('author_profile', formData.author_profile !== null ? formData.author_profile : '');

            formData.blog_contents.forEach((content, index) => {
                formDataToSend.append(`blog_contents[${index}][topic]`, content.topic);
                formDataToSend.append(`blog_contents[${index}][description]`, content.description);
                if (content.image instanceof File) {
                    formDataToSend.append(`blog_contents[${index}][image]`, content.image);
                }
            });
            console.log("author_profile", formData.author_profile)

            const response = await axios.post(`${BASE_URL}/admins/blogs/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Response:', response);
            alert('Blog added successfully!');
            window.location.reload();

            setFormData({
                forum: '',
                title: '',
                author: '',
                qualification: '',
                date: '',
                author_profile: null,
                blog_banner: null,
                blog_contents: [
                    {
                        topic: '',
                        description: '',
                        image: null
                    }
                ]
            });
        } catch (error) {
            console.error('Error adding blog:', error);
            alert('Failed to add blog. Please try again.');
        }
    };
    const isFile = (file) => file instanceof File;


    const handleFormSubmit = async (e, blogId) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('forum', formData.forum);
        formDataToSend.append('title', formData.title);
        formDataToSend.append('author', formData.author);
        formDataToSend.append('qualification', formData.qualification);
        formDataToSend.append('date', formData.date);

        if (formData.blog_banner && formData.blog_banner instanceof File) {
            formDataToSend.append('blog_banner', formData.blog_banner);
        }

        if (formData.author_profile && formData.author_profile instanceof File) {
            formDataToSend.append('author_profile', formData.author_profile);
        }

        formData.blog_contents.forEach((content, index) => {
            formDataToSend.append(`blog_contents[${index}][topic]`, content.topic);
            formDataToSend.append(`blog_contents[${index}][description]`, content.description);

            if (content.image) {
                if (content.image instanceof File) {
                    formDataToSend.append(`blog_contents[${index}][image]`, content.image);
                } else {
                    formDataToSend.append(`blog_contents[${index}][image]`, content.image); // Pass the existing image URL
                }
            }
        });

        try {
            const response = await axios.put(`${BASE_URL}/admins/blogs/${blogId}/update/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const updatedBlog = response.data;
            setBlogs(prevBlogs => prevBlogs.map(blog => blog.id === updatedBlog.id ? { ...blog, ...updatedBlog } : blog));
            alert('Blog updated successfully!');

            setFormData({
                forum: '',
                title: '',
                author: '',
                qualification: '',
                date: '',
                author_profile: null,
                blog_banner: null,
                blog_contents: [{ topic: '', description: '', image: null }]
            });

            setEditFormVisible(false);
            setSelectedBlog(null);
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert('Failed to update blog. Please try again.');
        }
    };






    useEffect(() => {
        if (selectedBlog) {
            setFormData({
                ...formData,
                blog_banner: null,
                author_profile: null,

            });
        }
    }, [selectedBlog]);




    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);
        if (!personalInfoActive) {
            setMailingAddressActive(false);
        }
    };

    const toggleMailingAddress = () => {
        setMailingAddressActive(!mailingAddressActive);
        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const renderDescription = (text) => {
        const linkPattern = /\[([^\]]+)\]\((http[s]?:\/\/[^\)]+)\)/g;

        return text.split(linkPattern).map((part, i) => {
            if (i % 3 === 1) {
                return <a key={i} href={text.split(linkPattern)[i + 1]} target="_blank" rel="noopener noreferrer">{part}</a>;
            }
            return <span key={i}>{part}</span>;
        });
    };
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
    };
    const extractTextAndUrls = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;

        let text = '';
        let urls = [];

        // Function to recursively process nodes
        const processNode = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                text += node.textContent + ' ';
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.tagName === 'A') {
                    urls.push(node.href);
                }
                node.childNodes.forEach(processNode);
            }
        };

        div.childNodes.forEach(processNode);

        // Remove extra spaces
        text = text.trim();

        return {
            text,
            urls
        };
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Blog Management</p>
                <div className='flex pt-6 gap-[12rem] p-6'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Blog</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={toggleMailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Blog</p>
                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className="   w-[50%] ">
                        <form onSubmit={handleSubmit} className="bg-white     ">
                            <label htmlFor="forum" className="text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]">Choose Forum</label>
                            <div className=" pt-1">

                                <select
                                    id="forum"
                                    className="appearance-none border rounded w-full py-4 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={formData.forum}
                                    onChange={(e) => setFormData({ ...formData, forum: e.target.value })}
                                >
                                    <option value="" disabled>Select a forum</option>
                                    {forums.map(forum => (
                                        <option key={forum.id} value={forum.id}>{forum.title}</option>
                                    ))}
                                </select>
                            </div>
                            < p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Blog Title</p>
                            <div className="pt-2">

                                <input
                                    type="text"
                                    id="title"
                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter blog title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </div>
                            < p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Blog Banner</p>
                            <div className="pt-2">

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange1}
                                    className="appearance-none border rounded w-full px-3 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <p className='text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]'>Blog Section</p>
                            <div className='pt-2'>
                                {formData.blog_contents.map((content, index) => (
                                    <div key={index} className=" border p-6 rounded-[8px] mb-6  ">

                                        <div>
                                            <label className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Topic</label>
                                            <div className='pt-2'>
                                                <input
                                                    type="text"
                                                    name={`blog_contents[${index}][topic]`}
                                                    className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    placeholder="Enter topic"
                                                    value={content.topic}
                                                    onChange={(e) => handleChange(e, index)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Description</p>
                                            <div className="mb-2">
                                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                                    Description
                                                </label>
                                                <ReactQuill
                                                    value={content.description}
                                                    onChange={(value) => handleQuillChange(value, index)}
                                                    placeholder="Enter description with links and formatting"
                                                    className="w-full"
                                                />
                                            </div>


                                        </div>

                                        <div>
                                            <p className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Upload Images</p>
                                            <div className='pt-2'>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleImageChange(e, index)}
                                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>
                                        </div>


                                        <div className='p-4 bg-[#1AB78D] text-white w-[160px] mt-4 rounded-[10px] text-center'>
                                            <button type="button" className='flex items-center gap-2' onClick={() => handleRemoveContent(index)}>
                                                <p className='pl-2'>Remove Section</p>
                                            </button>
                                        </div>


                                    </div>
                                ))}
                            </div>
                            {/* <div className="mb-6">
                <button type="button" onClick={handleAddButtonClick} className="bg-gray-400 text-white rounded px-4 py-2 hover:bg-gray-500 focus:outline-none focus:shadow-outline">
                    Add Blog Content
                </button>
            </div> */}


                            <div className='p-4 bg-[#1AB78D] text-white w-[140px] mt-4 mb-6 rounded-[10px] text-center'>
                                <button type="button" className='flex items-center gap-2' onClick={handleAddButtonClick}>
                                    <p className='pl-2'>Add Section</p>
                                </button>
                            </div>

                            <div className=' '>
                                <div className="">

                                    <p htmlFor="author" className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Author</p>
                                    <div className='pt-2'>
                                        <input
                                            type="text"
                                            id="author"
                                            className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter author name"
                                            value={formData.author}
                                            onChange={(e) => setFormData({ ...formData, author: e.target.value })}
                                        />
                                    </div>
                                </div>


                                <p htmlFor="author" className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Author Profile</p>
                                <div className=" pt-2">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleAuthorProfileChange}
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                            </div>

                            <div className=' '>

                                <p htmlFor="author" className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Qualification</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        id="qualification"
                                        className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        placeholder="Enter qualification"
                                        value={formData.qualification}
                                        onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
                                    />
                                </div>


                                <p htmlFor="author" className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Blog Date</p>
                                <div className="pt-2 ">
                                    <input
                                        type="date"
                                        id="date"
                                        className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        value={formData.date}
                                        onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                    />
                                </div>
                            </div>




                            <div className="flex items-center justify-start">


                                <button type="submit" className='submit-btn-blog rounded-[10px] w-full  py-4'>
                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                                </button>

                            </div>
                        </form>
                    </div>
                )}



                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editFormVisible && (
                            <div>
                                  <div className='relative'>
                                    <input
                                        type="text"
                                        placeholder="Search speakers..."
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[50%] relative"
                                    />

                                    <img src={search} alt="Search" className="absolute left-[40rem] top-6 transform -translate-y-1/2" />
                                </div>
                                <div className='bg-gray-100 py-4 w-full flex p-6 gap-[21.5rem]'>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-2 text-[18px] not-italic font-semibold leading-[normal]'>Blog Name</p>
                                    <p className='text-[color:var(--Gray,#58585A)]  text-[18px] not-italic font-semibold leading-[normal]'>Forum Name</p>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold leading-[normal]'>Actions</p>
                                </div>
                                {/* {blogs.map((blog, index) => ( */}
                                     {blogs.filter(blog => blog.title.toLowerCase().includes(searchQuery.toLowerCase())).map((blog, index) => (
                                    <div key={index} className='w-full '>
                                        <div className='flex pt-4 pb-4 pl-8 '>
                                            <div className='flex gap-4'>
                                                <p className='text-[color:var(--Gray,#58585A)] w-[405px] text-[16px] not-italic font-normal leading-[normal]'>{blog.title}</p>
                                                <p className='text-[color:var(--Gray,#58585A)] w-[468px] text-[16px] not-italic font-normal leading-[normal]'>{blog.forum_title}</p>
                                            </div>
                                            <div className='flex gap-6'>
                                                <div>
                                                    <img src={ed} alt="" className='' onClick={() => handleEdit(blog)} />
                                                </div>
                                                <div>
                                                    <img src={del} alt="" className='' onClick={() => handleDelete(blog.id)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='' />
                                    </div>
                                ))}
                            </div>
                        )}

                        {editFormVisible && selectedBlog && (
                            <div className='w-[50%]'>
                                <form onSubmit={(e) => handleFormSubmit(e, selectedBlog.id)}>
                                    <div className="text-start space-y-4">
                                        <div className="w-full">
                                            <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Choose Forum</label>
                                            <select
                                                className="block w-full border border-gray-300 rounded-md px-3 py-4 mt-1 bg-white focus:outline-none focus:border-blue-500"
                                                value={formData.forum}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, forum: parseInt(e.target.value) });
                                                }}
                                            >
                                                <option value="" disabled>Select a forum</option>
                                                {forums && forums.map(forum => (
                                                    <option key={forum.id} value={forum.id}>
                                                        {forum.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="w-full">
                                            <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Blog Title</label>
                                            <input
                                                type="text"
                                                className="block w-full border border-gray-300 rounded-md px-3 py-4 mt-1 bg-white focus:outline-none focus:border-blue-500"
                                                placeholder="Enter blog title"
                                                value={formData.title}
                                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                            />
                                        </div>

                                        {selectedBlog && (
                                            <div>
                                                <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Blog Banner</label>
                                                <input
                                                    type="file"
                                                    name="blog_banner"
                                                    onChange={handleImageChange1}
                                                    accept="image/*"
                                                />
                                                {formData.blog_banner ? (
                                                    <img src={URL.createObjectURL(formData.blog_banner)} alt="Blog Banner Preview" className="mt-2 w-[40%] h-auto rounded-md" />
                                                ) : (
                                                    <img src={selectedBlog.blog_banner} alt="Blog Banner Preview" className="mt-2 w-[40%] h-auto rounded-md" />
                                                )}
                                            </div>
                                        )}
                                        <div className='block text-[#222] text-[18px] font-medium leading-[24px]    border-gray-200'>
                                            <p className=''>Blog Section</p>
                                            <div className='pt-4'>
                                                {formData.blog_contents.map((content, index) => (
                                                    <div key={index} className="space-y-4  border p-3  rounded-[8px] border-gray-200">
                                                        <div className="  items-start  ">
                                                            <div className=" ">
                                                                <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Topic</label>
                                                                <input
                                                                    type="text"
                                                                    className="block w-full border border-gray-300 rounded-md px-3 py-4 mt-1 bg-white focus:outline-none focus:border-blue-500"
                                                                    placeholder="Enter topic"
                                                                    value={content.topic}
                                                                    onChange={(e) => handleFieldChange(index, 'topic', e.target.value)}
                                                                />
                                                            </div>
                                                            {/* <div className=" pt-4">
                                                                <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Description</label>
                                                                <input
                                                                    type="text"
                                                                    className="block w-full border border-gray-300 rounded-md px-3 py-4 mt-1 bg-white focus:outline-none focus:border-blue-500"
                                                                    placeholder="Enter description"
                                                                    value={content.description}
                                                                    onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                                                />
                                                            </div> */}
                                                        </div>
                                                        <div className="mb-2">
                                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                                Description
                                                            </label>
                                                            <ReactQuill
                                                                value={content.description}
                                                                onChange={(value) => handleQuillChange(value, index)}
                                                                placeholder="Enter description with links and formatting"
                                                                className="w-full"
                                                            />
                                                        </div>


                                                        <div className="w-full">
                                                            <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Upload Photos</label>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => handleImageChange(e, index)}
                                                                className="block w-full border border-gray-300 rounded-md px-3 py-2 mt-1 bg-white"
                                                            />
                                                            {content.image && (
                                                                <img
                                                                    src={content.image instanceof File ? URL.createObjectURL(content.image) : content.image}
                                                                    alt="Selected Image Preview"
                                                                    className="mt-2 w-[30%] h-auto rounded-md"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className='w-[30%]'>
                                                            <button className='submit-btn-cgeneratepaswd text-white rounded-[8px] p-3' type="button" onClick={() => handleRemoveContent(index)}>Remove this content</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="w-[30%] ">
                                            {/* Add button */}
                                            <button
                                                type="button"
                                                onClick={() => handleAddContent()}
                                                className="submit-btn-cgeneratepaswd p-3 text-white rounded-[8px]  "
                                            >
                                                Add another content
                                            </button>
                                        </div>
                                        <div className="w-full">
                                            <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Author</label>
                                            <input
                                                type="text"
                                                className="block w-full border border-gray-300 rounded-md px-3 py-4 mt-1 bg-white focus:outline-none focus:border-blue-500"
                                                placeholder="Enter author name"
                                                value={formData.author}
                                                onChange={(e) => setFormData({ ...formData, author: e.target.value })}
                                            />
                                        </div>

                                        {selectedBlog && (
                                            <div>
                                                <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Author Profile</label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleAuthorProfileChange}
                                                    className="block w-full border border-gray-300 rounded-md px-3 py-2 mt-1 bg-white"
                                                />
                                                {formData.author_profile ? (
                                                    <img src={URL.createObjectURL(formData.author_profile)} alt="Author Profile Preview" className="mt-2 w-[30%] h-auto rounded-md" />
                                                ) : (
                                                    <img src={selectedBlog.author_profile} alt="Author Profile Preview" className="mt-2 w-[30%] h-auto rounded-md" />
                                                )}
                                            </div>
                                        )}

                                        <div className="w-full">
                                            <label className="block text-[#222] text-[18px] font-medium leading-[24px]">Qualification</label>
                                            <input
                                                type="text"
                                                className="block w-full border border-gray-300 rounded-md px-3 py-4 mt-1 bg-white focus:outline-none focus:border-blue-500"
                                                placeholder="Enter qualification"
                                                value={formData.qualification}
                                                onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
                                            />
                                        </div>


                                        <p htmlFor="author" className="text-[color:var(--Black,#222)] pt-6 text-[18px] not-italic font-medium leading-[24px]">Blog Date</p>
                                        <div className="pt-2 ">
                                            <input
                                                type="date"
                                                id="date"
                                                className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={formData.date}
                                                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                            />
                                        </div>
                                        <div className='flex'>
                                            <div className='pt-8 w-[500px]'>
                                                <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                                </button>
                                            </div>
                                            <div className='pt-8 w-[25%] ml-6'>
                                                <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitEditForm}>Cancel</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </>
                )}

            </div>
        </div>
    );
};

export default Blogs;
