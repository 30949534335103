import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import Logo23 from "../../../assets/images/Line 5.svg";
import logo20 from "../../../assets/images/arrow-right-02-round.png";



import { motion, useAnimation, useInView } from 'framer-motion';

const AboutCard4 = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });



    // Example effect to set isLoggedIn (replace this with your actual logic)
    useEffect(() => {
        // This is a placeholder. Replace with actual logic to check if user is logged in.
        const checkLoginStatus = () => {
            // For example, check if there's a token in localStorage
            const token = localStorage.getItem('authToken');
            setIsLoggedIn(!!token);
        };

        checkLoginStatus();
    }, []);
    const imageControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
            imageControls.start({ opacity: 1, x: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
            imageControls.start({ opacity: 0, x: '100%' }); // Start from outside the right side
        }
    }, [isInView, controls, imageControls]);

    return (
        <div className="w-full h-full bg-[#f4f4f4] mob-paddingbottom">
            <div className="container">
                <div className="pt-6">
                    <div className="relative bg-[#00549A] rounded-[30px] overflow-hidden">
                        <motion.div
                            className=" "
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 0.6 }}
                            ref={ref}
                        >
                            <div className="text-white pt-36 pl-14 pb-16 text-start text-[32px] not-italic font-light leading-[43px]">
                                Dedicated to your <br /> <span style={{ fontWeight: "bold" }}>health and happiness</span>
                                <div className="text-left pt-6">
                                    {!isLoggedIn && (
                                        <Link to="/register">
                                            <div className="inline-flex py-[10px] px-[60px] justify-center items-center gap-[10px] shrink-0 bg-[#1AB78D] rounded-[105px]">
                                                <p className="text-[#FFF] text-[16px] not-italic font-normal leading-[normal]">Join FCPI</p>
                                                <div className="ml-2">
                                                    <img src={logo20} alt="Logo" />
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                        <motion.img
                            src={Logo23}
                            alt="Image"
                            className="absolute right-0 top-8 transform -translate-y-1/2"
                            initial={{ opacity: 0, x: '100%' }}
                            animate={imageControls}
                            transition={{ duration: 0.6 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCard4;
