import react from "react";
import Home from "../../../../Components/Home";
import Login from "../../../../Components/User/Dashboard/Login/Login";    
import Footer from  "../../../../Components/Footer/Footer";


const LoginPage = () => {

    return (
        <div>
    <Home/>
    <Login/>
  
  <Footer/> 
    </div>
    )
}
export default LoginPage;