import React, { useState } from 'react';
import logo3 from "../../../assets/images/user-story.svg";
// import "./Navbar.css";
import { Link } from 'react-router-dom';  
import acc from "../../../assets/images/user-account.png";
import adm1 from "../../../assets/images/profile image.png";
import adm from "../../../assets/images/admin-1.png";
import but from "../../../assets/images/search-normal.png";


const Anavbar = () => {
 
    return (
        <div className=''>
             <div className= " w-full h-full ">
                 <div className="flex p-4 bg-white pt-4 justify-center">
                    <p className='text-[#06101C] pt-2 text-[22px] not-italic font-semibold leading-[24px]  '>Welcome to FCPI Dashboard</p>
                    
                   
             
        </div>
        </div>
        </div>
    );
};

export default Anavbar;
