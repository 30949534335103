import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import search from '../../../assets/images/search.png';
import './AddEvent.css';
import { useParams } from 'react-router-dom';
import imag from "../../../assets/images/feedback-user.svg";
import imag1 from "../../../assets/images/gen-feedback.svg";

const Feedback = () => {
    const { slug } = useParams();
    const [normalEvents, setNormalEvents] = useState([]);
    const [generalEvents, setGeneralEvents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [enrolledUsers, setEnrolledUsers] = useState([]);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [currentGeneralEvent, setCurrentGeneralEvent] = useState(null);
    const [activeTab, setActiveTab] = useState('normal'); // New state for tabs

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const fetchEventData = async (url) => {
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching event data:', error);
            return [];
        }
    };

    const fetchEnrolledUsers = async (singleEventId) => {
        const endpoint = `${BASE_URL}/accounts/feedbacks/${singleEventId}/`;
        try {
            const response = await axios.get(endpoint);


            if (response.data && response.data.feedbacks) {

                const formattedUsers = response.data.feedbacks.map((feedback) => ({
                    name: feedback.user?.name || 'N/A',
                    email: feedback.user?.email || 'N/A',
                    phone: feedback.user?.phone || 'N/A',
                    feedback: feedback,
                }));

                setEnrolledUsers(formattedUsers);
                console.log("response feedback", response.data);
                setPopupVisible(true);
            } else {
                console.warn('No feedback data found.');
            }
        } catch (error) {
            console.error('Error fetching enrolled users:', error);
        }
    };


    const fetchGeneralEnrolledUsers = async (singleEventId) => {
        const endpoint = `${BASE_URL}/accounts/general-feedbacks/${singleEventId}/`;
        try {
            const response = await axios.get(endpoint);


            if (response.data && response.data.feedbacks) {

                const formattedUsers = response.data.feedbacks.map((feedback) => ({
                    name: feedback.user?.name || 'N/A',
                    email: feedback.user?.email || 'N/A',
                    phone: feedback.user?.phone || 'N/A',
                    feedback: feedback,
                }));

                setEnrolledUsers(formattedUsers);
                setPopupVisible(true);
                console.log("response general feedback", response.data);
            } else {
                console.warn('No general feedback data found.');
            }
        } catch (error) {
            console.error('Error fetching general enrolled users:', error);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const fetchedNormalEvents = await fetchEventData(`${BASE_URL}/admins/single-events-all/`);
            const fetchedGeneralEvents = await fetchEventData(`${BASE_URL}/admins/general-single-events-all/`);

            console.log("Fetched Normal Events:", fetchedNormalEvents);
            console.log("Fetched General Events:", fetchedGeneralEvents);

            // Sort the events in descending order (latest date first)
            const sortedNormalEvents = fetchedNormalEvents.sort((a, b) => new Date(b.date) - new Date(a.date));
            const sortedGeneralEvents = fetchedGeneralEvents.sort((a, b) => new Date(b.date) - new Date(a.date));

            setNormalEvents(sortedNormalEvents);
            setGeneralEvents(sortedGeneralEvents);
        };

        fetchData();
    }, []);


    const handleNormalEnrolledButtonClick = (event) => {
        setCurrentEvent(event);
        fetchEnrolledUsers(event.id);
    };

    const handleGeneralEnrolledButtonClick = (event) => {
        setCurrentGeneralEvent(event);
        fetchGeneralEnrolledUsers(event.id);
    };

    // Filtering events based on the active tab and search query
    const filteredEvents = (activeTab === 'normal' ? normalEvents : generalEvents).filter(event =>
        event.event_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const [currentPage, setCurrentPage] = useState(1);
    const eventsPerPage = 10; // Number of events per page
    const totalPages = Math.ceil(filteredEvents.length / eventsPerPage);
    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] font-semibold'>Enrolled Users</p>
                <div className='pt-8'>
                    {/* Tabs for Normal and General Events */}
                    <div className='flex gap-[12rem] pl-4 '>
                        <button
                            className={`relative p-8 ${activeTab === 'normal' ? 'border-b-2 border-[#00549A]' : ''}`}
                            onClick={() => setActiveTab('normal')}
                        >
                            <div className='flex gap-3 '>
                            {activeTab === 'normal' && <img src={imag} alt="" />}
                            <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${activeTab === 'normal' ? 'text-[#00549A]' : 'text-gray-600'}`}> Events Feedback</p>
                            </div>
                        </button>
                        <button
                            className={`relative p-4 ${activeTab === 'general' ? 'border-b-2 border-[#00549A]' : ''}`}
                            onClick={() => setActiveTab('general')}
                        >
                            <div className='flex gap-3 '>
                            {activeTab === 'general' && <img src={imag1} alt="" />}
                            <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${activeTab === 'general' ? 'text-[#00549A]' : 'text-gray-600'}`}>General Feedback</p>
                            </div>
                        </button>
                    </div>
                    <div className='pt-8'>
                        {/* Search Input */}
                        <div className='relative'>
                            <input
                                type="text"
                                placeholder="Search events..."
                                value={searchQuery}
                                onChange={handleSearchInputChange}
                                className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none w-[50%]"
                            />
                            <img src={search} alt="Search" className="absolute left-[40rem] top-6 transform -translate-y-1/2" />
                        </div>

                        <div className='pt-4'>
                            <div className='pt-4'>
                                {currentEvents.map((event, index) => (
                                    <div key={index}>
                                        <EventRow
                                            event={event}
                                            index={indexOfFirstEvent + index + 1} // Adjust the index for display
                                            onEnrolledClick={activeTab === 'normal' ? handleNormalEnrolledButtonClick : handleGeneralEnrolledButtonClick}
                                        />
                                        <hr />
                                    </div>
                                ))}
                            </div>


                        </div>
                    </div>
                    <div className="pagination flex justify-center items-center space-x-4 mt-6">
                        <button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                        >
                            <span className="pagination-icon">&#8249;</span> {/* Left arrow */}
                        </button>
                        <span className="pagination-info">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={nextPage}
                            disabled={currentPage === totalPages}
                            className={`pagination-arrow ${currentPage === totalPages ? 'disabled' : ''}`}
                        >
                            <span className="pagination-icon">&#8250;</span> {/* Right arrow */}
                        </button>
                    </div>

                </div>
            </div>

            {(popupVisible && currentEvent) && (
                <UserPopup enrolledUsers={enrolledUsers} eventName={currentEvent.event_name} onClose={() => setPopupVisible(false)} />
            )}
            {(popupVisible && currentGeneralEvent) && (
                <UserPopup enrolledUsers={enrolledUsers} eventName={currentGeneralEvent.event_name} onClose={() => setPopupVisible(false)} />
            )}
        </div>
    );
};

const EventRow = ({ event, index, onEnrolledClick }) => (
    <div className='flex pt-4 pb-4 pl-8'>
        <div className='flex gap-4 '>
            <p className='text-[color:var(--Gray,#58585A)] w-[760px] text-[16px]'> {index} . {event.event_name}  - Day {event.day}</p>
            <p className='text-[color:var(--Gray,#58585A)] w-[300px] text-[16px]'>{event.date}</p>
            <div>
                <button
                    className='bg-[#00549A] text-white p-2 rounded-[8px]'
                    onClick={() => onEnrolledClick(event)}
                >
                    Feedback Users
                </button>
            </div>
            <hr />
        </div>
        <hr />
    </div>
);


const UserPopup = ({ enrolledUsers, eventName, onClose }) => {
    const getSatisfactionLabel = (code) => {
        switch (code) {
            case 'VS': return 'Very Satisfied';
            case 'SS': return 'Somewhat Satisfied';
            case 'N': return 'Neutral';
            case 'US': return 'Unsatisfied';
            case 'VU': return 'Very Unsatisfied';
            default: return 'N/A';
        }
    };
    
    const getHowDidYouHearLabel = (code) => {
        switch (code) {
            case 'E': return 'Email';
            case 'S': return 'Social Media Post';
            case 'W': return 'WhatsApp';
            case 'F': return 'FCPI Website';
            case 'R': return 'Referral';
            case 'I': return 'IDCongress2023';
            case 'O': return 'Other';
            default: return 'N/A';
        }
    };
    
    const exportToCSV = () => {
        const csvData = [
            ['No.', 'Event Name', 'Name', 'Email', 'Phone', 'Audio/Video Quality', 'Presentation Content', 'Presentation Duration', 'Speaker Delivery', 'How Did You Hear', 'Suggestions'], 
            ...enrolledUsers.map((user, index) => [
                index + 1,                         
                eventName,                            
                user.name,                           
                user.email,                         
                user.phone,                         
                getSatisfactionLabel(user.feedback?.audio_video_quality),     
                getSatisfactionLabel(user.feedback?.presentation_content),   
                getSatisfactionLabel(user.feedback?.presentation_duration),   
                getSatisfactionLabel(user.feedback?.speaker_delivery),       
                getHowDidYouHearLabel(user.feedback?.how_did_you_hear),       
                user.feedback?.suggestion || 'N/A',  
            ])
        ];
    
        const csvContent = csvData.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${eventName}-enrolled-users.csv`);
        link.click();
    };
    
    

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-[60%] p-4 rounded-md shadow-lg max-h-screen overflow-y-auto">
                <div className='flex justify-between'>
                    <div className="text-xl font-semibold">Feedback Users</div>
                    <div className="flex gap-4">
                        <button
                            className='bg-[#00549A] text-white py-2 px-4 rounded-md hover:bg-[#004080] transition duration-200'
                            onClick={exportToCSV}
                        >
                            Export as CSV
                        </button>
                        <button
                            className='bg-[#00549A] text-white py-2 px-4 rounded-md hover:bg-[#004080] transition duration-200'
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
                <div className="overflow-x-auto pt-8">
                    <div className="table w-full">
                        <div className="table-header-group">
                            <div className="table-row bg-gray-200">
                                <div className="table-cell p-4 font-semibold">N0</div>
                                <div className="table-cell p-4 font-semibold">Name</div>
                                <div className="table-cell p-4 font-semibold">Email</div>
                                <div className="table-cell p-4 font-semibold">Phone</div>
                            </div>
                        </div>
                        <div className="  table-row-group ">
                            {enrolledUsers.length > 0 ? (
                                enrolledUsers.map((user, index) => (
                                    <div key={index} className="table-row border-b">
                                        <div className="table-cell py-2 px-4">{index + 1}</div> {/* Count column */}
                                        <div className="table-cell w-[40%] py-2 px-4">{user.name}</div>
                                        <div className="table-cell py-2 px-4">{user.email}</div>
                                        <div className="table-cell py-2 px-4">{user.phone}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="table-row">
                                    <div className="table-cell py-2 px-4 text-gray-500 text-center" colSpan="4">No feedbacks</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedback;
