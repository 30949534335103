import react from "react";
import Home from "../../../../Components/Home";
import ResetPassword from "../../../../Components/User/Dashboard/Login/ResetPassword";    
import Footer from  "../../../../Components/Footer/Footer";


const  ResetPage = () => {

    return (
        <div>
    <Home/>
    <ResetPassword/>
  
  <Footer/> 
    </div>
    )
}
export default ResetPage;