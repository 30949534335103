import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import imag from "../../../assets/images/add-member.svg";
import imag1 from "../../../assets/images/manage-member.svg";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import pic from "../../../assets/images/Picture.png";
import search from "../../../assets/images/search.png";
import cls from "../../../assets/images/close.png";
import icon from "../../../assets/images/icon.png";
import icon1 from "../../../assets/images/icon1.png";

const Speaker = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setMailingAddressActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);
    const [editSpeakerVisible, setEditSpeakerVisible] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        qualification: '',
        designation: '',
        description: '',
        photo: null,
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        youtube: ''
    });
    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditSpeakerVisible(false);
        setDisplaySectionVisible(true);
    };

    const [speakers, setSpeakers] = useState([]);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.title]: e.target.value });
    };
    const fetchSpeakers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/speakers/`);
            setSpeakers(response.data);
        } catch (error) {
            console.error('Error fetching speakers:', error);
        }
    };

    useEffect(() => {
        fetchSpeakers();
    }, []);


    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
        setFormData(prevFormData => ({
            ...prevFormData,
            photo: imageFile
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('name', formData.name);
            postData.append('qualification', formData.qualification);
            postData.append('designation', formData.designation);
            postData.append('description', formData.description);
            postData.append('photo', formData.photo);
            postData.append('facebook', formData.facebook);
            postData.append('twitter', formData.twitter);
            postData.append('linkedin', formData.linkedin);
            postData.append('instagram', formData.instagram);
            postData.append('youtube', formData.youtube);

            const response = await axios.post(`${BASE_URL}/admins/speakers/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);

            alert('Speaker added successfully!');

            setFormData({
                name: '',
                qualification: '',
                designation: '',
                description: '',
                photo: null,
                facebook: '',
                twitter: '',
                linkedin: '',
                instagram: '',
                youtube: ''
            });

            window.location.reload();

        } catch (error) {
            console.error('Error adding speaker:', error);
            alert('Failed to add speaker. Please try again.');
        }
    };




    const handleDelete = async (speakerId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this speaker?');

        if (confirmDelete) {
            try {
                await axios.delete(`${BASE_URL}/admins/speakers/${speakerId}/delete/`);
                alert('Speaker deleted successfully!');

                // Refresh the list of speakers
                fetchSpeakers();
            } catch (error) {
                console.error('Error deleting speaker:', error);
                alert('Failed to delete speaker. Please try again.');
            }
        }
    };


    const handleEdit = (speaker) => {
        setSelectedSpeaker(speaker);
        setEditSpeakerVisible(!editSpeakerVisible);
        setSelectedImage(null);


        setFormData({
            name: speaker.name,
            qualification: speaker.qualification,
            designation: speaker.designation,
            description: speaker.description,
            photo: null,
            facebook: speaker.facebook || '',
            twitter: speaker.twitter || '',
            linkedin: speaker.linkedin || '',
            instagram: speaker.instagram || '',
            youtube: speaker.youtube || ''
        });
    };


    const handleSpeakerSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('name', formData.name);
            postData.append('qualification', formData.qualification);
            postData.append('designation', formData.designation);
            postData.append('description', formData.description);

            if (formData.photo) {
                postData.append('photo', formData.photo);
            }
            postData.append('facebook', formData.facebook);
            postData.append('twitter', formData.twitter);
            postData.append('linkedin', formData.linkedin);
            postData.append('instagram', formData.instagram);
            postData.append('youtube', formData.youtube);

            let response;
            if (!selectedSpeaker) {
                response = await axios.post(`${BASE_URL}/admins/speakers/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                alert('Speaker added successfully!');
            } else {
                response = await axios.put(`${BASE_URL}/admins/speakers/${selectedSpeaker.id}/update/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                alert('Speaker updated successfully!');
            }

            const updatedSpeaker = response.data;

            setSpeakers(prevSpeakers => {
                if (!selectedSpeaker) {
                    return [...prevSpeakers, updatedSpeaker];
                } else {
                    return prevSpeakers.map(speaker => speaker.id === updatedSpeaker.id ? updatedSpeaker : speaker);
                }
            });

            setFormData({
                name: '',
                qualification: '',
                designation: '',
                description: '',
                photo: '',
                facebook: '',
                twitter: '',
                linkedin: '',
                instagram: '',
                youtube: ''
            });
            setEditSpeakerVisible(false);

        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit Speaker. Please try again.');
        }
    };


    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setMailingAddressActive(false);
        }
    };

    const toggleMailingAddress = () => {
        setMailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const [currentPage, setCurrentPage] = useState(1);
    const speakersPerPage = 10;
    const filteredSpeakers = speakers.filter(speaker =>
        speaker.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const indexOfLastSpeaker = currentPage * speakersPerPage;
    const indexOfFirstSpeaker = indexOfLastSpeaker - speakersPerPage;
    const currentSpeakers = filteredSpeakers.slice(indexOfFirstSpeaker, indexOfLastSpeaker);

    const totalPages = Math.ceil(filteredSpeakers.length / speakersPerPage);
    const startingIndex = (currentPage - 1) * speakersPerPage + 1;


    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Speaker Management</p>
                <div className='flex pt-6 gap-[12rem] p-6'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Speaker</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={toggleMailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Speaker</p>
                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Speaker Name</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Speaker Name"
                                        value={formData.name}
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Qualification</p>
                                <div className="pt-2 relative">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Qualification"
                                            value={formData.qualification}
                                            onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Designation</p>
                                <div className="pt-2 relative">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Designation"
                                            value={formData.designation}
                                            onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
                                        />

                                    </div>
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Description</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4 pb-28 w-full bg-[#F4F4F4]"
                                        placeholder="Description"
                                        value={formData.description}
                                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Upload Photos</p>
                                <div className="pt-2">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Facebook</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="facebook"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Facebook URL"
                                        value={formData.facebook}
                                        onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Twitter</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="twitter"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Twitter URL"
                                        value={formData.twitter}
                                        onChange={(e) => setFormData({ ...formData, twitter: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>LinkedIn</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="linkedin"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="LinkedIn URL"
                                        value={formData.linkedin}
                                        onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Instagram</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="instagram"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Instagram URL"
                                        value={formData.instagram}
                                        onChange={(e) => setFormData({ ...formData, instagram: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>YouTube</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="youtube"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="YouTube URL"
                                        value={formData.youtube}
                                        onChange={(e) => setFormData({ ...formData, youtube: e.target.value })}
                                    />
                                </div>
                                <div className='pt-8'>
                                    <div className='flex gap-2 justify-end'>

                                        <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                            <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                        </button>

                                        {/* <button type="button" className='bg-[#00549A] rounded-[10px] px-4 py-2'>
                            <p className='text-white  text-center text-[16px] not-italic font-normal leading-[normal]'>Cancel</p>
                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editSpeakerVisible && (
                            <div className=' '>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        placeholder="Search speakers..."
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[50%] relative"
                                    />

                                    <img src={search} alt="Search" className="absolute left-[40rem] top-6 transform -translate-y-1/2" />
                                </div>
                                {currentSpeakers.map((speaker, index) => (
                                    <div className='pt-2' key={index}>
                                        <div className='w-full '>
                                            <div className='flex pt-4 pb-4 pl-8 '>
                                            <p className='text-[color:var(--Gray,#58585A)] pt-3  text-[16px] not-italic font-normal leading-[normal]'>{startingIndex + index}</p>
                                                <div className='flex gap-4 pl-6'>
                                                    <img src={speaker.photo} alt="" className='w-14 rounded-[60px]' />
                                                    <p className='text-[color:var(--Gray,#58585A)] w-[500px] pt-2 pl-4 text-[16px] not-italic font-normal leading-[normal]'>{speaker.name}</p>
                                                </div>
                                                <div className='flex gap-6 pt-2'>
                                                    <div>
                                                        <img src={ed} alt="" className=' ' onClick={() => handleEdit(speaker)} />
                                                    </div>
                                                    <div>
                                                        <img src={del} alt="" className=' ' onClick={() => handleDelete(speaker.id)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className=' ' />
                                        </div>
                                    </div>
                                ))}
                                <div className="pagination flex justify-center items-center space-x-4 mt-6">
                                    <button
                                        onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
                                        disabled={currentPage === 1}
                                        className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                    >
                                        <span className="pagination-icon">&#8249;</span> {/* Left arrow */}
                                    </button>
                                    <span className="pagination-info">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                        className={`pagination-arrow ${currentPage === totalPages ? 'disabled' : ''}`}
                                    >
                                        <span className="pagination-icon">&#8250;</span> {/* Right arrow */}
                                    </button>
                                </div>

                            </div>
                        )}
                        {editSpeakerVisible && selectedSpeaker && (
                            <div className='w-[50%]'>
                                <form onSubmit={handleSpeakerSubmit}>
                                    <div className="text-start">
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Speaker Name</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="Speaker Name"
                                                value={formData.name}
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Qualification</p>
                                        <div className="pt-2 relative">
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="FQualification"
                                                    value={formData.qualification}
                                                    onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Designation</p>
                                        <div className="pt-2 relative">
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Designation"
                                                    value={formData.designation}
                                                    onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
                                                />


                                            </div>
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Description</p>
                                        <div className="pt-1">
                                            <textarea
                                                className="border rounded-[6px] px-[20px] py-4 pb-28 w-full bg-[#F4F4F4]"
                                                placeholder="Description"
                                                value={formData.description}
                                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Upload Photos</p>
                                        {selectedSpeaker && (
                                            <div className="pt-1">

                                                <img src={selectedImage ? URL.createObjectURL(selectedImage) : selectedSpeaker.photo} alt="Old Forum Image" className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-[200px] bg-[#F4F4F4]" />
                                            </div>
                                        )}
                                        <div className="pt-1">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Facebook</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                name="facebook"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="Facebook URL"
                                                value={formData.facebook}
                                                onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Twitter</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                name="twitter"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="Twitter URL"
                                                value={formData.twitter}
                                                onChange={(e) => setFormData({ ...formData, twitter: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>LinkedIn</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                name="linkedin"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="LinkedIn URL"
                                                value={formData.linkedin}
                                                onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Instagram</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                name="instagram"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="Instagram URL"
                                                value={formData.instagram}
                                                onChange={(e) => setFormData({ ...formData, instagram: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>YouTube</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                name="youtube"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="YouTube URL"
                                                value={formData.youtube}
                                                onChange={(e) => setFormData({ ...formData, youtube: e.target.value })}
                                            />
                                        </div>



                                        <div className='flex'>
                                            <div className='pt-8 w-[70%]'>
                                                <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                                </button>
                                            </div>


                                            <div className='pt-8 w-[30%] ml-6'>
                                                <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitEditForm}>Cancel</p>
                                                </button>
                                            </div>
                                        </div>



                                    </div>
                                </form>
                            </div>
                        )}

                    </>
                )}
            </div>
        </div >
    );
};

export default Speaker;
