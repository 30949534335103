import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
import Speciality from "../../../Components/User/SpecialityForum/Speciality";
import Card1 from "../../../Components/User/SpecialityForum/Card1";

const SpecialityForum = () => {

    return (
        <div>
    <Home/>
    <Speciality/>
    <Card1/>
  <Footer/> 
    </div>
    )
}
export default SpecialityForum;