import React from "react";
import Asidebar from "../../../Components/Admin/Asidebar/Asidebar";
import Anavbar from "../../../Components/Admin/Anavbar/Anavbar"; 
import Board from "../../../Components/Admin/ACMS/Board";
 
 

const  ABoardPage = () => {
    return (
       
        <div className="">
            <div className="asidebardiv apos-side">
            <Asidebar />
            </div>

            <div className="anavdiv apos">          
            <Anavbar />
           <Board/>
            </div>
                  
                  
                  
            </div>   
         
          
         
          
       
    );
}

export default ABoardPage;
