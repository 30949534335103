import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import axios from "axios";
import Logo7 from "../../../assets/images/eclips.png";
import arrowLeft from "../../../assets/images/arrow-right-01-round.png";
import arrowRight from "../../../assets/images/arrow-right-02-round.png";
import red from "../../../assets/images/medical-banner-with-doctor-wearing-coat.jpg";
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
import './Blog.css';

const SpecialityForum = () => {
  const { slug } = useParams();
  const [forum, setForum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(2);
  const [liveEvents, setLiveEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [completedEvents, setCompletedEvents] = useState([]);
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isInView, controls]);
  useEffect(() => {
    const fetchForumDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admins/forums/${slug}/`);
        const forumData = response.data;
        setForum(forumData);
        setLiveEvents(forumData.live_events);
        setUpcomingEvents(forumData.upcoming_events);
        setCompletedEvents(forumData.completed_events);
        setLoading(false);
        console.log("completed_events", forumData)

      } catch (error) {

        setLoading(false);
      }
    };

    fetchForumDetails();
  }, [slug]);

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admins/blogsforum/${slug}/`);
        // console.log("Response", response);
        const blogsData = response.data;
        setBlogs(blogsData);
        setLoading(false);
        console.log("Blogs", blogsData); // Log the blogs data
      } catch (error) {
        // console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [slug]);





  if (!forum) {
    return <div></div>;
  }

  const handlePastEventsClick = () => {
    setCurrentIndex(2);
  };

  const handleLiveEventsClick = () => {
    setCurrentIndex(0);
  };

  const handleUpcomingEventsClick = () => {
    setCurrentIndex(1);
  };

  const renderBanners = () => {
    let eventsToShow = [];

    if (currentIndex === 0) {
      eventsToShow = liveEvents;
    } else if (currentIndex === 1) {
      eventsToShow = upcomingEvents;
    } else if (currentIndex === 2) {
      eventsToShow = completedEvents;
    }

    return eventsToShow.map((event, index) => (
      <Link to={`/events-detail/${event.slug}/`} key={index}>
        <img
          key={index}
          src={BASE_URL + event.banner}
          alt={`Banner ${index}`}
          className="rounded-[30px] w-full object-cover h-full"
        />
      </Link>
    ));

  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <div className="w-full h-full bg-[#f4f4f4]">
      <div className="container">
        <div className="pt-6">
          <div className='aspect-[4] bg-card8-background bg-cover to-slate-900 bg-opacity-40 t responsive-card rounded-[30px]'>            
              <div className='z-10 pl-12 pt-32'>
                <p className='forum-name-head relative  text-white'>{forum?.forum.title}</p>  
              </div>      
          </div>


          <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 pt-6">

            <div>
              <div className="mob-pb-20 rounded-[30px] bg-[#00549a] pall-15">

                <div className='eventhead'>
                  <h4 className='event-head'>Events</h4>
                  
                </div>

                <div className="flex rounded-[30px]">




                  <div className=" mt-0 pt-0">
                    <button
                      className={`smooth event-pad-r-l rounded-[105px] border ${currentIndex === 2 ? 'border-transparent' : 'border-white'}`}
                      style={{ width: 'auto', backgroundColor: currentIndex === 2 ? '#1ab78d' : '', }}
                      onClick={handlePastEventsClick}
                    >
                      <p className="text-white text-[14px] font-semibold">Past <span className='m-640-none'>Events</span></p>
                    </button>
                    <button
                      className={`smooth transition justify-center items-center ml-3 event-pad-r-l rounded-[105px] border ${currentIndex === 0 ? 'border-transparent' : ''}`}
                      style={{ width: 'auto', backgroundColor: currentIndex === 0 ? '#1ab78d' : '' }}
                      onClick={handleLiveEventsClick}
                    >
                      <p className="text-white text-[14px] font-semibold">Live <span className='m-640-none'>Events</span> </p>
                    </button>
                    <button
                      className={`smoothtransition justify-center items-center ml-3 event-pad-r-l rounded-[105px] border ${currentIndex === 1 ? 'border-transparent' : ''}`}
                      style={{ width: 'auto', backgroundColor: currentIndex === 1 ? '#1ab78d' : '' }}
                      onClick={handleUpcomingEventsClick}
                    >
                      <p className="text-white text-[14px] font-semibold">Upcoming <span className='m-640-none'>Events</span></p>
                    </button>
                  </div>


                </div>

                <div>
                  <motion.div
                    className="flex justify-center pb-6 items-center eventslider h-56 sm:h-64 md:h-72 lg:h-72 xl:h-80 2xl:h-96 "
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 4 }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                    }}
                    ref={ref}
                  >

                    <Carousel
                      indicators={false}
                      autoHeight={true}
                      className="   mt-6 w-[100%] m-w-100-div mx-auto"
                      prevIcon={<img src={arrowLeft} alt="Previous" className="w-6 h-6 md:w-8 md:h-8" />}
                      nextIcon={<img src={arrowRight} alt="Next" className="w-6 h-6 md:w-8 md:h-8" />}
                    >
                      {renderBanners()}
                    </Carousel>

                  </motion.div>
                </div>
              </div>
            </div>
            <div>  {blogs.slice(-1).map(blog => (
              <Link to={`/blogs-detail/${blog.slug}`}>
                <div className='bg-white rounded-[30px] blog-m' key={blog.id}>
                  <div className='blogsection'>


                    <motion.div
                      className="blogimg "
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 4 }}
                      transition={{
                        duration: 1,
                        ease: "easeInOut",
                      }}
                      ref={ref}
                    >

                      <img src={BASE_URL + blog.blog_banner} alt="Banner" className="rounded-[30px] relative" />
                    </motion.div>

                    <div className="absolute right-4 top-8 transform -translate-y-1/2 inline-flex z-10 gap-[10px] shrink-0 px-[28px] py-[8px] rounded-[105px] bg-[#c5e5ff]">
                      <p className="text-[#222] text-[14px] not-italic font-normal leading-[normal]">Latest Blogs</p>
                    </div>
                  </div>
                  <div className='pt-10 text-start'>
                    <p className='text-black text-start pl-8 text-[20px] not-italic font-semibold leading-[30px]'>{blog.title}</p>
                    <hr className='mt-4' />
                    <div className='flex pt-5 pb-5 pl-8'>
                      <div className='inline-flex justify-center items-center gap-[10px] px-[9px] py-[2px]   '>
                        <img src={BASE_URL + blog.author_profile} alt="" className='rounded-[30px] w-[40px] h-[40px]' />
                      </div>

                      <p className='text-[#58585A] pl-4 text-[16px] not-italic font-medium leading-[24px]'>By {blog.author}</p>


                      <div className="flex-grow"></div>

                      <p className='text-[#58585A] pr-8 text-[16px] not-italic font-normal leading-[24px]'>{formatDate(blog.date)}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}</div>

            <motion.div
              className=" "
              initial={{ opacity: 0 }}
              animate={{ opacity: 4 }}
              transition={{
                duration: 1,
                ease: "easeInOut",
              }}
              ref={ref}
            >



            </motion.div>
          </div>
          <div>
            <h1>{forum.title}</h1>
            <p>{forum.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialityForum;
