import react from "react";
import Home from "../../../Components/Home/index";
import Chat from "../../../Components/User/Dashboard/Chat/Chat";  
import Card2 from "../../../Components/User/Events/Card2";  
import Footer from  "../../../Components/Footer/Footer";


const DetailPage = () => {

    return (
        <div>
    <Home/>
    <Chat/>
    <Card2/>
  <Footer/> 
    </div>
    )
}
export default DetailPage;