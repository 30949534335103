import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
 
import Newsletter from "../../../Components/User/Newsletter/Newsletter";
 

const  NewslettersPage = () => {

    return (
        <div>
    <Home/>
    <Newsletter/>
     
    <Footer/> 
    </div>
    )
}
export default NewslettersPage;