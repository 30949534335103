import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/home-banner.svg";
import imag1 from "../../../assets/images/manage-home-banner.svg";
import Icon from "../../../assets/images/Icon frame.png";
import { Link } from "react-router-dom";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';




const HomeBanner = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);

    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedForum, setSelectedForum] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [banners, setBanners] = useState([]);
    const [formData, setFormData] = useState({

        url: '',
        banner: null,
    });
    const [banner, setBanner] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
        setFormData(prevFormData => ({
            ...prevFormData,
            banner: imageFile
        }));
    };



    const fetchBanner = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/banner/`);
            // Sort the banners by ID in descending order
            const sortedData = response.data.sort((a, b) => b.id - a.id);
            setBanner(sortedData);
            console.log("banner", sortedData);
        } catch (error) {
            console.error('Error fetching banner:', error);
        }
    };

    useEffect(() => {
        fetchBanner();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('url', formData.url);
            postData.append('banner', formData.banner);

            const response = await axios.post(`${BASE_URL}/admins/banner/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);

            alert('banner added successfully!');
            window.location.reload();
            setFormData({
                url: '',
                image: null
            });

        } catch (error) {
            console.error('Error adding Banner:', error);
            alert('Failed to add forum. Please try again.');
        }
    };


    const handleEdit = (banner) => {
        setSelectedBanner(banner);
        setSelectedImage(null);
        setFormData({
            url: banner.url,
            banner: null,
        });
        setEditFormVisible(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('url', formData.url);

            if (formData.banner) {
                postData.append('banner', formData.banner);
            }

            let response;
            if (!selectedBanner) {

                response = await axios.post(`${BASE_URL}/admins/banner/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(response.data);
                alert('Banner added successfully!');
            } else {

                response = await axios.put(`${BASE_URL}/admins/banner/${selectedBanner.id}/update/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(response.data);
                alert('Banner updated successfully!');
            }

            const updatedBanner = response.data;


            setBanner(prevBanner => {
                if (!selectedBanner) {

                    return [...prevBanner, updatedBanner];
                } else {

                    return prevBanner.map(banner =>
                        banner.id === updatedBanner.id ? updatedBanner : banner
                    );
                }
            });

            setFormData({
                url: '',
                banner: null
            });
            setEditFormVisible(false);

        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit Banner. Please try again.');
        }
    };



    const handleDelete = async (bannerId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this banner?');

        if (confirmDelete) {
            try {
                const response = await axios.delete(`${BASE_URL}/admins/banner/${bannerId}/delete/`);
                console.log(response.data);
                alert('Banner deleted successfully!');
                fetchBanner()
            } catch (error) {
                console.error('Error deleting banner:', error);
                alert('Failed to delete banner. Please try again.');
            }
        }
    };








    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Banner</p>
                <div className='flex pt-12 gap-[12rem] p-8'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Banner</p>

                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Banner</p>

                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">


                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Banner</p>
                                <div className="pt-1">
                                    <input
                                        type="file"
                                        accept="banner/*"
                                        onChange={handleImageChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-2 w-full bg-[#F4F4F4]"
                                    />
                                </div>
                                <div className="relative  pt-6">
                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>URL</p>
                                    <div className="pt-2">
                                        <input
                                            type="text"
                                            name="url"
                                            value={formData.url}
                                            className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="url"
                                            onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                                        />

                                    </div>
                                </div>
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>

                )}

                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editFormVisible && (
                            <div className='pt-4'>
                                <div className='bg-gray-100 py-4 w-full flex p-6 gap-[27.5rem]'>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-2 text-[18px] not-italic font-semibold leading-[normal]'>Banner ID</p>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-2 text-[18px] not-italic font-semibold leading-[normal]'>Banner Image</p>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold leading-[normal]'>Actions</p>
                                </div>
                                <div className="">

                                    {banner.map((banner, index) => (
                                        <div key={index} className="flex gap-[20.5rem]">
                                            <p className='pt-11 pl-12 text-[18px]'>{index + 1}</p>  
                                            <div className='banner-item w-[30%] pl-36 pt-4'>
                                                <img src={banner.banner} alt={`Banner ${index + 1}`} className='pt-8 w-[200px]' />
                                            </div>
                                            <div className='flex gap-6 pt-14'>
                                                <div>
                                                    <img src={ed} alt="" className='' onClick={() => handleEdit(banner)} />
                                                </div>
                                                <div>
                                                    <img src={del} alt="" className='' onClick={() => handleDelete(banner.id)} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}



                                </div>
                            </div>


                        )}

                        {editFormVisible && (
                            <div className='w-[50%]'>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="text-start">
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Banner</p>
                                        <div className="pt-1">
                                            <img src={selectedImage ? URL.createObjectURL(selectedImage) : selectedBanner.banner} alt="Selected Banner" className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]" />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4] mt-4"
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>URL</p>
                                        <div className="pt-1">
                                            <input
                                                type="text"
                                                name="url"
                                                value={formData.url}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="URL"
                                                onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                                            />
                                        </div>
                                        <div className='pt-8 flex gap-8'>
                                            <button className='bg-[#00549A] rounded-[10px] w-[50%] py-4'>
                                                <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                            </button>
                                            <button
                                                type="button"
                                                className="bg-gray-500 text-white w-[50%] py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                                                onClick={handleExitEditForm}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default HomeBanner;
