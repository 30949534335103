import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
import Blogs from "../../../Components/User/Blogs/Blogs";
 

const BlogPage = () => {

    return (
        <div>
    <Home/>
    <Blogs/>
     
    <Footer/> 
    </div>
    )
}
export default BlogPage;