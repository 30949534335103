import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Logo1 from "../../../../assets/images/calendar-03.png";
import Logo2 from "../../../../assets/images/location-03.png";
import Logo3 from "../../../../assets/images/mail-edit.png";
import arrow1 from "../../../../assets/images/ev.png";
import arrow2 from "../../../../assets/images/ev-1.png";
import logo1 from "../../../../assets/images/c9b148186761267 2.png";
import logo2 from "../../../../assets/images/user-add--01.png";
import logo3 from "../../../../assets/images/user-story.svg";
import { BASE_URL } from '../../../../Utils/Config';
import { useParams, useNavigate } from 'react-router-dom';
import { getLocal } from '../../../../helpers/auth';
import eye from '../../../../assets/images/chat-eye.png';
import logo13 from "../../../../assets/images/st.png";
import "./chat.css";
import fram from "../../../../assets/images/Frame 10.png";
import fcp from "../../../../assets/images/FCPI.png";
import gr from "../../../../assets/images/gr.png";
import send from "../../../../assets/images/send.png";
import us from "../../../../assets/images/us.png";
import { toast } from "react-hot-toast";
import pdficon from "../../../../assets/images/pdf-icon 2.svg";
import { motion, useAnimation, useInView } from 'framer-motion';
import down from "../../../../assets/images/circle-arrow-down-01-sharp.svg"
import { fromZonedTime, toZonedTime } from 'date-fns-tz';


import { parseISO } from 'date-fns';





const Chat = ({ userId }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [speakers, setSpeakers] = useState([]);
    const speakersPerPage = 4;
    const { slug } = useParams();
    const [event, setEvent] = useState(null);
    const [events, setEvents] = useState(null);
    const [singleEvent, setSingleEvent] = useState(null);
    const user_auth = getLocal();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [activeDay, setActiveDay] = useState(1);
    const [showDropdown, setShowDropdown] = useState(false);
    console.log('Type of newMessage:', typeof newMessage);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [latestMultiEvent, setLatestMultiEvent] = useState(null);
    const [showChat, setShowChat] = useState(false);
    const navigate = useNavigate();
    function handleDoubleClick(event) {
        const target = event.target;
        const radio = target.closest('input[type="radio"]');
        if (radio && radio.checked) {
            radio.checked = false;
        }
    }
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const [formData, setFormData] = useState({
        presentation_content: '',
        speaker_delivery: '',
        presentation_duration: '',
        audio_video_quality: '',
        how_did_you_hear: '',
        suggestion: ''
    });
    const [loadingGeneralMessages, setLoadingGeneralMessages] = useState(false);
    const [loadingEventMessages, setLoadingEventMessages] = useState(false);
    // Check if the user is logged in
    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {

                navigate('/login');
            }
        };
        checkAuth();
    }, [navigate]);
    useEffect(() => {
        const fetchEventSpeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/speakers/`);
                if (response.data && Array.isArray(response.data.speakers)) {
                    setSpeakers(response.data.speakers);

                } else {
                    console.error('Invalid data format for speakers:', response.data);
                }
            } catch (error) {
                console.error('Error fetching event speakers:', error);
            }
        };

        fetchEventSpeakers();
    }, [slug]);

    useEffect(() => {
        const fetchGeneralEventSpeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_events/${slug}/speakers/`);
                if (response.data && Array.isArray(response.data.speakers)) {
                    setSpeakers(response.data.speakers);

                } else {
                    console.error('Invalid data format for speakers:', response.data);
                }
            } catch (error) {
                console.error('Error fetching event speakers:', error);
            }
        };

        fetchGeneralEventSpeakers();
    }, [slug]);



    const [day, setDay] = useState();
    const [showSections, setShowSections] = useState({ url: true, chat: false, feedback: true });
    // useEffect(() => {
    //     if (event && event.single_events) {

    //         const singleEvent = event.single_events.find(se => se.day === activeDay);
    //         setSelectedSingleEvent(singleEvent || null);


    //         const currentDate = new Date();
    //         const liveEvent = event.single_events.find(se => {
    //             const eventDate = new Date(se.date);
    //             return eventDate.toDateString() === currentDate.toDateString() && eventDate <= currentDate;
    //         });

    //         if (liveEvent) {
    //             setShowSections(prev => ({ ...prev, chat: true }));
    //         } else {
    //             setShowSections(prev => ({ ...prev, chat: false }));
    //         }
    //     }
    // }, [event, activeDay]);


    // useEffect((day) => {
    // setActiveDay(day);

    //     const singleEvent = event.single_events.find(se => se.day === day);
    //     setSelectedSingleEvent(singleEvent || null);

    //     const currentDate = new Date();

    //     if (singleEvent) {
    //         const eventDate = new Date(singleEvent.date);


    //         const isLiveEvent = eventDate.toDateString() === currentDate.toDateString() && eventDate <= currentDate;


    //         const eventEndTime = new Date(parseTimeString(latestMultiEvent.ending_time));
    //         const bufferEndTime = new Date(eventEndTime.getTime() + 15 * 60 * 1000); 


    //         const shouldShowChat = isLiveEvent && currentDate <= bufferEndTime;
    //         setShowSections(prev => ({ ...prev, chat: shouldShowChat }));
    //     } else {

    //         setShowSections(prev => ({ ...prev, chat: false }));
    //     }
    // }, []);

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsLoggedIn(true);
        }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log("User's current timezone:", userTimezone);

    }, []);



    const handleDayClick = (day) => {
        console.log("Clicked Day:", day);
        setActiveDay(day);

        if (!event || !event.single_events) {
            console.error("Event or single_events is null or undefined.");
            return;
        }

        const singleEvent = event.single_events.find(se => se.day === day);
        setSelectedSingleEvent(singleEvent || null);

        if (!singleEvent) {
            console.error(`No single event found for day ${day}`);
            setShowSections(prev => ({ ...prev, chat: false }));
            setShowFeedbackSection(false);
            return;
        }

        console.log("Single Event Data:", singleEvent);

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(`Client's timezone: ${timezone}`);

        const currentDate = new Date();
        const currentDateTimeInUserTZ = toZonedTime(currentDate, timezone);
        console.log(`Current Date and Time in user's timezone: ${currentDateTimeInUserTZ}`);

        console.log(`Raw Single Event Start Date: ${singleEvent.date}`);
        console.log(`Raw Single Event End Date: ${singleEvent.date}`);

        const eventDateUTC = new Date(singleEvent.date);
        const eventDate = toZonedTime(eventDateUTC, timezone);
        console.log(`Single Event Date in user's timezone: ${eventDate}`);

        const isLiveEvent = eventDate.toDateString() === currentDateTimeInUserTZ.toDateString() && eventDate <= currentDateTimeInUserTZ;

        // Check for existing first_multi_event_start and last_multi_event_end properties
        if (singleEvent.first_multi_event_start && singleEvent.last_multi_event_end) {
            console.log("Raw first_multi_event_start:", singleEvent.first_multi_event_start);
            console.log("Raw last_multi_event_end:", singleEvent.last_multi_event_end);

            const startTimeParts = singleEvent.first_multi_event_start.split(":");
            const endTimeParts = singleEvent.last_multi_event_end.split(":");

            const eventStartTimeIST = new Date(singleEvent.date);
            eventStartTimeIST.setHours(startTimeParts[0], startTimeParts[1], 0);

            const eventEndTimeIST = new Date(singleEvent.date);
            eventEndTimeIST.setHours(endTimeParts[0], endTimeParts[1], 0);

            const eventStartTimeUTC = fromZonedTime(eventStartTimeIST, 'Asia/Kolkata');
            const eventEndTimeUTC = fromZonedTime(eventEndTimeIST, 'Asia/Kolkata');

            const eventStartTimeInUserTZ = toZonedTime(eventStartTimeUTC, timezone);
            const eventEndTimeInUserTZ = toZonedTime(eventEndTimeUTC, timezone);

            console.log(`Event Start Time in user's timezone: ${eventStartTimeInUserTZ}`);
            console.log(`Event End Time in user's timezone: ${eventEndTimeInUserTZ}`);

            const feedbackTimeUTC = new Date(eventEndTimeUTC.getTime() - 20 * 60 * 1000);
            const feedbackTimeInUserTZ = toZonedTime(feedbackTimeUTC, timezone);
            console.log(`Feedback Time in user's timezone: ${feedbackTimeInUserTZ}`);

            const isEventCompleted = currentDateTimeInUserTZ >= eventEndTimeInUserTZ;

            if (currentDateTimeInUserTZ >= feedbackTimeInUserTZ && currentDateTimeInUserTZ <= eventEndTimeInUserTZ) {
                console.log('Feedback Section should be shown within feedback window');
                setShowFeedbackSection(true);
            } else if (isEventCompleted) {
                console.log('Feedback Section should be shown for completed events');
                setShowFeedbackSection(true);
            } else {
                console.log('Feedback Section should NOT be shown');
                setShowFeedbackSection(false);
            }

            if (isLiveEvent && currentDateTimeInUserTZ <= eventEndTimeInUserTZ) {
                console.log('Chat Section should be shown during live event');
                setShowSections(prev => ({ ...prev, chat: true }));
            } else {
                console.log('Chat Section should NOT be shown');
                setShowSections(prev => ({ ...prev, chat: false }));
            }
        } else {
            console.error("No valid multi_event start or end times found.");
            setShowSections(prev => ({ ...prev, chat: false }));
            setShowFeedbackSection(false);
        }
    };







    useEffect(() => {
        if (event && event.single_events && latestMultiEvent) {
            handleDayClick(1);
        }
    }, [event, latestMultiEvent]);

    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {

                navigate('/login');
            }
        };
        checkAuth();
    }, [navigate]);





    useEffect(() => {
        const fetchSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/enroll/${slug}/single-events/`);
                setEvent(response.data);
                console.log('Fetched Event Data:', response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvent();
    }, [slug]);


    useEffect(() => {
        const fetchSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_enroll/${slug}/single-events/`);
                setEvent(response.data);
                console.log('Fetched Event Data:', response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvent();
    }, [slug]);

















    const sendMessage = async (e) => {


        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('Authentication token is missing.');
                toast.error('Authentication token is missing.');
                return;
            }

            const messageData = {
                content: newMessage.trim(),
                event_name: event?.event_name || '',
                timestamp: Date.now()
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            };

            // Debugging logs
            console.log('Sending Message Data:', messageData);

            // Send message to the first endpoint
            try {
                await axios.post(`${BASE_URL}/speaker/send-message/`, messageData, config);
                console.log('Message sent successfully to send-message endpoint.');
                toast.success('Message sent successfully to send-message endpoint.');
            } catch (error) {
                console.error('Error sending message to send-message endpoint:', error.response ? error.response.data : error.message);
                toast.error('An error occurred while sending the message to send-message endpoint');
            }

            // Send message to the second endpoint
            try {
                await axios.post(`${BASE_URL}/speaker/general_send-message/`, messageData, config);
                console.log('Message sent successfully to general_send-message endpoint.');
                toast.success('Message sent successfully to general_send-message endpoint.');
            } catch (error) {
                console.error('Error sending message to general_send-message endpoint:', error.response ? error.response.data : error.message);
                toast.error('An error occurred while sending the message to general_send-message endpoint');
            }

            // Clear message input after sending
            setNewMessage('');

        } catch (error) {
            console.error('Unexpected error:', error);
            toast.error('Unexpected error occurred while sending the message');
        }
    };




    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsLoggedIn(true);
        }
    }, []);



    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };







    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };







    const mapChoices = {
        'Very satisfied': 'VS',
        'Somewhat satisfied': 'SS',
        'Neutral': 'N',
        'Unsatisfied': 'US',
        'Very unsatisfied': 'VU',
        'Email': 'E',
        'Social Media Post': 'S',
        'WhatsApp': 'W',
        'FCPI Website': 'F',
        'Referral': 'R',
        'IDCongress2023': 'I',
        'Other': 'O',
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('Authentication information not found');
                return;
            }

            // Prepare the form data
            const formDataWithMappedChoices = {
                presentation_content: mapChoices[formData.presentation_content] || 'VS',  
                speaker_delivery: mapChoices[formData.speaker_delivery] || 'VS',
                presentation_duration: mapChoices[formData.presentation_duration] || 'VS',
                audio_video_quality: mapChoices[formData.audio_video_quality] || 'VS',
                how_did_you_hear: formData.how_did_you_hear ? mapChoices[formData.how_did_you_hear] : 'O',
                suggestion: formData.suggestion || '',
                event_id: event.id || '',
                singleEvent_day: activeDay || '',
            };
            

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            };


            try {
                await axios.post(`${BASE_URL}/accounts/general_feedback/`, formDataWithMappedChoices, config);
                console.log('General feedback submitted successfully.');
                alert('General feedback submitted successfully.');
                setShowFeedbackForm(false)
            } catch (error) {
                console.error('Error submitting general feedback:', error);
                if (error.response && error.response.data.error === 'You have already submitted feedback for this event.') {
                    alert('You have already submitted feedback for this event.');
                } else {

                }
            }


            try {
                await axios.post(`${BASE_URL}/accounts/feedback/`, formDataWithMappedChoices, config);
                console.log('Event feedback submitted successfully.');
                alert('Event feedback submitted successfully.');
                setShowFeedbackForm(false)
            } catch (error) {
                console.error('Error submitting event feedback:', error);
                if (error.response && error.response.data.error === 'You have already submitted feedback for this event.') {
                    alert('You have already submitted feedback for this event.');
                } else {

                }
            }


            setFormData({
                presentation_content: '',
                speaker_delivery: '',
                presentation_duration: '',
                audio_video_quality: '',
                how_did_you_hear: '',
                suggestion: ''
            });

        } catch (error) {
            console.error('Unexpected error:', error);
            alert('Unexpected error occurred while submitting feedback');
        }
    };







    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    function extractYouTubeId(url) {
        let videoId = '';

        if (url.includes('youtu.be')) {
            videoId = url.split('/').pop();
        } else if (url.includes('youtube.com')) {
            const match = url.match(/[?&]v=([^&]+)/);
            videoId = match && match[1] ? match[1] : '';


            if (!videoId) {
                const path = new URL(url).pathname;
                videoId = path.split('/').pop();
            }
        }

        return videoId;
    }





    const [dropdownVisible, setDropdownVisible] = useState(false);
    const toggleDropdown1 = () => {
        setDropdownVisible(prevState => !prevState);
    }
    const toggleFeedbackForm = () => {
        setShowFeedbackForm(prevState => !prevState);
    };


    const [userCount, setUserCount] = useState(0);

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_enroll/${slug}/single-events/`);
                setEvent(response.data);
                console.log("Event response data:", response.data);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        };

        fetchEventDetails();
    }, [slug]);


    useEffect(() => {
        if (!event || !event.id) return;

        const fetchUserCount = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/events/${slug}/enrollment-count/`);
                setUserCount(response.data.user_count);
            } catch (error) {
                console.error('Error fetching user count for normal event:', error);
            }
        };

        fetchUserCount();
    }, [event, slug]);


    useEffect(() => {
        if (!event || !event.id) return;

        const fetchGeneralUserCount = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/general_events/${slug}/enrollment-count/`);
                setUserCount(response.data.user_count);
            } catch (error) {
                console.error('Error fetching user count for general event:', error);
            }
        };

        fetchGeneralUserCount();
    }, [event, slug]);


    const [selectedSingleEvent, setSelectedSingleEvent] = useState('');




    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!event || !event.id) return; // Ensure event ID is available

        const fetchMessages = async () => {
            setLoading(true); // Start loading
            try {
                // Fetch event-specific messages
                const eventMessagesResponse = await axios.get(`${BASE_URL}/speaker/user/messages/ `, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                // Fetch general messages
                const generalMessagesResponse = await axios.get(`${BASE_URL}/speaker/user/general_messages/ `, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                // Combine both responses
                const eventMessages = eventMessagesResponse.data && Array.isArray(eventMessagesResponse.data) ? eventMessagesResponse.data : [];
                const generalMessages = generalMessagesResponse.data && Array.isArray(generalMessagesResponse.data) ? generalMessagesResponse.data : [];

                const allMessages = [...eventMessages, ...generalMessages];

                setMessages(allMessages);

            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();


        const intervalId = setInterval(fetchMessages, 1000);


        return () => {
            clearInterval(intervalId);
        };
    }, [event]);







    const filteredMessages = messages.filter(message => message.userId === userId);
    const [showFeedbackSection, setShowFeedbackSection] = useState(false);


    const parseTimeString = (timeStr) => {
        const [time, period] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (period === 'PM' && hours !== 12) hours += 12;
        if (period === 'AM' && hours === 12) hours = 0;


        const date = new Date();
        date.setHours(hours, minutes, 0, 0);


        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0);
    };

    useEffect(() => {
        const fetchSingleEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/`);
                setEvents(response.data);

                if (response.data && response.data.single_events && response.data.single_events.length > 0) {
                    setActiveDay(response.data.single_events[0].day);

                    const allMultiEvents = response.data.single_events.flatMap(singleEvent => singleEvent.multi_events);
                    const sortedMultiEvents = allMultiEvents.sort((a, b) => {
                        const aEnd = parseTimeString(a.ending_time);
                        const bEnd = parseTimeString(b.ending_time);
                        return bEnd - aEnd;
                    });

                    console.log('Sorted Multi Events:', sortedMultiEvents);
                    setLatestMultiEvent(sortedMultiEvents[0]);
                }
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvents();
    }, [slug]);

    useEffect(() => {
        const fetchSingleEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_events/${slug}/`);
                setEvents(response.data);

                if (response.data && response.data.single_events && response.data.single_events.length > 0) {
                    setActiveDay(response.data.single_events[0].day);

                    const allMultiEvents = response.data.single_events.flatMap(singleEvent => singleEvent.multi_events);
                    const sortedMultiEvents = allMultiEvents.sort((a, b) => {
                        const aEnd = parseTimeString(a.ending_time);
                        const bEnd = parseTimeString(b.ending_time);
                        return bEnd - aEnd;
                    });

                    console.log('Sorted Multi Events:', sortedMultiEvents);
                    setLatestMultiEvent(sortedMultiEvents[0]);
                }
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvents();
    }, [slug]);

    // useEffect(() => {
    //     const checkFeedbackSection = () => {
    //         if (latestMultiEvent && event) {
    //             const now = new Date();
    //             const endTime = parseTimeString(latestMultiEvent.ending_time);


    //             const feedbackTime = new Date(endTime.getTime() - 20 * 60 * 1000);

    //             console.log('Current Time (UTC):', now.toISOString());
    //             console.log('End Time (UTC):', endTime.toISOString());
    //             console.log('Feedback Time (UTC):', feedbackTime.toISOString());

    //             const eventDate = new Date(event.date);
    //             const isCurrentDayEvent = now.toDateString() === eventDate.toDateString();

    //             if (!isCurrentDayEvent) {

    //                 console.log('Feedback Section should be shown for past events');
    //                 setShowFeedbackSection(true);
    //             } else if (now >= feedbackTime) {

    //                 console.log('Feedback Section should be shown within feedback window for current day event');
    //                 setShowFeedbackSection(true);
    //             } else {
    //                 console.log('Feedback Section should NOT be shown');
    //                 setShowFeedbackSection(false);
    //             }
    //         }
    //     };

    //     checkFeedbackSection();

    //     const intervalId = setInterval(checkFeedbackSection, 10000); // Recheck every 10 seconds

    //     return () => clearInterval(intervalId);
    // }, [latestMultiEvent, event]);


    const formatTime = (time) => {
        // Convert 'HH:MM:SS' to 'HH:MM AM/PM' format
        const [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${period}`;
    };


    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [loadingGeneralAttachments, setLoadingGeneralAttachments] = useState(false);
    const [generalAttachments, setGeneralAttachments] = useState([]);
    const [eventAttachments, setEventAttachments] = useState([]);
    const [loadingEventAttachments, setLoadingEventAttachments] = useState(false);

    useEffect(() => {
        if (!event || !event.slug) return;

        const fetchAttachments = async () => {
            setLoadingGeneralAttachments(true);
            setLoadingEventAttachments(true);

            try {
              
                const generalResponse = await axios.get(`${BASE_URL}/admins/general_attachments/${slug}/`);
                console.log('Fetched general attachments:', generalResponse.data);
                setGeneralAttachments(generalResponse.data);

        
                const eventResponse = await axios.get(`${BASE_URL}/admins/attachments/${slug}/`);
                console.log('Fetched event attachments:', eventResponse.data);
                setEventAttachments(eventResponse.data);
            } catch (error) {
                console.error('Error fetching attachments:', error);
                toast.error("Error fetching attachments");
            } finally {
                setLoadingGeneralAttachments(false);
                setLoadingEventAttachments(false);
            }
        };

        fetchAttachments();
    }, [event]);




    const [singleEventId, setSingleEventId] = useState(null);




    const handleImageClick = async (attachment) => {
        const singleEventId = attachment.single_event;

        try {
            await axios.post(`${BASE_URL}/admins/user-file-interaction/`, {
                attachmentId: attachment.id,
                singleEventId: singleEventId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            handleDownload(attachment.file);
        } catch (error) {
            console.error('Error associating file or downloading:', error.response ? error.response.data : error.message);
        }
    };

    const handleImageClick2 = async (attachment) => {
        const singleEventId = attachment.single_event;

        try {
            await axios.post(`${BASE_URL}/admins/general_user-file-interaction/`, {
                attachmentId: attachment.id,
                singleEventId: singleEventId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            handleDownload(attachment.file);
        } catch (error) {
            console.error('Error associating file or downloading:', error.response ? error.response.data : error.message);
        }
    };

    const handleDownload = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Extract filename from URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const formatDate = (dateString) => {
 
        const [year, month, day] = dateString.split('-');
    
        
        const paddedDay = String(day).padStart(2, '0');
        const paddedMonth = String(month).padStart(2, '0');
    
       
        return `${paddedDay}-${paddedMonth}-${year}`;
    };
    
    // Example usage
    const dateString = '2024-10-05'; 
    const formattedDate = formatDate(dateString);
    console.log(formattedDate);  
    


    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6 '>
                    <div className='bg-white event-box-p8  rounded-[30px]'>
                        <div className='  rounded-[30px]'>

                            <div className='    rounded-[30px]'>
                                {event && (
                                    <>
                                        <div className='flex relative z-10   pt-12'>
                                            <p className='event-name-title '>{event.event_name}</p>

                                        </div>
                                        <p className='event-name-subtitle '>{event.forum_name}</p>
                                        <div className='flex justify-between block-768'>
                                            <div className='flex pt-12 gap-8 block-768'>
                                                <div className='flex relative z-10  gap-8'>
                                                    <div className='flex gap-4'>
                                                        <div>
                                                            <img src={Logo1} alt="Calendar" className="pt-4 " />

                                                        </div>
                                                        {event.days === 1 ? (
                                                            <>
                                                                <div>
                                                                    <p className='text-black pt-2 text-[17px]   mm-0 not-italic font-normal leading-[normal]'>
                                                                        {formatDate(event.date)}
                                                                    </p>
                                                                    {event.single_events && event.single_events.map((singleEvent, index) => (
                                                                        <div key={index} className='rounded-[10px] pt-1 mb-3'>
                                                                            <div className="flex">
                                                                                {singleEvent.first_multi_event_start && singleEvent.last_multi_event_end && (
                                                                                    <div className="flex">
                                                                                        <p className="text-black pt-1 text-[17px]  not-italic font-normal leading-[normal]">
                                                                                            {`${formatTime(singleEvent.first_multi_event_start)} - ${formatTime(singleEvent.last_multi_event_end)}`} (IST)
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className='text-black pt-4 text-[17px] mm-0 not-italic font-normal leading-[normal]'>
                                                                    {`${formatDate(event.start_date)}  -  ${formatDate(event.end_date)}`}
                                                                </p>
                                                            </>
                                                        )}

                                                    </div>

                                                </div>
                                                <div className='border h-[85px] px-4 py-2 rounded-[10px] m-top-10'>
                                                    <div className='flex gap-2 pt-2 '>
                                                        <div className='pt-1'>
                                                            <img src={eye} alt="" />
                                                        </div>
                                                        <div>
                                                            <p className='text-[#00549A] text-[22px]'>{userCount}</p>
                                                        </div>
                                                    </div>
                                                    <p className='text-[#58585A] text-[14px] '>People are currently Watching</p>
                                                </div>
                                            </div>


                                            <motion.div
                                                className=" m-top-10 "
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{
                                                    duration: 2,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <p className=' text-[18px] text-gray-600 font-semibold'>Speakers</p>
                                                <div className='flex pt-2'>
                                                    {speakers.map((speaker, index) => (
                                                        <div key={index} className={`relative ${index > 0 ? 'z-10 -ml-4' : ''}`}>
                                                            <img src={BASE_URL + speaker?.photo} alt={`Speaker ${index + 1}`} className='rounded-[60px] w-16 h-16' />

                                                        </div>
                                                    ))}

                                                </div>
                                            </motion.div>



                                        </div>
                                    </>
                                )}
                                <hr className='mt-8' />

                            </div>

                            <div className=''>
                                <div className="pt-2 ">

                                    <motion.div
                                        className="pt-12"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >
                                        {event && event.single_events.length > 1 && (
                                            event.single_events
                                                .filter((singleEvent) => {
                                                    const today = new Date();
                                                    const eventDate = new Date(event.date);
                                                    const currentDay = new Date(eventDate);
                                                    currentDay.setDate(eventDate.getDate() + (singleEvent.day - 1)); // Adjust for multi-day events

                                                    // Show only current day's button for live events, show all if the event is in the past
                                                    if (today < new Date(event.end_date)) {
                                                        return today.toDateString() === currentDay.toDateString(); // Only show the current day's button
                                                    } else {
                                                        return true; // Show all days if the event is in the past
                                                    }
                                                })
                                                .map((singleEvent, index) => (
                                                    <button
                                                        key={index}
                                                        className={`px-4 py-2 day-text ${activeDay === singleEvent.day ? 'border-b-2 border-[#00549A] text-blue-900' : 'text-custom-color'}`}
                                                        onClick={() => handleDayClick(singleEvent.day)}
                                                    >
                                                        Day {singleEvent.day}
                                                    </button>
                                                ))
                                        )}



                                    </motion.div>


                                </div>

                               

                            </div>
                            <div className='pt-4 '>
                              
                                {(generalAttachments.length > 0 || eventAttachments.length > 0) && (
                                    <div>
                                        <div className='flex justify-end'>
                                            <p className='text-[16px] font-semibold p-2'>Download Attachments</p>
                                            <img src={down} alt="" className='' />
                                        </div>

                                        {loadingGeneralAttachments || loadingEventAttachments ? (
                                            <p>Loading...</p>
                                        ) : (
                                            <>
                                                {generalAttachments.length > 0 && (
                                                    <div>
                                                        <ul className='flex justify-end pt-2'>
                                                            {generalAttachments.map((attachment) => (
                                                                <li key={attachment.id} style={{ margin: '0 10px', width: '50px' }}>
                                                                    <img
                                                                        src={pdficon}
                                                                        alt="File Icon"
                                                                        className='file-icon'
                                                                        onClick={() => handleImageClick2(attachment)}
                                                                        style={{ cursor: 'pointer', width: '100px' }}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                {eventAttachments.length > 0 && (
                                                    <div>
                                                        <ul className='flex justify-end pt-2'>
                                                            {eventAttachments.map((attachment) => (
                                                                <li key={attachment.id} style={{ margin: '0 10px', width: '50px' }}>
                                                                    <img
                                                                        src={pdficon}
                                                                        alt="File Icon"
                                                                        className='file-icon'
                                                                        onClick={() => handleImageClick(attachment)}
                                                                        style={{ cursor: 'pointer', width: '100px' }}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}


                            </div>



                            <motion.div
                                className="w-60 "
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 4 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                                ref={ref}
                            >
                                {showSections.url && (
                                    <div className=''>
                                        {event && event.single_events && event.single_events.map((singleEvent, index) => (
                                            <div key={singleEvent.id} className=' p-2 '>
                                                {activeDay === (index + 1) && singleEvent.youtube_link && (
                                                    <div className="video-responsive pt-12">
                                                        <iframe className='y-video' src={`https://www.youtube.com/embed/${extractYouTubeId(singleEvent.youtube_link)}`}
                                                            frameBorder="0"
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                )}
                                            </div>
                                        ))}


                                    </div>

                                )}

                            </motion.div>

                            {showSections.chat && (
                                <div className='w-40 h-[60%] mob-wid-100'>
                                    <div className='p-2   '>
                                        <div className='bg-white shadow-2xl w-[100%] rounded-[10px]'>
                                            {/* Header */}
                                            <div className='flex gap-8 p-4 pl-6'>
                                                <div>
                                                    <img src={fram} alt="" className='w-[100%]' />
                                                </div>
                                                <div className='pt-1'>
                                                    <img src={fcp} alt="" />
                                                    <div className='flex gap-4'>
                                                        <div className='flex gap-2'>
                                                            <div className='pt-2'>
                                                                <img src={gr} alt="" />
                                                            </div>
                                                            <p className='text-gray-500 text-[16px] font-bold'>Online</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                            <div>

                                            </div>




                                            <div className='flex flex-col h-full'>

                                                <div className="flex-grow p-6 max-h-[37.5vh] overflow-y-auto p-l-10">
                                                    <div>
                                                        {messages.length > 0 ? (
                                                            messages.map((message) => (
                                                                <div
                                                                    key={message.id}
                                                                    className={`flex ${message.author === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
                                                                >
                                                                    <div className='flex gap-6'>
                                                                        <div className={`py-2 px-4 max-w-xs break-words flex justify-end bg-[#00549A] text-white rounded-t-lg rounded-bl-lg`}>
                                                                            {message.content}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="text-center">No messages yet</div>
                                                        )}
                                                    </div>

                                                </div>
                                                <div className="py-4 px-6 relative">
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            sendMessage();
                                                        }}
                                                        className="flex space-x-2"
                                                    >
                                                        <input
                                                            type="text"
                                                            value={newMessage}
                                                            onChange={(e) => setNewMessage(e.target.value)}
                                                            className="flex-grow border border-gray-400 rounded-[10px] px-4 py-3 focus:outline-none"
                                                            placeholder="Type a message..."
                                                        />
                                                        <div
                                                            onClick={sendMessage}
                                                            className="cursor-pointer absolute top-5 right-8 flex items-center justify-center px-4 py-2 rounded-lg"
                                                        >
                                                            <img src={send} alt="Send" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            )}

                            <div className=' clear-both'></div>




                            {showFeedbackSection && (
                                <div className='p-2'>
                                    <p className='submit-f-head'>Submit Feedback Form to Download Certificate</p>
                                    <p className='text-[#58585A] pt-4 text-[18px]'>To download your certificate, please complete the feedback form. Your insights help us improve our programs and services. Once submitted,<br/> you'll be able to access and download your certificate directly from the user dashboard. Thank you for your participation and valuable feedback!</p>
                                    <div className="  pt-6">
                                        <button
                                            className="flex justify-center smooth items-center px-[30px] py-[14px] rounded-[105px] bg-[#1AB78D] "
                                            onClick={toggleFeedbackForm}
                                        >
                                            <p className="text-[16px] not-italic font-normal leading-[normal] text-white">Submit Your Feedback</p>
                                        </button>
                                        <div className='pt-8'>

                                            {showFeedbackForm && (
                                                <form onSubmit={handleSubmit}>

                                                    <motion.div
                                                        className="pt-12"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 4 }}
                                                        transition={{
                                                            duration: 1,
                                                            ease: "easeInOut",
                                                        }}
                                                        ref={ref}
                                                    >
                                                        <div className='pt-12'>
                                                            <div className='flex'>



                                                                <div className='wrapper-table  '>
                                                                    <table>
                                                                        <caption className='visually-hidden'>Feedback on Presentation</caption>
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope='col text-black '></th>
                                                                                {[
                                                                                    'Very satisfied',
                                                                                    'Somewhat satisfied',
                                                                                    'Neutral',
                                                                                    'Unsatisfied',
                                                                                    'Very Unsatisfied'
                                                                                ].map((label, index) => (
                                                                                    <th
                                                                                        key={index}
                                                                                        scope='col'
                                                                                        style={{
                                                                                            backgroundColor: index === 0 ? '#C5E5FF26' :  
                                                                                                index === 1 ? '#1AB78D26' :  
                                                                                                    index === 2 ? '#FFC23326' : 
                                                                                                        index === 3 ? '#EA375126' :  
                                                                                                            '#AE3DAE26' 
                                                                                        }}
                                                                                        className=' '
                                                                                    >
                                                                                        {label}
                                                                                    </th>
                                                                                ))}
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {['Content of the presentation', 'Speakers_delivery', 'Duration of the presentation', 'Quality of audio & video'].map((aspect, aspectIndex) => (
                                                                                <tr key={aspectIndex}>
                                                                                    <th scope='row'>{aspect.replace(/_/g, ' ')}</th>
                                                                                    {[
                                                                                        'Very satisfied',
                                                                                        'Somewhat satisfied',
                                                                                        'Neutral',
                                                                                        'Unsatisfied',
                                                                                        'Very Unsatisfied'
                                                                                    ].map((satisfaction, index) => (
                                                                                        <td key={index}>
                                                                                            <input
                                                                                                type='radio'
                                                                                                name={aspect}
                                                                                                value={satisfaction}
                                                                                                onChange={handleChange}
                                                                                                checked={formData[aspect] === satisfaction}
                                                                                            />
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>

                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className='pt-8'>
                                                            <p className="text-[36px] font-semibold">How did you hear about<br /> How did you hear about FCPI events?</p>
                                                            <div className='pt-6 pb-5'>
                                                                {['Email', 'SocialMediaPost', 'WhatsApp', 'FCPIWebsite', 'Referral', 'Events', 'Other'].map((source, index) => (
                                                                    <p key={index} style={{ marginBottom: '20px' }}>
                                                                        <input
                                                                            type="radio"
                                                                            name="how_did_you_hear"
                                                                            value={source}
                                                                            onChange={handleChange}
                                                                            checked={formData.how_did_you_hear === source}
                                                                        /> {source}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                            <hr />
                                                            <div className='pt-16'>
                                                                <div className='rounded-[15px] bg-[#bfe1fd40] p-8'>
                                                                    <div className='flex gap-f-20'>
                                                                       
                                                                        <div className='w-full'>
                                                                            <p className='text-[36px] font-semibold'>Do you have any suggestion to  make it better next time ?</p>
                                                                            <div className='pt-6 '>
                                                                                <input
                                                                                    type="text"
                                                                                    name="suggestion"
                                                                                    value={formData.suggestion}
                                                                                    placeholder="Enter your suggestion"
                                                                                    className='border rounded-[15px] w-[100%] p-12 '
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <div className='float-right pt-4'>
                                                                                    <button
                                                                                        type="submit"
                                                                                        className=" submit-btn-certificate flex justify-center items-center px-[30px] py-[14px] rounded-[105px] bg-[#1AB78D]"
                                                                                    >
                                                                                        <p className="text-[16px] not-italic font-normal leading-[normal] text-white">Submit Your Feedback</p>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>

                </div>
            </div>
        </div>

    );
};

export default Chat;