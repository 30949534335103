import react from "react";
import Home from "../../../Components/Home/index";
import Board from  "../../../Components/User/About/Board"; 
import Footer from  "../../../Components/Footer/Footer";


const AboutBoardPage = () => {

    return (
        <div>
    <Home/>
   <Board/>
  <Footer/> 
    </div>
    )
}
export default AboutBoardPage;