import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Utils/Config';
import Logo23 from '../../../src/assets/images/Mask group.svg';
import { motion, useAnimation, useInView } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { scroller } from 'react-scroll';
import './Card5.css';

const Card5 = () => {
  const [users, setUsers] = useState([]);
  const [animatedCount, setAnimatedCount] = useState(0);
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false }); // Track view events
  const [userCount, setUserCount] = useState(0);

  // Fetch users and update user count
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/accounts/user-count/`);
        const count = response.data.user_count;
        setUserCount(count);
        console.log("User count:", count);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Start counting animation
  const startCounting = () => {
    let start = 0;
    const increment = Math.ceil(userCount / 100); // Adjust increment as needed
    const interval = setInterval(() => {
      start += increment;
      if (start >= userCount) {
        clearInterval(interval);
        setAnimatedCount(userCount);
      } else {
        setAnimatedCount(start);
      }
    }, 30); // Adjust interval speed
  };

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
      startCounting(); // Trigger counting animation when in view
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isInView, controls, userCount]);

  // Scroll to a specific section
  const handleScroll = () => {
    scroller.scrollTo('cardSection', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className="w-full h-full bg-[#f4f4f4]">
      <div className="container">
        <div className="pt-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="bg-white rounded-[30px] lg:col-span-2">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={controls}
                transition={{ duration: 0.6 }}
                ref={ref}
              >
                <div className="p-10 pl-12 p-m-10">
                  <div className="flex justify-between items-center">
                    <p className="text-black text-start text-[32px] not-italic font-bold leading-[normal]">
                      Specialty Forums
                    </p>
                  </div>

                  <p className="text-[#58585A] text-[18px] speciality-text text-start not-italic font-normal">
                    The Federation of Clinical Pharmacists in India (FCPI) focuses on specialized areas like critical care medicine, infectious diseases, and research & publications, fostering collaboration and knowledge sharing among professionals to enhance skills and improve patient outcomes in these critical fields.
                  </p>
                </div>
              </motion.div>
            </div>

            <motion.div
              className="bg-[#00549A] pall-member rounded-[30px] relative overhiden"
              initial={{ opacity: 0, y: 50 }}
              animate={controls}
              transition={{ duration: 0.6 }}
              ref={ref}
            >
              <div className="flex items-center space-x-2">
                <p className="text-white text-start pleft-14 pttop-10 text-[67px] not-italic font-medium leading-[normal]">
                  {animatedCount}
                </p>
                <FontAwesomeIcon icon={faPlus} className="text-white text-[40px]" /> {/* Plus icon */}
              </div>
              <div className="flex text-star pleft-14 justify-start">
                <p className="text-white text-[17px] not-italic font-normal leading-[normal]">Members </p>
                <p className="text-[#78B6E9] text-[17px] plleft-5 not-italic font-normal leading-[normal]">use our</p>
                <img src={Logo23} alt="Image" className="absolute right-0 top-30 transform -translate-y-1/2" />
              </div>
              <p className="text-[#78B6E9] pleft-14 relative z-10 text-start text-[17px] not-italic font-normal leading-[normal] pbm-20">
                educational programs
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card5;
