import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { BASE_URL } from '../../../../Utils/Config';
import logo3 from "../../../../assets/images/user-story.svg";
import menu2 from "../../../../assets/images/menu-02.svg";
import acc from "../../../../assets/images/dashboard-circleblack.svg";
import acc1 from "../../../../assets/images/camera-videoblack.svg";
import acc2 from "../../../../assets/images/calendar-01black.svg";
import acc3 from "../../../../assets/images/logout.png";
import menu from "../../../../assets/images/Mneu.png";
import logo from "../../../../assets/images/FCPI Logo 1.svg";
import acc5 from "../../../../assets/images/download-square-02black.svg";
import gif from "../../../../assets/images/giftblack.svg";
import close from "../../../../assets/images/cancel-01.png";
import "./Navbar.css";

const Navbar = () => {
    const [isMobileView, setIsMobileView] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAboutDropdown, setShowAboutDropdown] = useState(false);
    const [showBlogDropdown, setShowBlogDropdown] = useState(false);
    const [showSpecialityDropdown, setShowSpecialityDropdown] = useState(false);
    const [forums, setForums] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [isAboutDropdownHovered, setIsAboutDropdownHovered] = useState(false);
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);
    const [showSpecialtyList, setShowSpecialtyList] = useState(false);
    const [toggleIcon, setToggleIcon] = useState(true); // Initialized to true

    useEffect(() => {
        // Check if it's a mobile view based on window width
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1279.5); // Adjust as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleDropdownMouseEnter = () => {
        setIsAboutDropdownHovered(true);
    };
    const handleSpecialtyMouseLeave = () => {
        setShowSpecialtyList(false);
    };
    const handleSpecialtyMouseEnter = () => {
        setShowSpecialtyList(true);
    };

    const handleDropdownMouseLeave = () => {
        setIsAboutDropdownHovered(false);
    };

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };
    const handleAboutMouseEnter = () => {
        setIsAboutDropdownHovered(true);
    };

    const handleAboutMouseLeave = () => {
        setIsAboutDropdownHovered(false);
    };
    const handleAboutLinkClick = () => {
        setShowAboutDropdown(!showAboutDropdown);
    };
    const handleBlogLinkClick = () => {
        setShowBlogDropdown(!showBlogDropdown);
    };
    const handleSpecialityClick = () => {
        setShowSpecialityDropdown(!showSpecialityDropdown);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/');
    };
    const handleAccountMouseEnter = () => {
        setIsAccountDropdownOpen(true);
    };
    const handleMenuMouseEnter = () => {
        setIsMenuDropdownOpen(true);
    };
    const handleMenuMouseLeave = () => {
        setIsMenuDropdownOpen(false);
    };
    const toggleAccountDropdown = () => {
        setIsAccountDropdownOpen(!isAccountDropdownOpen);
    };
    const toggleMenuDropdown = () => {
        setIsMenuDropdownOpen(!isMenuDropdownOpen);
    };
    const handleAccountMouseLeave = () => {
        setIsAccountDropdownOpen(false);
    };
    useEffect(() => {
        const fetchForums = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forums/`);
                setForums(response.data);
            } catch (error) {
                console.error("Error fetching forums:", error);
            }
        };

        fetchForums();
    }, []);

    const handleIconToggle = () => {
        setShowDropdown(!showDropdown);
        setToggleIcon(!toggleIcon);
    };
    const [isBlogDropdownHovered, setIsBlogDropdownHovered] = useState(false);
    const handleBlogMouseEnter = () => {
        setIsBlogDropdownHovered(true);
    };

    const handleBlogMouseLeave = () => {
        setIsBlogDropdownHovered(false);
    };
    const handleBlogDropdownMouseEnter = () => {
        setIsBlogDropdownHovered(true);
    };

    const handleBlogDropdownMouseLeave = () => {
        setIsBlogDropdownHovered(false);
    };

    return (
        <div className=''>
            <div className="w-full h-full">
                <div className="flex m-block-menu p-4 bg-white pt-4 justify-between">

                    <div className=" w-full h-full ">
                        <div className="flex m-block-menu p-4 bg-white pt-4 just-end">
                            <div className='flex m-block-menu prr-14 p-rm-0 mob-width-100 bg-white rounded-[30px] nav-shadow mb-pad-0'>



                                {isMobileView ? (
                                    <div className="mobile-only">
                                        <div className='flex justify-between'>
                                            <div>
                                                <Link to="/">  <img src={logo} alt="Logo" className="logo-fcpi" /> </Link>
                                            </div>
                                            <div>
                                                <button className="ellipsis-button" onClick={handleIconToggle}>
                                                    <img
                                                        src={toggleIcon ? menu : close}
                                                        alt="Menu"
                                                        className={`menu-image ${toggleIcon ? 'rotate-open' : 'rotate-close'}`}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`bg-white menu-mobile ${showDropdown ? 'dropdown-animation' : ''}`}>
                                            <div className="dropdown-menu text-start">
                                                <ul className="mobile-menuul">
                                                    <li className='drop-down-menu'>
                                                        <div>
                                                            <Link to="#" className="about-us-button" onClick={handleAboutLinkClick}>
                                                                About Us <FontAwesomeIcon icon={showAboutDropdown ? faAngleUp : faAngleDown} />
                                                            </Link>


                                                            <div className={`dropdown-menu-wrapper ${showAboutDropdown ? 'dropdown-animation' : ''}`}>
                                                                <div className="dropdown-menu p-6">
                                                                    <ul>
                                                                        <li>
                                                                            <Link to="/about-fcpi">
                                                                                <p className="menutext font-normal px-3 py-1">About FCPI</p></Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/board-of-directors">
                                                                                <p className="menutext font-normal px-3 py-1">Board of Directors</p></Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/forum-members">
                                                                                <p className="menutext font-normal px-3 py-1">Forum Members</p></Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/committees">
                                                                                <p className="menutext font-normal px-3 py-1">Committees</p></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <Link to="#" className="" onClick={handleSpecialityClick}>
                                                                Specialty Forums <FontAwesomeIcon icon={showSpecialityDropdown ? faAngleUp : faAngleDown} />
                                                            </Link>

                                                            <div className={`dropdown-menu-wrapper ${showSpecialityDropdown ? 'dropdown-animation' : ''}`}>
                                                                <div className="dropdown-menu p-6">
                                                                    <ul>
                                                                        {forums.map((forum) => (
                                                                            <li key={forum.id}>
                                                                                <Link to={`/speciality-forum/${forum.slug}`}>
                                                                                    <p className="menutext font-normal px-3 py-1">
                                                                                        {forum.title}
                                                                                    </p>
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <Link to="/events">Events</Link>
                                                    </li>





                                                    <li className='drop-down-menu'>
                                                        <div>
                                                            <Link to="#" className="about-us-button" onClick={handleBlogLinkClick}>
                                                                Media Center <FontAwesomeIcon icon={showBlogDropdown ? faAngleUp : faAngleDown} />
                                                            </Link>

                                                            <div className={` dropdown-menu-wrapper ${showBlogDropdown ? 'dropdown-animation' : ''}`}>
                                                                <div className="dropdown-menu p-6">
                                                                    <ul>
                                                                        <li>
                                                                            <Link to="/blogs">Blogs</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/podcasts">Podcast</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/newsletters">Newsletter</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <Link to="/gallery">Gallery</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact">Contact Us</Link>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className=' flex gap-8 pr-2 relative justify-center'>
                                            <div>
                                                <button className='myac-btn-dashboard' onMouseEnter={handleMenuMouseEnter} onMouseLeave={handleMenuMouseLeave} onClick={toggleMenuDropdown} >
                                                    User Menu
                                                    <img className='menu2' src={menu2} alt="" />
                                                </button>
                                                {isMenuDropdownOpen && (
                                                    <div className={`menu-list show`} onMouseEnter={handleMenuMouseEnter} onMouseLeave={handleMenuMouseLeave}>
                                                        <ul className="drop-menu">
                                                            <li className='flex'>
                                                                <Link to="/dashboard">
                                                                    <img src={acc} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}> Dashboard</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/live">
                                                                    <img src={acc1} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Live</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/upcoming">
                                                                    <img src={acc2} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}> Upcoming events</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/past">
                                                                    <img src={acc2} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}> Attended Events</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/download">
                                                                    <img src={acc5} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}> Downloads</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/certificate">
                                                                    <img src={gif} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}> Certificates</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/points">
                                                                    <img src={acc2} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}> Points</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <div
                                                                    className='flex items-center   py-1 px-3 rounded cursor-pointer'
                                                                    onClick={handleLogout}
                                                                >
                                                                    <img src={acc3} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Logout</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                <button className='myac-btn-dashboard' onMouseEnter={handleAccountMouseEnter} onMouseLeave={handleAccountMouseLeave} onClick={toggleAccountDropdown}>
                                                    My Account
                                                    <img src={logo3} alt="" />
                                                </button>
                                                {isAccountDropdownOpen && (
                                                    <div className={`account-list show`} onMouseEnter={handleAccountMouseEnter} onMouseLeave={handleAccountMouseLeave}>
                                                        <ul className="drop-menu">
                                                            <li className='flex'>
                                                                <Link to="/points">
                                                                    <img src={acc} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>My Points</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/profile">
                                                                    <img src={acc1} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>My Profile</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <Link to="/password">
                                                                    <img src={acc2} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Change Password</p>
                                                                </Link>
                                                            </li>
                                                            <li className='flex'>
                                                                <div
                                                                    className='flex items-center   py-1 px-3 rounded cursor-pointer'
                                                                    onClick={handleLogout}
                                                                >
                                                                    <img src={acc3} alt="" />
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Logout</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>


                                ) : (
                                    <>

                                        <Link to="/">
                                            <div className="justify-center items-center px-11 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white">
                                                <p className="text-[16px] not-italic font-normal leading-[normal]" >Home</p>
                                            </div>
                                        </Link>
                                        <div className="justify-center items-center px-8 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white about-us-button" onMouseEnter={handleAboutMouseEnter}
                                            onMouseLeave={handleAboutMouseLeave}>
                                            <p className="text-[16px] not-italic font-normal leading-[normal]"  >About Us</p>
                                            {isAboutDropdownHovered && (
                                                <div
                                                    className={`aboutuser-list show`}
                                                    onMouseEnter={handleDropdownMouseEnter}
                                                    onMouseLeave={handleDropdownMouseLeave}
                                                >
                                                    <ul className="drop-menu">
                                                        <li className=" ">
                                                            <Link to="/about-fcpi">
                                                                <p className="text-[14px] not-italic px-3 py-1    font-normal leading-[normal]" style={{ display: "block" }}>About FCPI</p>
                                                            </Link>
                                                        </li>
                                                        <li className=" ">
                                                            <Link to="/board-of-directors">
                                                                <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Board of Directors</p>
                                                            </Link>
                                                        </li>
                                                        <li className="">
                                                            <Link to="/forum-members">
                                                                <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Forum Members</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/committees">
                                                                <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Committees</p></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            className={`relative justify-center items-center px-11 py-[16px] rounded-[105px] bg-${location.pathname.includes("/speciality-forum")
                                                ? "[#00549A]"
                                                : "white"
                                                } text-${location.pathname.includes("/speciality-forum")
                                                    ? "white"
                                                    : "black"
                                                } hover:bg-[#00549a] hover:text-white`}
                                            onMouseEnter={handleSpecialtyMouseEnter}
                                            onMouseLeave={handleSpecialtyMouseLeave}
                                        >
                                            <p className="text-[16px] not-italic font-normal leading-[normal]">
                                                Specialty Forums
                                            </p>
                                            {showSpecialtyList && (
                                                <div className="forumuser-list show">
                                                    <ul className="drop-menu">
                                                        {forums.map((forum) => (
                                                            <li key={forum.id}>
                                                                <Link to={`/speciality-forum/${forum.slug}`}>
                                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]">
                                                                        {forum.title}
                                                                    </p>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>

                                        <Link to="/events">
                                            <div className="justify-center items-center px-11 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white">
                                                <p className="text-[16px] not-italic font-normal leading-[normal]" >Events</p>
                                            </div>
                                        </Link>
                                        <Link to=""
                                            className="justify-center items-center px-11 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white"
                                            onMouseEnter={handleBlogMouseEnter}
                                            onMouseLeave={handleBlogMouseLeave}
                                        >
                                            <p className="text-[16px] not-italic font-normal leading-[normal]">Media Center</p>
                                            {isBlogDropdownHovered && (
                                                <div
                                                    className={`bloguser-list show`}
                                                    onMouseEnter={handleBlogDropdownMouseEnter}
                                                    onMouseLeave={handleBlogDropdownMouseLeave}
                                                >
                                                    <ul className="drop-menu">
                                                        <li className="">
                                                            <Link to="/blogs">
                                                                <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Blogs</p>
                                                            </Link>
                                                        </li>
                                                        <li className="">
                                                            <Link to="/podcasts">
                                                                <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal] " style={{ display: "block" }}>Podcast</p>
                                                            </Link>
                                                        </li>
                                                        <li className="">
                                                            <Link to="/newsletters">
                                                                <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Newsletter</p>
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            )}
                                        </Link>
                                        {/* <Link to="/blogs">
                                            <div className="justify-center items-center px-11 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white">
                                                <p className="text-[16px] not-italic font-normal leading-[normal]"  >Blogs</p>
                                            </div>
                                        </Link> */}

                                        <Link
                                            to="/gallery"
                                            className="justify-center items-center px-11 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white"
                                        >
                                            <div>
                                                <p className="text-[16px] not-italic font-normal leading-[normal]">Gallery</p>
                                            </div>
                                        </Link>

                                        <Link to="/contact">
                                            <div className="justify-center items-center px-11 py-[16px] rounded-[105px] bg-white hover:bg-[#00549a] text-[#222] hover:text-white">
                                                <p className="text-[16px] not-italic font-normal leading-[normal]"  >Contact Us </p>
                                            </div>
                                        </Link>




                                    </>
                                )}

                            </div>

                            <div className=' relative h-mob-nav '>
                                <button className='bg-[#1AB78D] my-account-btn' onMouseEnter={handleAccountMouseEnter} onMouseLeave={handleAccountMouseLeave} onClick={toggleAccountDropdown}>
                                    <div className='flex gap-4'>
                                        <p className='text-[color:var(--White,#FFF)] text-[17px] not-italic font-semibold leading-[normal]'>My Account</p>
                                        <img src={logo3} alt="" />
                                    </div>
                                </button>
                                {isAccountDropdownOpen && (
                                    <div className={`account-list show`} onMouseEnter={handleAccountMouseEnter} onMouseLeave={handleAccountMouseLeave}>
                                        <ul className="drop-menu">
                                            <li className='flex'>
                                                <Link to="/points">
                                                    <img src={acc} alt="" />
                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>My Points</p>
                                                </Link>
                                            </li>
                                            <li className='flex'>
                                                <Link to="/profile">
                                                    <img src={acc1} alt="" />
                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>My Profile</p>
                                                </Link>
                                            </li>
                                            <li className='flex'>
                                                <Link to="/password">
                                                    <img src={acc2} alt="" />
                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Change Password</p>
                                                </Link>
                                            </li>
                                            <li className='flex'>
                                                <div
                                                    className='flex items-center   py-1 px-3 rounded cursor-pointer'
                                                    onClick={handleLogout}
                                                >
                                                    <img src={acc3} alt="" />
                                                    <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: "block" }}>Logout</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;