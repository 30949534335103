import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from "../../../Utils/Config";
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

const Status = () => {
    const { userId } = useParams();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        userrole: '',
        status: '',
        image: ''
    });

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/singleuser/${userId}/`);
                const userData = response.data;

                if (userData && Object.keys(userData).length > 0) {
                    setFormData({
                        first_name: userData.user.first_name || '',
                        last_name: userData.user.last_name || '',
                        email: userData.user.email || '',
                        phone: userData.user.phone || '',
                        userrole: userData.user.userrole || '',
                        status: userData.user.status || '',
                        image: userData.profile.image || ''
                    });
                } else {
                    toast.error('User profile data is missing');
                }
            } catch (error) {
                toast.error('Error fetching user profile');
            }
        };

        fetchUserProfile();
    }, [userId]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            status: e.target.value
        });
    };

    const handleSubmit = async () => {
        try {
            if (!userId) {
                console.error("User ID is undefined");
                return;
            }
           
            await axios.put(`${BASE_URL}/accounts/users/${userId}/status/`, { status: formData.status });
             alert('Status updated successfully');  
        } catch (error) {
            console.error('Error updating status:', error);
             alert('Failed to update status');
        }
    };
    const navigate = useNavigate();
    const handleExitEditForm = () => {

        navigate(-1);
    };
 
    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[26px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Status</p>
                <hr className='mt-6' />
                <div className='flex'>
                    <div>
                        <div className='flex gap-[13.2rem] pt-8'>
                            <div>
                                <p className='text-[color:var(--Black,#222)] pl-12 text-[18px] not-italic font-medium leading-[25px] tracking-[-0.08px]'>Current Status</p>
                                <div className='pt-2 pl-12'>
                                    <button className='px-4 py-2 rounded-[30px]' style={{ background: "rgba(26, 183, 141, 0.10)" }}>
                                        <p className='text-[#1AB78D] text-[14px] not-italic font-semibold leading-[17px]'>{formData.status}</p>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[25px] tracking-[-0.08px]'>User Type</p>
                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[25px] tracking-[-0.07px]'>{formData.userrole}</p>
                            </div>
                            <div>
                                <p className='text-[color:var(--Black,#222)] pl-12 text-[18px] not-italic font-medium leading-[25px] tracking-[-0.08px]'>Change Status</p>
                                <div className="relative pt-2 pl-12">
                                    <select
                                        value={formData.status || ''}
                                        onChange={handleInputChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-2 w-full bg-[#F4F4F4]"
                                    >
                                        <option value="" disabled>Select User Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end gap-4 pt-8'>
                    <div>
                        <button onClick={handleExitEditForm} className='rounded-[6px] px-8 py-3' style={{ border: "1px solid var(--Gray, #58585A)" }}>
                            <p className='text-[color:var(--Gray,#58585A)] text-center text-[18px] not-italic font-medium leading-[normal]'>Cancel</p>
                        </button>
                    </div>
                    <div>
                        <button  className='rounded-[6px] px-8 py-3 bg-[#00549A]' onClick={handleSubmit}>
                            <p className='text-white text-center text-[18px] not-italic font-medium leading-[normal]'>Update</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Status;
