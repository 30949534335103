import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/add-board.svg";
import imag1 from "../../../assets/images/manage-board.svg";
import Icon from "../../../assets/images/Icon frame.png";
import { Link } from "react-router-dom";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import { useNavigate } from 'react-router-dom';




const BoardMember = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(false);
    const [mailingAddressActive, setmailingAddressActive] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        board: '',
        members: [],

    });
    const [members, setMembers] = useState([]);
    const [Boardexclude, setBoardExclude] = useState([]);
    const [memberExist, setMemberExist] = useState([]);

    const [BoardsWithMembers, setBoardsWithMembers] = useState([]);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.title]: e.target.value });
    };
    const [Board, setBoard] = useState([]);
    const [boardMember, setBoardMember] = useState([]);

    const [boards, setBoards] = useState([]);


    useEffect(() => {
        const fetchBoards = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/boards_get/`);

                setBoards(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching board:', error);
            }
        };

        const fetchMembers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/members/`);
                setMembers(response.data);
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };
        const fetchBoardmember = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/boards_get_member/`);
                setBoardMember(response.data);
            } catch (error) {
                console.error('Error fetching board:', error);
            }
        };

        fetchBoards();
        fetchBoardmember();
        fetchMembers();

    }, []);

    const [memberList, setMemberList] = useState(false);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedMembers1, setSelectedMembers1] = useState([]);


    const fetchBoardExclude = async (boardId) => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/members_exclude/${boardId}/`);
            setFilteredMembers(response.data);
            setBoardExclude(response.data);
        } catch (error) {
            console.error('Error fetching board excluded members:', error);
        }
    };
    const handleRemoveMember1 = (memberId) => {
        setSelectedMembers(prevSelected => prevSelected.filter(id => id !== memberId));
    };


    const handleRemoveMember = async (boardId, memberId) => {
        try {

            const updatedBoardMembers = { ...boardMembers };
            updatedBoardMembers[boardId] = updatedBoardMembers[boardId].filter(id => id !== memberId);
            setBoardMembers(updatedBoardMembers);


            setMembers(prevMembers => prevMembers.filter(member => member.id !== memberId));

            setSelectedMembers(prevSelected => prevSelected.filter(id => id !== memberId));


            await axios.put(
                `${BASE_URL}/admins/boardmembers/${boardId}/update/`,
                { deleted_members: [memberId] }
            );


            await fetchMembers();



        } catch (error) {
            console.error('Error removing member:', error);
            alert('Failed to remove member. Please try again.');
        }
    };




    const fetchMembers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/boardmembers/`);
            setMembers(response.data);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };


    useEffect(() => {
        fetchMembers();
    }, []);








    const handleMemberSearch = (value) => {
        const filtered = members.filter((member) =>
            member.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredMembers(filtered);

        if (filtered.length === 1) {
            setSelectedMembers([filtered[0].id]);
        }
    };

    const [existingMembers, setExistingMembers] = useState([]);






    const handleUpdateMembers = async () => {
        try {
            const response = await axios.put(
                `${BASE_URL}/admins/boardmembers/${selectedBoardId}/update/`,
                { members: selectedMembers, deleted_members: selectedMembers1 }
            );
            console.log('Updated Members:', response.data);
            alert('Members updated successfully!');
            await handleViewMembers(selectedBoardId);

            setSelectedMembers([]);
            setSelectedMembers1([]);
        } catch (error) {
            console.error('Error updating members:', error);
            alert('Failed to update members. Please try again.');
        }
    };






    const handleCheckboxChange = (e, memberId) => {
        const isChecked = e.target.checked;
        if (isChecked) {

            setSelectedMembers(prevSelected => [...prevSelected, memberId]);

            setFilteredMembers(prevFiltered => prevFiltered.filter(member => member.id !== memberId));
        } else {

            setSelectedMembers(prevSelected => prevSelected.filter(id => id !== memberId));

            const memberToAddBack = members.find(member => member.id === memberId);
            if (memberToAddBack) {
                setFilteredMembers(prevFiltered => [...prevFiltered, memberToAddBack]);
            }
        }
    };


    const [boardMembers, setBoardMembers] = useState({});





    const [membersForBoard, setMembersForBoard] = useState([]);

    const handleViewMembers = async (boardId) => {
        console.log('View Members clicked for boardId:', boardId);
        try {
            const response = await axios.get(`${BASE_URL}/admins/boardmembers/${boardId}/`);
            console.log('Response Data:', response.data);

            const membersForBoard = response.data.map(item => item.member);
            console.log("membersForboard", membersForBoard)
            setBoardMembers(prevState => ({
                ...prevState,
                [boardId]: membersForBoard
            }));
            setSelectedBoardId(boardId);

            // Update membersForForum state
            setMembersForBoard(membersForBoard);

            setShowMembers(prevShowMembers => !prevShowMembers);
        } catch (error) {
            console.error('Error fetching board members:', error);
        }
    };


    const [showMembers, setShowMembers] = useState(false);





    const [selectedBoardId, setSelectedBoardId] = useState(null);

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
        setFormData(prevFormData => ({
            ...prevFormData,
            image: imageFile
        }));
    };


    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { board, members } = formData;

            const existingMembers = boardMembers[board] || [];
            const duplicateMembers = selectedMembers.filter(memberId => existingMembers.includes(memberId));

            if (duplicateMembers.length > 0) {
                alert('One or more selected members are already part of the board.');
                return;
            }

            const response = await axios.post(`${BASE_URL}/admins/boardmembers/`, { board, members: selectedMembers });
            console.log(response.data);
            alert('Members added successfully!');


            const updatedBoardResponse = await axios.get(`${BASE_URL}/admins/boards/`);
            setBoards(updatedBoardResponse.data);

            setSelectedMembers([]);
        } catch (error) {
            console.error('Error adding members:', error);
            alert('Failed to add members. Please try again.');
        }
    };

    const handleRemoveSelectedMember1 = (memberId) => {
        console.log('Deleting member with ID:', memberId);

        setSelectedMembers(prevSelected => prevSelected.filter(id => id !== memberId));

        console.log('Before deletion:', boardMembers);


        const updatedBoardMembers = { ...boardMembers };
        updatedBoardMembers[selectedBoardId] = updatedBoardMembers[selectedBoardId].filter(member => member.id !== memberId);
        setBoardMembers(updatedBoardMembers);

        console.log('After deletion:', updatedBoardMembers);

        const memberToAddBack = membersForBoard.flatMap(boardMembers => boardMembers).find(member => member.id === memberId);
        if (memberToAddBack) {
            setFilteredMembers(prevFilteredMembers => [...prevFilteredMembers, memberToAddBack]);
        } else {
            console.log('Member not found:', memberId);
        }
    };





    const handleRemoveSelectedMember = (memberId) => {

        setSelectedMembers(prevSelected => prevSelected.filter(id => id !== memberId));

        setFilteredMembers(prevFiltered => {
            const removedMember = members.find(member => member.id === memberId);
            if (removedMember) {
                return [...prevFiltered, removedMember];
            } else {
                return prevFiltered;
            }
        });
    };


    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };


    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>
                    Add Board Member
                </p>
                <div className='flex pt-12 gap-[12rem] p-8'>
                <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>
                                    Manage Board Member
                                </p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>
                                    Add Board Member
                                </p>
                            </div>
                        </button>
                    </div>
                 
                </div>
                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <div className="  relative">
                                    <div className="relative pt-2">
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Select Board </p>
                                        <div className="relative pt-2">
                                        <select
                                            className="border  border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            value={formData.board}
                                            onChange={(e) => setFormData({ ...formData, board: e.target.value })}
                                        >
                                            <option value="" className=' ' disabled>Select a board</option>
                                            {boards.map(board => (
                                                <option key={board.id} value={board.id}>{board.title}</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div className=' pt-8'>
                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium  leading-[24px]'>Members</p>
                                    <div className="relative pt-2">
                                        <input
                                            type="text"
                                            name="member_search"
                                            value={selectedMember}
                                            onChange={(e) => {
                                                handleMemberSearch(e.target.value);
                                                setSelectedMember(e.target.value);
                                                handleChange(e);
                                            }}
                                            onClick={() => {
                                                if (!memberList) {
                                                    setMemberList(true);
                                                }
                                            }}
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Search for a member"
                                        />
                                        <div className="bg-white border border-gray-400 rounded-[6px] w-full mt-1 z-10" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            {filteredMembers.map(member => (
                                                <div key={member.id} className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200">
                                                    <input
                                                        type="checkbox"
                                                        id={member.id}
                                                        value={member.id}
                                                        checked={selectedMembers.includes(member.id)}
                                                        onChange={(e) => handleCheckboxChange(e, member.id)}
                                                        className="mr-2"
                                                    />
                                                    <label htmlFor={member.id}>{member.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='  pt-16'>
                                    {selectedMembers.map(memberId => (
                                        <div key={memberId} className="pt-2 flex relative">
                                            <img src={members.find(member => member.id === memberId)?.image} alt="" className='absolute left-6 top-4 w-[6%] rounded-[30px]' />
                                            <div
                                                className="border border-gray-400 rounded-[6px]  pl-20  py-4 w-full bg-[#F4F4F4] flex items-center"
                                            >
                                                {members.find(member => member.id === memberId)?.name}
                                                <img
                                                    src={del}
                                                    alt=""
                                                    className='ml-auto cursor-pointer pr-8 '
                                                    onClick={() => handleRemoveMember1(memberId)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
                {mailingAddressActive && (
                    <div className='pt-4'>
                        <div className='bg-gray-100 py-4 w-full flex p-6 gap-[21.5rem]'>
                            <p className='text-[color:var(--Gray,#58585A)] pl-2 text-[18px] not-italic font-semibold leading-[normal]'>Board Name</p>
 
                        </div>
                        {boardMember.map(board => (
                            <div key={board.id} className=" justify-between items-center p-4 border border-gray-200 bg-white mb-2">
                                <div className='flex   '>

                                    <p className="text-lg pt-3 font-semibold w-[100%]">{board.title}</p>
                                    <div className='flex justify-end w-[40%] '>

                                        <button className="bg-[#1AB78D] text-white font-bold p-4  rounded" onClick={() => handleViewMembers(board.id)}>View Members</button>

                                    </div>
                                </div>
                                <div>
                                    {showMembers && selectedBoardId === board.id && (
                                        <div className=' flex gap-8 '>
                                            <div className='memberboard'>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Members List</p>
                                                {Array.isArray(selectedMembers) && selectedMembers.map(memberId => {
                                                    const selectedMember = members.find(member => member.id === memberId);
                                                    return (
                                                        <div key={memberId} className="pt-2 flex relative memberboard">
                                                            <div>
                                                                <img src={selectedMember?.image} alt="" className="absolute left-6 top-4 w-10 rounded-full  " />
                                                            </div>
                                                            <div className="border border-gray-400 rounded-[6px] pl-20 pr-20 py-4 w-[100%] bg-[#F4F4F4] flex items-center relative">
                                                                <p className="text-lg font-semibold ">{selectedMember?.name}</p>
                                                                <button
                                                                    onClick={() => handleRemoveMember(board.id, memberId)} // Call handleRemoveMember directly
                                                                    className="ml-auto cursor-pointer pr-2 absolute right-4 top-1/2 transform -translate-y-1/2"
                                                                >
                                                                    <img src={del} alt="Delete" className="h-6 w-6" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {membersForBoard.map((boardMembers) =>
                                                    boardMembers.map((member) => {
                                                        const existingMember = members.find((m) => m.id === member.id);
                                                        if (!existingMember) return null;
                                                        return (
                                                            <div key={existingMember.id} className="pt-2 flex justify-between relative memberboard">
                                                                <div>
                                                                    <img src={existingMember.image} alt="" className="absolute left-6 top-4 w-10 rounded-full" />
                                                                </div>
                                                                <div className="border border-gray-400 rounded-[6px] pl-20 pr-20 py-4 w-[100%] bg-[#F4F4F4] flex items-center relative">
                                                                    <p className="text-lg font-semibold">{existingMember.name}</p>
                                                                    <button
                                                                        onClick={() => handleRemoveMember(board.id, existingMember.id)} // Call handleRemoveMember directly
                                                                        className="ml-auto cursor-pointer pr-2 absolute right-4 top-1/2 transform -translate-y-1/2"
                                                                    >
                                                                        <img src={del} alt="Delete" className="h-6 w-6" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                )}
                                                <div className="flex justify-center mt-4">
                                                    <button className="bg-[#00549A] text-white font-bold py-2 px-4 rounded" onClick={handleUpdateMembers}>Update Members</button>
                                                </div>
                                            </div>
                                            <div className='w-[40%]'>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Add Members</p>
                                                <div className="relative pt-2">
                                                    <input
                                                        type="text"
                                                        name="member_search"
                                                        value={selectedMember}
                                                        onChange={(e) => {
                                                            handleMemberSearch(e.target.value);
                                                            setSelectedMember(e.target.value);
                                                        }}
                                                        onClick={() => {
                                                            if (!memberList) {
                                                                setMemberList(true);
                                                            }
                                                        }}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        placeholder="Search for a member"
                                                    />
                                                    <div className="bg-white border border-gray-400 rounded-[6px] w-full mt-1 z-10" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                        {filteredMembers
                                                            .filter(member => !membersForBoard.flat().some(displayedMember => displayedMember.id === member.id))
                                                            .map(member => (
                                                                member && member.name && (
                                                                    <div key={member.id} className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200">

                                                                        <input
                                                                            type="checkbox"
                                                                            id={member.id}
                                                                            value={member.id}
                                                                            checked={selectedMembers.includes(member.id)}
                                                                            onChange={(e) => handleCheckboxChange(e, member.id)}
                                                                            className="mr-2"
                                                                        />
                                                                        <label htmlFor={member.id}>{member.name}</label>
                                                                    </div>
                                                                )
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}




                                </div>
                            </div>
                        ))}

                    </div>
                )}

            </div>
        </div>
    );
};
export default BoardMember;