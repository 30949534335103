import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
import Gallery from "../../../Components/User/Gallery/Gallery";
 

const GalleryPage = () => {

    return (
        <div>
    <Home/>
    <Gallery/>
     
    <Footer/> 
    </div>
    )
}
export default GalleryPage;