import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/add-news.svg";
import imag1 from "../../../assets/images/manage-news.svg";
import Icon from "../../../assets/images/Icon frame.png";
import { Link } from "react-router-dom";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import ReactQuill from 'react-quill';
import parse from 'html-react-parser';





const News = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);

    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedForum, setSelectedForum] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [banners, setBanners] = useState([]);
    const [formData, setFormData] = useState({

        text: '',
        date: '',
    });
    const [news, setNews] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };



    const fetchNews = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/news/`);
            setNews(response.data);
            console.log("text", response.data)
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };
    useEffect(() => {
        fetchNews();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('text', formData.text);
            postData.append('date', formData.date);

            const response = await axios.post(`${BASE_URL}/admins/news/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);

            alert('news added successfully!');
            window.location.reload();
            setFormData({
                text: '',
                date: ''
            });

        } catch (error) {
            console.error('Error adding news:', error);
            alert('Failed to add news. Please try again.');
        }
    };


    const handleEdit = (news) => {
        setSelectedNews(news);
        setFormData({
            text: news.text,
            date: news.date,
        });
        setEditFormVisible(true);

    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();

            postData.append('text', formData.text);
            postData.append('date', formData.date)

            if (!selectedNews) {

                const response = await axios.post(`${BASE_URL}/admins/news/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(response.data);
                alert('News added successfully!');
            } else {

                const response = await axios.put(`${BASE_URL}/admins/news/${selectedNews.id}/update/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(response.data);
                alert('News updated successfully!');
            }


            setFormData({

                text: '',
                date: ''
            });
            setEditFormVisible(false);
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit news. Please try again.');
        }
    };

    const handleDelete = async (newsId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this news?');

        if (confirmDelete) {
            try {
                const response = await axios.delete(`${BASE_URL}/admins/news/${newsId}/delete/`);
                console.log(response.data);
                alert('News deleted successfully!');
                fetchNews()
            } catch (error) {
                console.error('Error deleting news:', error);
                alert('Failed to delete news. Please try again.');
            }
        }
    };








    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add News</p>
                <div className='flex pt-12 gap-[12rem] p-8'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add News</p>

                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage News</p>

                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">



                                <div className="relative  pt-6">
                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Text</p>
                                    <div className="pt-2">
                                        <ReactQuill
                                            name="text"
                                            value={formData.text}
                                            className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4] resize-none"
                                            placeholder="Enter your text here"
                                            onChange={(value) => setFormData({ ...formData, text: value })} // Updated this line
                                            rows="5"
                                        />
                                    </div>
                                    <p className='text-[color:var(--Black,#222)] pt-8  text-[18px] not-italic font-medium leading-[24px]'>News Date</p>
                                    <div className="pt-2">
                                        <input
                                            type="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={handleChange}
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Date of Birth"
                                        />
                                    </div>
                                </div>
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>

                )}

                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editFormVisible && (
                            <div className='pt-4'>
                                <div className='bg-gray-100 py-4 w-full flex p-6  '>

                                    <p className='text-[color:var(--Gray,#58585A)] pl-14 w-[80%] text-[18px] not-italic font-semibold leading-[normal]'>News</p>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px]  not-italic font-semibold leading-[normal]'>Actions</p>
                                </div>
                                <div className="">

                                    {news.map((news, index) => (
                                        <div key={index} className="flex ">
                                            
                                            <div className=' '>
                                                <p className='pt-14 pl-12 text-[18px] '>{index + 1}</p>
                                            </div>

                                            <div className='w-[70%]'>
                                                <p className='pt-14 pl-12 text-[18px] news-content' style={{ wordWrap: 'break-word' }}>
                                                    {parse(news.text)}
                                                </p>
                                            </div>

                                            <div className='flex pl-24 gap-6 pt-20'>
                                                <div>
                                                    <img src={ed} alt="" onClick={() => handleEdit(news)} />
                                                </div>
                                                <div>
                                                    <img src={del} alt="" onClick={() => handleDelete(news.id)} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}



                                </div>
                            </div>
                        )}



                        {editFormVisible && (
                            <div className='w-[50%]'>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="text-start">


                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Text</p>
                                        <div className="pt-1">
                                            <ReactQuill
                                                name="text"
                                                value={formData.text}
                                                className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4] resize-none"
                                                placeholder="Enter your text here"
                                                onChange={(value) => setFormData({ ...formData, text: value })} // Updated this line
                                                rows="5"
                                            />
                                        </div>

                                        <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>News Date</p>
                                        <div className="pt-2 relative ">
                                            <div className="pt-2">
                                                <input
                                                    type="date"
                                                    name="date"
                                                    value={formData.date}
                                                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Date of Birth"
                                                />
                                            </div>
                                        </div>
                                        <div className='pt-8 flex gap-8'>
                                            <button className='bg-[#00549A] rounded-[10px] w-[50%] py-4'>
                                                <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                            </button>
                                            <button
                                                type="button"
                                                className="bg-gray-500 text-white py-2 px-4 w-[50%] rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                                                onClick={handleExitEditForm}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default News;
