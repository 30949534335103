import React from "react";
import Asidebar from "../../Components/Admin/Asidebar/Asidebar";
import Anavbar from "../../Components/Admin/Anavbar/Anavbar"; 
import Amember from "../../Components/Admin/Member/Amember";
 
import "./Adashboard.css";
import Blogs from "../../Components/Admin/Blogs/ABlogs";
import Certificates from "../../Components/Admin/Certificates/Certificates";

const  CertificatesPage = () => {
    return (
       
        <div className="">
            <div className="asidebardiv apos-side">
            <Asidebar />
            </div>

            <div className="anavdiv apos">          
            <Anavbar />
           <Certificates/>
            </div>
                  
                  
                  
            </div>   
         
          
         
          
       
    );
}

export default CertificatesPage;
