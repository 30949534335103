import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
 
import News  from "../../../Components/User/News/News";
 

const  NewssPage = () => {

    return (
        <div>
    <Home/>
    <News/>

     
    <Footer/> 
    </div>
    )
}
export default NewssPage;