import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
import Register from "../../../Components/User/Register/Register";
 

const RegisterPage = () => {

    return (
        <div>
    <Home/>
    <Register/>
     
    <Footer/> 
    </div>
    )
}
export default RegisterPage;