import react from "react";
import Home from "../../../Components/Home/index";
import Forum from "../../../Components/User/About/Forum";  
import Footer from  "../../../Components/Footer/Footer";


const AboutForum = () => {

    return (
        <div>
    <Home/>
    <Forum/>
  <Footer/> 
    </div>
    )
}
export default AboutForum;