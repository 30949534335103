import react from "react";
import Home from "../../../Components/Home/index"; 
import Footer from  "../../../Components/Footer/Footer";
import Events from "../../../Components/User/Events/Event";
// import Card from "../../../Components/User/Events/Card1";

const SpecialityForum = () => {

    return (
        <div>
    <Home/>
    <Events/>
     {/* <Card/> */}
    <Footer/> 
    </div>
    )
}
export default SpecialityForum;