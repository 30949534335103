import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from "../../../Utils/Config";
import { toast, Toaster } from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import reg from "../../../assets/images/reg.png";

import './Register.css'

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        password2: ''
    });

    const { first_name, last_name, phone, email, password, password2 } = formData;
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                navigate('/dashboard');
            }
        };
        checkAuth();
    }, [navigate]);

    useEffect(() => {
        const validateEmail = async () => {
            if (email) {
                try {
                    const response = await axios.get(`${BASE_URL}/accounts/validate-email/`, {
                        params: { email }
                    });
                    if (response.data.exists) {
                        setEmailError('Email already exists.');
                        setEmailValid(false);
                    } else {
                        setEmailError('');
                        setEmailValid(true);
                    }
                } catch (error) {
                    console.error('Error validating email:', error);
                    setEmailError('Error checking email.');
                    setEmailValid(false);
                }
            } else {
                setEmailError('');
                setEmailValid(null);
            }
        };

        validateEmail();
    }, [email]);

    const handleChange = (e, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!first_name || !last_name || !phone || !email || !password || !password2) {
            toast.error("All fields are required.");
            return;
        }

        if (password !== password2) {
            toast.error("Passwords do not match.");
            return;
        }

        if (!validatePassword(password)) {
            toast.error("Please enter valid password.");
            return;
        }

        if (!emailValid) {
            toast.error("Please use a valid, unique email address.");
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/accounts/register/`, {
                first_name,
                last_name,
                email,
                phone,
                password,
            });

            if (response.status === 200) {
                toast.success("Registration successful. Please check your email for OTP.");
                navigate(`/otp?email=${encodeURIComponent(email)}`);
            } else {
                toast.error("Something went wrong during registration.");
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.errors) {
                const errorMessages = Object.values(error.response.data.errors).flat();
                errorMessages.forEach(errorMessage => toast.error(errorMessage));
            } else {
                toast.error("An error occurred during registration.");
            }
        }
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[A-Za-z\d@#$%^&+=]{8,}$/;
        return passwordRegex.test(password);
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <Toaster position="top-center" reverseOrder={false} />
                <div className="pt-6">
                    <div className="flex mob-block justify-between items-center bg-white rounded-[30px]">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-full">
                            <div className="text-start p-12 p-12-mob">
                                <p className="text-[#222] text-[34px] not-italic font-semibold leading-[36px]">User Registration</p>
                                <p className="text-[#58585A] pt-4 text-[19px] not-italic font-normal leading-[28px]">Register Now and Get Access to Our Contents...</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="pt-6">
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-[30px] w-full px-[20px] py-4"
                                            placeholder="First Name"
                                            value={first_name}
                                            onChange={(e) => handleChange(e, 'first_name')}
                                        />
                                    </div>

                                    <div className="pt-6">
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-[30px] px-[20px] py-4 w-full"
                                            placeholder="Last Name"
                                            value={last_name}
                                            onChange={(e) => handleChange(e, 'last_name')}
                                        />
                                    </div>

                                    <div className="pt-6">
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-[30px] px-[20px] py-4 w-full"
                                            placeholder="Phone Number"
                                            value={phone}
                                            onChange={(e) => handleChange(e, 'phone')}
                                        />
                                    </div>

                                    <div className="relative pt-6">
                                        <input
                                            type="email"
                                            className="border border-gray-300 rounded-[30px] px-[20px] py-4 w-full"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => handleChange(e, 'email')}
                                        />
                                        {emailError && <p className="text-red-500 text-sm pt-2">{emailError}</p>}
                                    </div>

                                    <div className="relative pt-6">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="border border-gray-300 rounded-[30px] px-[20px] py-4 w-full"
                                            placeholder="Enter your password"
                                            value={password}
                                            onChange={(e) => handleChange(e, 'password')}
                                        />
                                        <div
                                            className="absolute right-6 top-12 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
                                        </div>
                                        {passwordError && <p className="text-red-500 text-sm pt-2">{passwordError}</p>}
                                    </div>
                                    <div className="text-[13px] text-gray-400 pl-2 pt-2">Password should be 8 characters with at least one special character(* [@#$%^&+=]), one numeric, one small case and one upper case letter (i.e Abcd@123)</div>
                                    <div className="relative pt-6">
                                        <input
                                            type={showPassword2 ? "text" : "password"}
                                            className="border border-gray-300 rounded-[30px] px-[20px] py-4 w-full"
                                            placeholder="Re-Enter your password"
                                            value={password2}
                                            onChange={(e) => handleChange(e, 'password2')}
                                        />
                                        <div
                                            className="absolute right-6 top-12 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                            onClick={() => setShowPassword2(!showPassword2)}
                                        >
                                            {showPassword2 ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
                                        </div>
                                    </div>

                                    <div className="text-end tcenter pt-6">
                                        <button type="submit" className="button-bg px-16 rounded-[30px] py-4 ">
                                            <p className="text-white registerbtn not-italic font-semibold leading-[normal]">Register Now</p>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="xl:w-1/2 lg:w-1/2 md:w-full   ">
                            <div className="p-12 p-12-mob1">
                                <img src={reg} alt="" className="rounded-[30px]  " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
