import React from "react";
import Asidebar from "../../../Components/Admin/Asidebar/Asidebar";
import Anavbar from "../../../Components/Admin/Anavbar/Anavbar"; 
import BoardMember from "../../../Components/Admin/ACMS/BoardMembers";  
 
 

 
 

const  BoardPage = () => {
    return (
       
        <div className="">
            <div className="asidebardiv apos-side">
            <Asidebar />
            </div>

            <div className="anavdiv apos">          
            <Anavbar />
           <BoardMember/>
            </div>
                  
                  
                  
            </div>   
         
          
         
          
       
    );
}

export default BoardPage;
